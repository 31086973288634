import { LinksCardList } from 'components/usefulLinks/molecules/linksCardList/linksCardList'
import React from 'react'
import { t } from 'utils/i18n'

import { PageLayout } from 'ui/layouts/pageLayout/pageLayout'

const UsefulLinks: React.FC = () => {
  return (
    <PageLayout title={t('useful_links.title')}>
      <LinksCardList />
    </PageLayout>
  )
}

export default UsefulLinks
