export const downloadFile = (blob: Blob | null, filename: string) => {
  if (blob) {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.download = filename
    document.body.appendChild(link) // we need to append the element to the dom -> otherwise it will not work in firefox
    link.click()
    link.remove()
  }
}
