import classNames from 'classnames'
import React, { forwardRef } from 'react'

import { FieldState } from 'ui/forms/form.hook'
import { TextArea } from 'ui/textareas/textArea'

export interface FormTextAreaProps extends FieldState {
  textAreaClassName?: string
  disabled?: boolean
  id?: string
  placeholder?: string
  onFocus?: () => void
}

export const FormTextArea = forwardRef<HTMLInputElement, FormTextAreaProps>(({ id, placeholder, ...props }, ref) => {
  return (
    <TextArea
      ref={ref as React.ForwardedRef<HTMLTextAreaElement>}
      className={classNames(
        { 'textArea--medium': !props.textAreaClassName?.includes('textArea--small'), 'textArea--error': !!props.error },
        props.textAreaClassName
      )}
      placeholder={placeholder}
      id={id}
      onChange={props.handleFieldChange}
      onBlur={() => props.handleFieldBlur(props.value as string)}
      onFocus={props.onFocus}
      value={props.value as string}
      disabled={props.disabled}
    />
  )
})
FormTextArea.displayName = 'FormTextArea'
