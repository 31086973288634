/**
 * Represents the path of properties in an object T.
 * Used for accessing nested property values for features like sorting.
 */
export type Path<T> = {
  [K in keyof T]-?: T[K] extends object | undefined
    ? K extends string
      ? `${K}` | (T[K] extends undefined ? never : `${K}.${Extract<keyof NonNullable<T[K]>, string>}`)
      : never
    : K
}[keyof T] &
  string

/**
 * Enumerates possible directions for sorting data in a table.
 */
export enum SortDirection {
  ASCENDING = 'ascending',
  DESCENDING = 'descending',
}

/**
 * Represents a configuration for sorting data in a table.
 */
export type SortConfig<T> = {
  key: Path<T>
  direction: SortDirection
}

/*
 * Represents a column in a table.
 */
// TODO: differentiate between `sort` and `sortBy` props
export type Col<T> = {
  name: string
  headerClassName?: string
  className?: string
  render: (t: T) => React.ReactNode
  sticky?: boolean
  sort?: (() => void) | Path<T>
}
