import React from 'react'
import { MANDATE_STATUS, Opportunity } from 'lib/oleenApi'
import { t } from 'utils/i18n'
import dayjs from 'dayjs'
import { some } from 'lodash'

export const MandateInfo: React.FC<{ opportunity: Opportunity }> = ({ opportunity }) => {
  if (opportunity.mandateStatus === MANDATE_STATUS.SIGNED) {
    return (
      <p className="text-xs font-normal text-gray-500">
        {t('opportunity_kanban.card.mandate.signed_at', {
          date: dayjs(opportunity.mandateStatusUpdatedAt).format('DD/MM/YYYY'),
        })}
      </p>
    )
  }
  if (opportunity.mandateStatus === MANDATE_STATUS.CREATED) {
    if (some(opportunity.mandateSignatures, signature => signature.mortgagor?.signedAt !== null)) {
      return (
        <p className="text-xs font-normal text-orange-500">{t('opportunity_kanban.card.mandate.partially_signed')}</p>
      )
    }
    return <p className="text-xs font-normal text-gray-500">{t('opportunity_kanban.card.mandate.created')}</p>
  }
  return <p className="text-xs font-normal text-gray-500">{t('opportunity_kanban.card.mandate.not_sent')}</p>
}
