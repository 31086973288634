import { TaskForm } from 'components/tasks/taskForm'
import { useOleenApi } from 'contexts/oleenApi'
import { toast } from 'helpers/toast'
import React, { useCallback } from 'react'
import { t } from 'utils/i18n'

import { Modal } from 'ui//modals/modal'
import { useQueryClient } from '@tanstack/react-query'
import { NewTask, Task } from 'lib/oleenApi'
import { updateTaskQueryData } from 'helpers/queries'

type EditTaskModalProps = {
  task: Task
  closeModal: () => void
}

export const EditTaskModal: React.FC<EditTaskModalProps> = ({ task, closeModal }) => {
  const { oleenApi } = useOleenApi()
  const queryClient = useQueryClient()
  const action = useCallback(
    (newTask: NewTask) => {
      return oleenApi.tasks().update(task.id, newTask)
    },
    [oleenApi, task.id]
  )
  const handleSuccess = useCallback(
    (task: Task) => {
      updateTaskQueryData(queryClient, task)
      toast.success(t('tasks.toast.creation.success'))
      closeModal()
    },
    [queryClient, closeModal]
  )
  const handleError = useCallback(() => {
    toast.error(t('tasks.toast.update.error'))
  }, [])

  return (
    <Modal onClose={closeModal} isOpen={true} className="modal--form w-1/3" title={t('tasks.modal.edit.title')}>
      <TaskForm
        task={task}
        defaultTaskableId={task.taskableId}
        action={action}
        onSuccess={handleSuccess}
        onError={handleError}
      ></TaskForm>
    </Modal>
  )
}
