import { useOleenApi } from 'contexts/oleenApi'
import { useAsync } from 'hooks/async.hook'
import { LeadContributor } from 'lib/oleenApi'
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect } from 'react'

/**
 * This is a context that provides access to the list of lead contributors.
 *
 * It is used to avoid fetching the list of lead contributors multiple times.
 */
interface LeadContributorsContextType {
  leadContributors: LeadContributor[]
  upsertLeadContributor: (leadContributor: LeadContributor) => void
  getLeadContributor: (id: string) => LeadContributor | null
  refreshLeadContributors: () => void
}

const LeadContributorsContext = createContext<LeadContributorsContextType>({
  leadContributors: [],
  upsertLeadContributor: () => null,
  getLeadContributor: () => null,
  refreshLeadContributors: () => null,
})

type LeadContributorsProviderProps = PropsWithChildren<unknown>

export const LeadContributorsProvider: React.FC<LeadContributorsProviderProps> = ({ children }) => {
  const { oleenApi } = useOleenApi()
  const [leadContributors, setLeadContributors] = React.useState<LeadContributor[]>([])

  const listLeadContributors = useCallback(() => {
    if (!oleenApi.hasAuthToken()) return Promise.resolve(null)
    return oleenApi.leadContributors().listAll()
  }, [oleenApi])

  const { data, run, retry } = useAsync(listLeadContributors)

  useEffect(() => {
    run()
  }, [run])

  useEffect(() => {
    if (data) setLeadContributors(data)
  }, [data])

  const leadContributorsIndex = React.useMemo(() => {
    return leadContributors.reduce(
      (acc, item) => {
        acc[item.id] = item
        return acc
      },
      {} as { [key: string]: LeadContributor }
    )
  }, [leadContributors])

  const upsertLeadContributor = useCallback((leadContributor: LeadContributor) => {
    setLeadContributors(prevLeadContributors => {
      const index = prevLeadContributors.findIndex(lc => lc.id === leadContributor.id)
      if (index === -1) {
        return [leadContributor, ...prevLeadContributors]
      }
      const newLeadContributors = [...prevLeadContributors]
      newLeadContributors[index] = leadContributor
      return newLeadContributors
    })
  }, [])

  const getLeadContributor = useCallback((id: string) => leadContributorsIndex[id], [leadContributorsIndex])

  return (
    <LeadContributorsContext.Provider
      value={{ leadContributors, upsertLeadContributor, getLeadContributor, refreshLeadContributors: retry }}
    >
      {children}
    </LeadContributorsContext.Provider>
  )
}

export const useLeadContributors = (): LeadContributorsContextType => {
  const context = useContext(LeadContributorsContext)
  if (context === undefined) {
    throw new Error('useLeadContributors must be used within a LeadContributorsProvider')
  }
  return context
}
