import { Slider } from 'ui/misc/slider'
import React, { useCallback, useEffect, useState } from 'react'
import { NewOpportunityForm } from 'components/newOpportunity/newOpportunityForm'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { PanelHeader } from 'ui/misc/panelHeader'
import { t } from 'utils/i18n'

export const NewOpportunityPage: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    setIsOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onTransitionEnd = useCallback(() => {
    if (!isOpen) {
      navigate(ROUTES.OPPORTUNITIES)
    }
  }, [navigate, isOpen])

  return (
    <Slider isOpen={isOpen} onClose={onClose} onTransitionEnd={onTransitionEnd}>
      <PanelHeader title={t('opportunities.new.title')} onClose={onClose} />
      <NewOpportunityForm />
    </Slider>
  )
}
