import React from 'react'

import { useField } from 'ui/forms/form.hook'
import { ICheckbox } from 'ui/legacy/atoms/checkbox/checkbox.types'

export const Checkbox: React.FC<ICheckbox> = ({ name, label, checked, onChange }) => {
  const { handleFieldChange, value } = useField({ name, defaultValue: checked })

  return (
    <div className="flex items-center">
      <input
        className="rounded-lg cursor-pointer"
        type="checkbox"
        name={name}
        checked={value as boolean}
        onChange={({ currentTarget: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
          handleFieldChange(checked)
          onChange?.(checked)
        }}
      />
      <label className="ml-2">{label}</label>
    </div>
  )
}
