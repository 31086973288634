import { Bars3Icon, UserIcon as UserOutline, XMarkIcon } from '@heroicons/react/20/solid'
import { FeatureFlagsModal } from 'components/featureFlags/featureFlagModal'
import { NavBar } from 'components/navBar/navBar'
import { NotificationMenu } from 'components/notifications/notificationMenu'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'

import { ICON_BUTTON_VARIANTS } from 'ui/buttons/iconButton'
import { BUTTON_CIRCLE_VARIANTS, ButtonCircle } from 'ui/buttons/buttonCircle'
import { Logo } from 'ui/layouts/mainLayout/logo'
import { ContentWrapper, Screen } from 'ui/layouts/mainLayout/mainLayout.styled'
import { useModal } from 'ui/legacy/atoms/modals/modal.hook'

import { Sidebar } from './sidebar'
import classNames from 'classnames'
import { CLICKS } from 'helpers/tracking'

const AccountMenu = () => {
  const navigate = useNavigate()
  const navigateToAccount = useCallback(() => navigate(ROUTES.ACCOUNT), [navigate])
  return (
    <ButtonCircle
      variant={BUTTON_CIRCLE_VARIANTS.GRAY}
      Icon={UserOutline}
      onClick={navigateToAccount}
      tracking={[CLICKS.navbarAccount]}
    />
  )
}

export const MainLayout: React.FC = ({ children }) => {
  const [displaySideMenu, setDisplaySideMenu] = useState(false)
  const { isOpen, openModal, closeModal } = useModal()
  const { pathname } = useLocation()

  useEffect(() => {
    if (displaySideMenu) setDisplaySideMenu(false)
  }, [pathname])

  return (
    <Screen>
      <NavBar>
        <button onClick={openModal}>
          <Logo />
        </button>
        <div className="flex gap-6 items-center">
          <NotificationMenu />
          <AccountMenu />
          {displaySideMenu ? (
            <XMarkIcon
              className={`${ICON_BUTTON_VARIANTS.DARK_BG} w-4 h-4 md:hidden`}
              onClick={() => setDisplaySideMenu(false)}
            />
          ) : (
            <Bars3Icon
              className={`${ICON_BUTTON_VARIANTS.DARK_BG} w-4 h-4 md:hidden`}
              onClick={() => setDisplaySideMenu(true)}
            />
          )}
        </div>
      </NavBar>
      <div className={`flex items-stretch grow h-[calc(100vh_-_var(--navbar-size))] relative bg-gray-900 pr-2`}>
        <div
          data-open={displaySideMenu}
          className={classNames(
            'px-3 absolute w-full h-full z-40 rtl',
            'transition-transform ease-in-out duration-200',
            '-right-full ',
            'data-[open=true]:-translate-x-full',
            'md:relative md:block md:h-full md:pr-0 md:w-64 md:min-w-64 md:ltr md:right-0',
            'data-[open=true]:md:block data-[open=false]:md:block',
            'data-[open=true]:md:-translate-x-0 data-[open=false]:md:translate-x-0'
          )}
        >
          <Sidebar />
        </div>
        <ContentWrapper>{children}</ContentWrapper>
      </div>
      {isOpen && <FeatureFlagsModal isOpen={isOpen} closeModal={closeModal} />}
    </Screen>
  )
}
