import React from 'react'

import { Trans } from 'react-i18next'
import { Person } from 'components/notifications/person'
import { useNotificationsContext } from 'contexts/notifications'
import { CLICKS, track } from 'helpers/tracking'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'

import { NewIcon, NotificationItem } from 'ui/notifications/notificationItem'

type ILeadContributedNotificationProps = {
  id: string
  opportunityId: string
  leadContributor: {
    firstName: string | null
    lastName: string | null
  }
  mortgagor: {
    firstName: string | null
    lastName: string | null
  }
  timing: string
  interacted: boolean
}

export const OpportunityContributedNotification: React.FC<ILeadContributedNotificationProps> = ({
  id,
  opportunityId,
  leadContributor,
  mortgagor,
  timing,
}) => {
  const navigate = useNavigate()
  const { toggleMenu, markAsInteracted } = useNotificationsContext()

  const onClick = () => {
    track(CLICKS.notification, { type: 'opportunity-contributed-notification' })
    toggleMenu()
    markAsInteracted(id)
    navigate(`${ROUTES.OPPORTUNITIES}/${opportunityId}`)
  }

  return (
    <NotificationItem
      onClick={onClick}
      Icon={<NewIcon />}
      subtitle={
        <Trans i18nKey="notification.opportunity_contributed.subtitle">
          <Person person={{ ...leadContributor, email: '' }} />
          <Person person={{ ...mortgagor, email: '' }} />
        </Trans>
      }
      timing={timing}
    />
  )
}
