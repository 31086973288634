import { Path } from './types'

/**
 * Checks if a value is null or undefined.
 *
 * @param {unknown} value - The value to check.
 */
export const isNullish = (value: unknown): boolean => value === null || value === undefined

/**
 * Retrieves the value at a given path within an object.
 * Path can include nested properties.
 *
 * @param {T} object - The object to retrieve the value from.
 * @param {Path<T>} path - The path to the desired property.
 * @returns The value at the specified path, or undefined if the path is invalid.
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const getValueByPath = <T>(object: T, path: Path<T>): any => {
  if (isNullish(object)) return undefined
  return path.split('.').reduce((acc, part) => acc && acc[part], object as any)
}
/* eslint-enable @typescript-eslint/no-explicit-any */
