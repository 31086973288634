import React from 'react'

import { CheckBoxToggle } from 'ui/buttons/checkBoxToggle'
import { FieldState } from 'ui/forms/form.hook'

export interface FormCheckBoxToggleProps extends FieldState {
  name: string
}

export const FormCheckBoxToggle: React.FC<FormCheckBoxToggleProps> = ({
  name,
  handleFieldChange,
  handleFieldBlur,
  value,
}) => {
  return (
    <CheckBoxToggle
      name={name}
      value={value as boolean}
      onChange={handleFieldChange}
      onBlur={() => handleFieldBlur(value as boolean)}
    />
  )
}
