import React from 'react'
import { t } from 'utils/i18n'

import { PageLayout } from 'ui/layouts/pageLayout/pageLayout'

import { useOleenApi } from 'contexts/oleenApi'
import { useQuery } from '@tanstack/react-query'

const Roadmap: React.FC = () => {
  const { oleenApi } = useOleenApi()

  const productBoardRequest = useQuery({
    queryKey: ['productBoardToken'],
    queryFn: () => oleenApi.productBoardToken().get(),
  })

  if (productBoardRequest.isLoading || productBoardRequest.isError) return <></>

  return (
    <PageLayout title={t('roadmap.title')}>
      <div>
        <span className="font-normal truncate">{t('roadmap.description')}</span>
      </div>
      <iframe
        className="h-full border-0"
        src={t('url.productboard_roadmap', { token: productBoardRequest.data?.token })}
      />
    </PageLayout>
  )
}

export default Roadmap
