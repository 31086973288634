import React from 'react'

import { RadioGroup } from 'ui/buttons/radioGroup'
import { FieldState } from 'ui/forms/form.hook'

export interface RadioButtonGroupProps extends FieldState {
  radioClassName?: string
  name: string
  radioOptions: { value: string; label: string }[]
}

export const FormRadio: React.FC<RadioButtonGroupProps> = ({ radioOptions: options, ...props }) => {
  return (
    <RadioGroup
      className={props.radioClassName}
      name={props.name}
      value={props.value as string}
      options={options}
      onChange={props.handleFieldChange}
    />
  )
}
