import React from 'react'
import { t } from 'utils/i18n'

export const NothingToDo: React.FC = () => {
  return (
    <div className="flex flex-col place-items-center">
      <svg width="310" height="275" viewBox="0 0 310 275" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_529_44522)">
          <rect width="309.92" height="274.634" transform="translate(0.0400391)" fill="white" />
          <circle cx="222.54" cy="46.5" r="27.5" fill="#FEF3C7" />
          <circle cx="222.54" cy="46.5" r="20.5" fill="#FCD34D" />
          <path
            d="M280.995 111.324C280.995 111.324 225.939 98.5264 162.097 86.8913C98.2548 75.2562 49.8033 69.1898 49.8033 69.1898C74.1332 47.239 110.522 29.4321 174.364 41.0672C238.206 52.7023 265.973 82.2016 280.995 111.324Z"
            fill="#C7D2FE"
          />
          <path
            d="M207.036 87.6805C203.075 109.414 189.417 84.4696 167.684 80.5087C145.95 76.5478 124.371 95.0703 128.332 73.3369C132.293 51.6035 153.122 37.196 174.856 41.1569C196.589 45.1178 210.997 65.9471 207.036 87.6805Z"
            fill="#A5B4FC"
          />
          <ellipse
            cx="165.309"
            cy="90.7489"
            rx="117.5"
            ry="21.5"
            transform="rotate(10.3287 165.309 90.7489)"
            fill="#4C1D95"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M276.371 103.907C258.865 109.151 215.122 107.213 164.991 98.0763C113.795 88.7459 71.482 74.7423 57.8258 63.5366C53.0382 64.971 50.2128 66.9426 49.7556 69.4513C47.6266 81.133 97.6548 100.035 161.497 111.67C225.339 123.305 278.819 123.267 280.948 111.586C281.369 109.272 279.744 106.675 276.371 103.907Z"
            fill="#5B21B6"
          />
          <rect
            x="168.309"
            y="82.6556"
            width="174"
            height="3.99999"
            rx="2"
            transform="rotate(100.329 168.309 82.6556)"
            fill="#111827"
          />
          <rect
            x="154.895"
            y="78.1778"
            width="24"
            height="4"
            rx="2"
            transform="rotate(10.3287 154.895 78.1778)"
            fill="#111827"
          />
          <path d="M173.04 139H191.04L165.04 237H147.04L173.04 139Z" fill="#93C5FD" />
          <path d="M195.04 139H213.04L187.04 237H169.04L195.04 139Z" fill="#93C5FD" />
          <path d="M217.04 139H235.04L209.04 237H191.04L217.04 139Z" fill="#93C5FD" />
          <path
            d="M239.04 139H257.04L234.986 222.129C232.66 230.896 224.725 237 215.655 237H213.04L239.04 139Z"
            fill="#93C5FD"
          />
          <rect x="86.0403" y="229" width="77" height="8" fill="#93C5FD" />
          <rect x="219.04" y="235.828" width="4" height="27" transform="rotate(-45 219.04 235.828)" fill="#93C5FD" />
          <rect
            x="200.04"
            y="229.933"
            width="4"
            height="27"
            transform="rotate(-13.486 200.04 229.933)"
            fill="#93C5FD"
          />
          <rect
            x="155.837"
            y="229.965"
            width="4"
            height="27"
            transform="rotate(11.3826 155.837 229.965)"
            fill="#93C5FD"
          />
          <rect
            x="87.3689"
            y="229.965"
            width="4"
            height="27"
            transform="rotate(11.3826 87.3689 229.965)"
            fill="#93C5FD"
          />
          <rect x="67.0403" y="199" width="60" height="4" fill="#BFDBFE" />
          <rect x="89.2581" y="199" width="67.1272" height="4" transform="rotate(53.5525 89.2581 199)" fill="#BFDBFE" />
          <rect
            x="90.6516"
            y="202.188"
            width="54.5605"
            height="4"
            transform="rotate(107.94 90.6516 202.188)"
            fill="#BFDBFE"
          />
          <path
            d="M89.3025 176.279H105.712V195.213C105.712 197.305 104.017 199 101.925 199H93.0893C90.9979 199 89.3025 197.305 89.3025 195.213V176.279Z"
            fill="#DBEAFE"
          />
          <path
            d="M90.5647 178.804H104.45V195.213C104.45 196.607 103.319 197.738 101.925 197.738H93.0892C91.695 197.738 90.5647 196.607 90.5647 195.213V178.804Z"
            fill="#FB923C"
          />
          <path d="M92.1689 168.252L93.7767 167.818L95.0628 172.585L92.812 170.635L92.1689 168.252Z" fill="#14B8A6" />
          <path d="M92.8118 170.635L95.0626 172.585L96.3486 177.353L94.0978 175.403L92.8118 170.635Z" fill="#E0E7FF" />
          <path d="M94.0974 175.403L96.3482 177.353L97.6343 182.12L95.3835 180.17L94.0974 175.403Z" fill="#14B8A6" />
          <path d="M95.3838 180.17L97.6346 182.12L98.9207 186.887L96.6699 184.937L95.3838 180.17Z" fill="#E0E7FF" />
          <path d="M96.6699 184.938L98.9207 186.887L100.207 191.655L97.956 189.705L96.6699 184.938Z" fill="#14B8A6" />
          <path d="M97.9556 189.705L100.206 191.655L101.492 196.422L99.2416 194.472L97.9556 189.705Z" fill="#E0E7FF" />
          <rect x="89.3022" y="176.279" width="8.83588" height="2.52454" fill="#EFF6FF" />
          <rect x="46.0403" y="252" width="227" height="5" fill="#FED7AA" />
          <rect opacity="0.7" x="91.04" y="190" width="4" height="7" rx="2" fill="white" />
          <rect opacity="0.7" x="91.04" y="185" width="4" height="4" rx="2" fill="white" />
        </g>
        <defs>
          <clipPath id="clip0_529_44522">
            <rect width="309.92" height="274.634" fill="white" transform="translate(0.0400391)" />
          </clipPath>
        </defs>
      </svg>
      <p>{t('tasks.what_is_next')}</p>
    </div>
  )
}
