import { ApiError, ApiErrorDetail, BaseErrorDetailType } from '../apiError'
import { Client } from '../client'
import { ObjectCamelToSnakeCase } from '../misc'

declare module '../client' {
  interface Client {
    addonSubscriptions: () => AddonSubscriptionService
  }
}

Client.prototype.addonSubscriptions = function () {
  return new AddonSubscriptionService(this)
}

/**
 * Represents an addon subscription.
 */
export interface AddonSubscription {
  id: string
  addon: string
  representativeId: string
  createdAt: string
  endedAt: string | null
}

/**
 * Represents the shape of a new addon subscription.
 */
export type NewAddonSubscription = Omit<AddonSubscription, 'id' | 'createdAt' | 'endedAt'>

/**
 * Represents the shape of a serialized new addon subscription.
 */
type SerializedNewAddonSubscription = ObjectCamelToSnakeCase<NewAddonSubscription>

export type AddonSubscriptionErrorType = BaseErrorDetailType | 'active_subscription_exists'

export type AddonSubscriptionErrorAttribute = 'base' | keyof SerializedNewAddonSubscription

interface AddonSubscriptionErrorDetail extends ApiErrorDetail {
  type: AddonSubscriptionErrorType
  attribute: AddonSubscriptionErrorAttribute
}

/**
 * Represents the shape of a new or updated addon subscription.
 */
export type AddonSubscriptionResponse = ObjectCamelToSnakeCase<AddonSubscription>

export class AddonSubscriptionApiError extends ApiError<AddonSubscriptionErrorDetail> {
  constructor(message: string, status: number, details: AddonSubscriptionErrorDetail[]) {
    super(message, status, details)
  }
}

/**
 * Represents the service for managing addon subscriptions.
 */
interface IAddonSubscriptionService {
  create: (newAddonSubscription: NewAddonSubscription) => Promise<AddonSubscription>
}

class AddonSubscriptionService implements IAddonSubscriptionService {
  constructor(private client: Client) {
    client.setErrorHandler(AddonSubscriptionApiError)
  }

  create(newAddonSubscription: NewAddonSubscription) {
    return this.client.request<NewAddonSubscription, AddonSubscription, AddonSubscriptionResponse>(
      {
        url: '/addon_subscriptions',
        method: 'POST',
        data: serializeNewAddonSubscription(newAddonSubscription),
      },
      response => parseAddonSubscription(response.data)
    )
  }
}

const serializeNewAddonSubscription = (newAddonSubscription: NewAddonSubscription): SerializedNewAddonSubscription => {
  return {
    addon: newAddonSubscription.addon,
    representative_id: newAddonSubscription.representativeId,
  }
}

const parseAddonSubscription = (data: AddonSubscriptionResponse): AddonSubscription => {
  return {
    id: data.id,
    addon: data.addon,
    representativeId: data.representative_id,
    createdAt: data.created_at,
    endedAt: data.ended_at,
  }
}
