import React from 'react'
import { Button } from 'ui/buttons/button'
import { Modal } from 'ui/modals/modal'
import { t } from 'utils/i18n'
import { Trans } from 'react-i18next'
import { EnvelopeIcon } from '@heroicons/react/24/outline'
import { CLICKS } from 'helpers/tracking'

type YuccanleadSubscriptionModalProps = {
  isSubscriptionPending: boolean
  isSubscriptionSuccessful: boolean
  onClose: () => void
  subscribe: () => void
}

const YuccanleadSubscriptionConfirmationModal: React.FC<Pick<YuccanleadSubscriptionModalProps, 'onClose'>> = ({
  onClose,
}) => (
  <Modal onClose={onClose} isOpen={true} className="modal--popup">
    <div>
      <EnvelopeIcon className="w-[42px] h-[42px]" />
      <h1 className="font-inter text-base font-normal leading-6 text-gray-600">
        {t('addon_subscriptions.yuccanlead.email_access_notice')}
      </h1>
    </div>
    <footer className="flex justify-center gap-4">
      <Button className="button--medium button--primary button--solid" onClick={onClose}>
        {t('addon_subscriptions.understood')}
      </Button>
    </footer>
  </Modal>
)

export const YuccanleadSubscriptionModal: React.FC<YuccanleadSubscriptionModalProps> = ({
  isSubscriptionPending,
  isSubscriptionSuccessful,
  onClose,
  subscribe,
}) => {
  if (isSubscriptionSuccessful) {
    return <YuccanleadSubscriptionConfirmationModal onClose={onClose} />
  }
  return (
    <Modal onClose={onClose} isOpen={true} title="Souscrire à YuccanLead" className="modal--form">
      <div>
        <p className="text-sm font-medium">{t('addon_subscriptions.yuccanlead.headline')}</p>
        <p className="text-sm font-medium">{t('addon_subscriptions.yuccanlead.headline_2')}</p>
        <p className="text-xs font-semibold">{t('addon_subscriptions.yuccanlead.features')}</p>
        <ul className="list-disc list-outside text-xs ml-4">
          <li>{t('addon_subscriptions.yuccanlead.feature_1')}</li>
          <li>{t('addon_subscriptions.yuccanlead.feature_2')}</li>
          <li>{t('addon_subscriptions.yuccanlead.feature_3')}</li>
        </ul>
        <p className="text-xs text-gray-500">{t('addon_subscriptions.yuccanlead.pricing')}</p>
        <Button
          className="button--solid button--primary button--small block"
          onClick={() => subscribe()}
          isLoading={isSubscriptionPending}
          tracking={[CLICKS.addonSubscriptionConfirm, { addon: 'yuccanlead' }]}
        >
          {t('addon_subscriptions.subscribe')}
        </Button>
        <Button
          className="button--outline button--secondary button--small block"
          onClick={onClose}
          tracking={[CLICKS.addonSubscriptionReject, { addon: 'yuccanlead' }]}
        >
          {t('addon_subscriptions.later')}
        </Button>

        <p className="text-xs">
          <Trans i18nKey="addon_subscriptions.yuccanlead.terms">
            <a
              className="text-blue-600 underline"
              href={t('addon_subscriptions.yuccanlead.yuccanlead_gcu')}
              target="_blank"
              rel="noreferrer"
            />
            <a
              className="text-blue-600 underline"
              href={t('addon_subscriptions.yuccanlead.pretto_gcu')}
              target="_blank"
              rel="noreferrer"
            />
          </Trans>
        </p>
      </div>
    </Modal>
  )
}
