import { InformationCircleIcon, UserGroupIcon } from '@heroicons/react/20/solid'
import { useLeadContributors } from 'contexts/leadContributors'
import { id } from 'helpers/ref'
import { BusinessPartner, BusinessPartnerErrorAttribute, BusinessPartnerErrorType } from 'lib/oleenApi'
import { BUSINESS_TYPES } from 'lib/oleenApi/services/businessPartnersService'
import React, { PropsWithChildren } from 'react'
import { t } from 'utils/i18n'

import { FormInput } from 'ui/forms/formInput'

import { LeadContributorsManagerFormElement } from './leadContributorsManager/leadContributorManagerFormElement'

enum EditBusinessPartnerFormFields {
  name = 'name',
  businessType = 'businessType',
  leadContributorIds = 'leadContributorIds',
}

export type EditBusinessPartnerFormData = {
  name: string
  businessType: string
  leadContributorIds: string[]
}

export type EditBusinessPartnerFormError = Partial<Record<BusinessPartnerErrorAttribute, BusinessPartnerErrorType>>

const options = BUSINESS_TYPES.map(value => ({
  value,
  label: t(`business_partners.business_type.${value}`),
  type: 'text' as const,
}))

type EditBusinessPartnerFormProps = PropsWithChildren<{
  error?: EditBusinessPartnerFormError
  businessPartner: BusinessPartner
}>

type CardProps = {
  title: string
  Icon?: HeroIcon
}

const Card: React.FC<PropsWithChildren<CardProps>> = ({ title, Icon, children }) => {
  return (
    <div className="flex flex-col rounded-lg">
      <div className="border rounded-t-lg flex items-center justify-start p-2 bg-gray-100 gap-2 text-sm font-bold leading-6 antialiased">
        {Icon && <Icon className="w-5 h-5 text-gray-500" />} {title}
      </div>
      <div className="border border-t-0 rounded-b-lg flex flex-col gap-4 p-4">{children}</div>
    </div>
  )
}

export const EditBusinessPartnerForm: React.FC<EditBusinessPartnerFormProps> = ({
  error,
  businessPartner,
  children,
}) => {
  const { leadContributors: allLeadContributors } = useLeadContributors()
  const [leadContributors, setLeadContributors] = React.useState(
    allLeadContributors.filter(leadContributor => businessPartner.leadContributorIds.includes(leadContributor.id))
  )

  return (
    <div className="flex flex-col gap-4">
      <Card title="Nom" Icon={InformationCircleIcon}>
        <FormInput
          name={EditBusinessPartnerFormFields.name}
          label="Nom"
          type="text"
          placeholder={"Ex: Agence de l'Etoile"}
          error={error?.name && { id: id(), value: t(`error.type.${error.name}`) }}
          defaultValue={businessPartner.name}
        />
        <FormInput
          name={EditBusinessPartnerFormFields.businessType}
          dropdownClassName="w-full"
          label="Type"
          type="select"
          options={[options]}
          error={error?.business_type && { id: id(), value: t(`error.type.${error.business_type}`) }}
          defaultValue={businessPartner.businessType}
        />
      </Card>
      <Card title="Contact" Icon={UserGroupIcon}>
        <LeadContributorsManagerFormElement
          name={EditBusinessPartnerFormFields.leadContributorIds}
          leadContributors={leadContributors}
          setLeadContributors={setLeadContributors}
        />
      </Card>
      {children}
    </div>
  )
}
