import React from 'react'
import { Opportunity } from 'lib/oleenApi'
import { t } from 'utils/i18n'
import dayjs from 'dayjs'

export const CreationInfo: React.FC<{ opportunity: Opportunity }> = ({ opportunity }) => (
  <p className="text-xs text-gray-500 font-normal">
    {t('opportunity_kanban.card.created_at', { date: dayjs(opportunity.createdAt).format('DD/MM/YYYY') })}
  </p>
)
