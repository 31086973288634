import { id as getId } from 'helpers/ref'
import React from 'react'

type RadioProps = {
  id?: string
  label: string
  name: string
  onChange?: (v: string) => void
  onFocus?: () => void
  onBlur?: () => void
  value: string
  checked?: boolean
}

export const Radio: React.FC<RadioProps> = ({ id, name, label, onChange, onFocus, onBlur, value, checked }) => {
  const inputId = id || getId()

  return (
    <div className="radio">
      <input
        id={inputId}
        name={name}
        type="radio"
        checked={checked}
        onChange={e => onChange && onChange(e.currentTarget.value)}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
      />
      <label htmlFor={inputId}>
        {!checked && <span className="unchecked" />}
        {checked && <span className="checked" />}
        <span> {label} </span>
      </label>
    </div>
  )
}
