import React, { createContext, useContext } from 'react'

import { Loader } from 'ui/legacy/atoms/loader/loader'
import { useOleenApi } from './oleenApi'
import { useQuery } from '@tanstack/react-query'
import { Representative } from 'lib/oleenApi'

interface RepresentativeContext {
  representative: Representative | null
}

const RepresentativeContext = createContext<RepresentativeContext>({
  representative: null,
})

export const useRepresentativeContext: () => RepresentativeContext = () => useContext(RepresentativeContext)

export const configureStonly = (email: string) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.StonlyWidget('sendData', {
    // FIXME use .d.ts file to monkey patch window
    contactForm: { email },
  })
}

export const RepresentativeProvider: React.FC = ({ children }) => {
  const { oleenApi } = useOleenApi()

  const representativeQuery = useQuery({
    queryKey: ['representative'],
    queryFn: () => oleenApi.representatives().current(),
  })

  if (representativeQuery.isError)
    return (
      <div className="h-screen w-screnn flex justify-center items-center">
        Une erreur est survenue en chargeant les données de votre compte.
      </div>
    )

  if (representativeQuery.isPending) return <Loader />

  return (
    <RepresentativeContext.Provider value={{ representative: representativeQuery.data }}>
      {children}
    </RepresentativeContext.Provider>
  )
}
