import { InsuranceOpportunityModal } from 'components/opportunity/insuranceOpportunityModal/insuranceOpportunityModal'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FormProvider } from 'ui/forms/form'

export const InsuranceOpportunityPage: React.FC = () => {
  const { opportunityId } = useParams()
  const navigate = useNavigate()

  return (
    <>
      <FormProvider>
        {opportunityId && (
          <InsuranceOpportunityModal
            closeModal={() => navigate(`/opportunities/${opportunityId}`)}
            opportunityId={opportunityId}
          />
        )}
      </FormProvider>
    </>
  )
}
