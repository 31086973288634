import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

import { DropdownOptionValue } from 'ui/dropdowns/dropdown/types'
import { TextInput } from 'ui/inputs/textInput'

export type DropdownOptionSearchProps = {
  className?: string
  value?: DropdownOptionValue
  placeholder?: string
  onChange: (value: string) => void
}

export const DropdownOptionSearch: React.FC<PropsWithChildren<DropdownOptionSearchProps>> = ({
  className,
  placeholder,
  onChange,
  value,
}) => {
  return (
    <div className={classNames('dropdownItem--search', className)}>
      <TextInput
        className="textInput--small"
        Icon={MagnifyingGlassIcon}
        placeholder={placeholder}
        onChange={onChange}
        value={value ?? undefined}
      />
    </div>
  )
}
