import { useOleenApi } from 'contexts/oleenApi'
import { useRepresentativeContext } from 'contexts/representative'
import * as StonlyHelper from 'helpers/stonly'
import { toast } from 'helpers/toast'
import React, { useEffect, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'

import { Alert } from 'ui/banners/alert'
import { Button } from 'ui/buttons/button'
import { useFormContext } from 'ui/forms/form'
import { FormInput } from 'ui/forms/formInput'
import { useQueryClient } from '@tanstack/react-query'
import { Opportunity } from 'lib/oleenApi'

export type InsuranceOpportunityFormProps = {
  opportunityId: string
}

export const InsuranceOpportunityForm: React.FC<InsuranceOpportunityFormProps> = ({ opportunityId }) => {
  const [isKeeping, setIsKeeping] = React.useState(true)
  const [isDropping, setIsDropping] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const queryClient = useQueryClient()

  const { oleenApi } = useOleenApi()

  const { representative } = useRepresentativeContext()

  // TODO: centralize query key management to facilitate invalidation
  const opportunity = queryClient.getQueryData(['mortgageOpportunity', opportunityId]) as Opportunity

  const additionalInfo = useMemo(() => {
    return {
      mortgagorFirstName: opportunity?.mortgagorFirstName,
      mortgagorLastName: opportunity?.mortgagorLastName,
      mortgagorPhone: opportunity?.mortgagorPhone,
      mortgagorEmail: opportunity?.mortgagorEmail,
      finspotProjectId: opportunity?.id,
    }
  }, [opportunity])

  const { watchField, getFormData } = useFormContext()
  const navigate = useNavigate()

  const onSubmit = async () => {
    const formData = getFormData()

    if (formData.insuranceType === 'keeping') {
      if (formData.insuranceAlreadyDone === 'no') {
        return navigate(`${ROUTES.OPPORTUNITIES}/${opportunityId}/${ROUTES.OPPORTUNITY_CREATE_TASK}`)
      }

      const payload = {
        ...additionalInfo,
        alreadyDone: formData.insuranceAlreadyDone === 'yes',
        representativeEmail: representative?.email,
      }

      setIsLoading(true)
      await oleenApi.zapierWebhooks().run('2061324/3l2sk4o', payload)
      setIsLoading(false)
      toast.success(t('opportunity.insurance_opportunity_modal.toast.response_success'))

      return navigate(`${ROUTES.OPPORTUNITIES}/${opportunityId}`)
    }

    StonlyHelper.setAdditionalInfo(additionalInfo)

    if (formData.insuranceType === 'delegating') {
      return navigate(`${ROUTES.OPPORTUNITIES}/${opportunityId}?openStonly=insurance-opportunity-form`)
    }

    if (formData.insuranceType === 'dropping') {
      return navigate(`${ROUTES.OPPORTUNITIES}/${opportunityId}?openStonly=impossible-insurance-opportunity-form`)
    }
  }

  useEffect(() => {
    watchField('insuranceType', value => {
      setIsKeeping(value === 'keeping')
      setIsDropping(value === 'dropping')
    })
  }, [watchField])

  return (
    <>
      <FormInput
        name="insuranceType"
        type="radio"
        defaultValue="keeping"
        radioClassName="flex flex-col gap-3"
        radioOptions={[
          { value: 'keeping', label: t('opportunity.insurance_opportunity_modal.form.keeping') },
          { value: 'delegating', label: t('opportunity.insurance_opportunity_modal.form.delegating') },
          { value: 'dropping', label: t('opportunity.insurance_opportunity_modal.form.dropping') },
        ]}
      />

      {isKeeping && (
        <>
          <p className="text-xs font-semibold">
            {t('opportunity.insurance_opportunity_modal.form.is_insurance_opportunity_done')}
          </p>
          <FormInput
            name="insuranceAlreadyDone"
            type="radio"
            defaultValue="yes"
            radioClassName="flex flex-col gap-3"
            radioOptions={[
              { value: 'yes', label: t('common.yes') },
              { value: 'no', label: t('common.no') },
            ]}
          />
        </>
      )}

      {isKeeping && (
        <Alert className="alert--small alert--confirmation">
          {t('opportunity.insurance_opportunity_modal.digital_insure_gateway')}
        </Alert>
      )}

      <Button className="button--solid button--primary button--small" onClick={onSubmit} isLoading={isLoading}>
        {!isKeeping && !isDropping ? t('common.confirm') : t('common.ok')}
      </Button>

      {!isKeeping && !isDropping && (
        <p className="text-gray-500 text-xs font-normal leading-4">
          {t('opportunity.insurance_opportunity_modal.confirmation_warning')}
        </p>
      )}
    </>
  )
}
