import { useFeatureFlagsContext } from 'contexts/featureFlags'
import React from 'react'
import { t } from 'utils/i18n'

import { Modal, MODAL_SIZES } from 'ui/legacy/atoms/modals/modal'
import { ToggleButton } from 'ui/legacy/atoms/toggleButton/toggleButton'

type FeatureFlagsModalProps = {
  isOpen: boolean
  closeModal: () => void
}

export const FeatureFlagsModal: React.FC<FeatureFlagsModalProps> = ({ isOpen, closeModal }) => {
  const { featureFlags, changeFlag } = useFeatureFlagsContext()
  const noFlags = featureFlags.length === 0
  return (
    <Modal
      header={<span className="text-1xl font-bold text-gray-900 truncate">{t('feature_flags_modal.title')}</span>}
      size={MODAL_SIZES.SM}
      isOpen={isOpen}
      closeModal={closeModal}
    >
      <div className="flex flex-col gap-4">
        {noFlags && <p className="text-xs">{t('feature_flags_modal.empty')}</p>}
        {!noFlags &&
          featureFlags.map((flag, index) => (
            <div key={index} className="flex gap-4">
              <ToggleButton checked={flag.value} onChange={() => changeFlag(flag.name)} />
              <p>{t(`feature_flags_modal.${flag.name}`)}</p>
            </div>
          ))}
      </div>
    </Modal>
  )
}
