import { formatAddress, formatAmount, formatText } from 'helpers/format'
import { MANDATE_FIELDS } from 'helpers/mandate'
import React from 'react'
import { t } from 'utils/i18n'

import { FormInput } from 'ui/forms/formInput'
import { LabelledCardVerticalBody, LabelledCardVerticalItem } from 'ui/legacy/molecules/card/card.styled'
import { CardDataRow } from 'ui/legacy/molecules/card/cardDataRow'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { Opportunity } from 'lib/oleenApi'

interface MandateProjectCardProps {
  opportunity: Opportunity
}

export const SendMandateModalProjectCard: React.FC<MandateProjectCardProps> = ({ opportunity }) => (
  <LabelledCard icon={CARD_ICON.HomeIcon} title={t('opportunity.project_title')} fullHeight>
    <LabelledCardVerticalBody>
      <CardDataRow
        label={t('opportunity.project_type')}
        data={formatText(t(`project_kind.${opportunity.projectKind}`))}
      />
      <CardDataRow label={t('opportunity.project_usage')} data={formatText(t(`good_usage.${opportunity.goodUsage}`))} />
      <CardDataRow
        label={t('opportunity.project_good_type')}
        data={formatText(t(`property_kind.${opportunity.propertyKind}`))}
      />
      <CardDataRow label={t('opportunity.project_address')} data={formatAddress(opportunity.goodAddress)} />
      <CardDataRow label={t('opportunity.project_good_price')} data={formatAmount(opportunity.propertyPrice)} />
      <CardDataRow label={t('opportunity.project_apport')} data={formatAmount(opportunity.contribution)} />
      <CardDataRow label={t('opportunity.brokerage_fees')} data={formatAmount(opportunity.brokerageFees)} />
    </LabelledCardVerticalBody>
    <LabelledCardVerticalItem>
      <FormInput name={MANDATE_FIELDS.COMMENT} label={t('opportunity.mandate_modal.project_note')} />
    </LabelledCardVerticalItem>
  </LabelledCard>
)
