import { OPPORTUNITY_VIEW_MODE } from 'helpers/opportunity'
import React from 'react'
import { t } from 'utils/i18n'

import { ButtonGroup } from 'ui/buttons/buttonGroup'
import { ButtonGroupItem } from 'ui/buttons/buttonGroupItem'
import { CLICKS } from 'helpers/tracking'

export type ViewModeSelectProps = {
  viewMode: OPPORTUNITY_VIEW_MODE
  setViewMode: (viewMode: OPPORTUNITY_VIEW_MODE) => void
}

export const ViewModeSelect: React.FC<ViewModeSelectProps> = ({ viewMode, setViewMode }) => {
  return (
    <ButtonGroup>
      <ButtonGroupItem
        onClick={() => setViewMode(OPPORTUNITY_VIEW_MODE.KANBAN)}
        position="left"
        state={viewMode === OPPORTUNITY_VIEW_MODE.KANBAN ? 'active' : 'default'}
        tracking={[CLICKS.opportunitiesViewMode, { viewMode: OPPORTUNITY_VIEW_MODE.KANBAN }]}
      >
        {t('table_project.kanban_view')}
      </ButtonGroupItem>
      <ButtonGroupItem
        onClick={() => setViewMode(OPPORTUNITY_VIEW_MODE.LIST)}
        position="right"
        className="border-l-0"
        state={viewMode === OPPORTUNITY_VIEW_MODE.LIST ? 'active' : 'default'}
        tracking={[CLICKS.opportunitiesViewMode, { viewMode: OPPORTUNITY_VIEW_MODE.LIST }]}
      >
        {t('table_project.list_view')}
      </ButtonGroupItem>
    </ButtonGroup>
  )
}
