import { CalendarIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import React, { useCallback } from 'react'
import { t } from 'utils/i18n'
import { Task } from 'lib/oleenApi/services/tasksService'

import { Button } from 'ui/buttons/button'
import { Modal } from 'ui/modals/modal'
import { CLICKS } from 'helpers/tracking'
import { ROUTES } from 'router/routes'
import { useQuery } from '@tanstack/react-query'
import { mortgageOpportunityQuery } from 'helpers/queries'
import { Opportunity } from 'lib/oleenApi'
import { getOpportunityTitle } from 'helpers/opportunity'

type Props = {
  task: Task
  onClose: () => void
}

const buildGoogleCalendarUrl = (task: Task, opportunity: Opportunity | undefined) => {
  const urlSearchParams = new URLSearchParams()
  if (task.description) urlSearchParams.append('details', task.description)
  urlSearchParams.append('location', 'Paris')
  const start_date = dayjs(task.dueDate).format('YYYYMMDDTHHmmss')
  const end_date = dayjs(task.dueDate).add(dayjs.duration(task.duration)).format('YYYYMMDDTHHmmss')
  urlSearchParams.append('dates', `${start_date}/${end_date}`)
  urlSearchParams.append('ctz', 'Europe/Paris')
  if (opportunity) {
    urlSearchParams.append('text', `${getOpportunityTitle(opportunity)} - ${task.title}`)
    urlSearchParams.append(
      'details',
      `<a href=${window.location.host}${ROUTES.OPPORTUNITIES}/${task.taskableId}>${getOpportunityTitle(opportunity)}</a>`
    )
  } else {
    urlSearchParams.append('text', task.title)
  }
  return `https://calendar.google.com/calendar/r/eventedit?${urlSearchParams.toString()}`
}

export const SelectCalendarModal: React.FC<Props> = ({ task, onClose }) => {
  const opportunity = useQuery(mortgageOpportunityQuery(task.taskableId)).data
  const onGoogleCalendarClick = useCallback(() => {
    window.open(buildGoogleCalendarUrl(task, opportunity), '_blank')
  }, [task, opportunity])

  return (
    <Modal isOpen={true} onClose={onClose} className="modal--popup">
      <div>
        <CalendarIcon />
        <h2>{t('tasks.calendar_modal.title')}</h2>
        <Button
          onClick={onGoogleCalendarClick}
          className="button--primary button--outline button--medium w-full"
          tracking={[CLICKS.taskExportType, { type: 'google' }]}
        >
          {t('tasks.calendar_modal.google_calendar')}
        </Button>
      </div>
      <footer className="flex justify-center">
        <Button onClick={onClose} className="button--secondary button--outline button--medium">
          {t('tasks.calendar_modal.close')}
        </Button>
      </footer>
    </Modal>
  )
}
