import {
  ArrowTopRightOnSquareIcon,
  BookOpenIcon,
  CalendarDaysIcon,
  EnvelopeIcon,
  HomeIcon,
  PresentationChartBarIcon,
  PresentationChartLineIcon,
  SparklesIcon,
} from '@heroicons/react/20/solid'
import { id } from 'helpers/ref'
import React from 'react'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'

import { SidebarItem } from 'ui/legacy/atoms/sidebar/sidebarItem'
import { SidebarItemExpendable } from 'ui/legacy/atoms/sidebar/sidebarItemExpendable'

export const Sidebar: React.FC = () => {
  return (
    <div className="py-6 space-y-2 w-full">
      <SidebarItem highlight to={ROUTES.TASKS} Icon={HomeIcon}>
        {t('layout.menu_home')}
      </SidebarItem>
      <SidebarItemExpendable
        isOpenByDefault={true}
        Icon={PresentationChartBarIcon}
        navItems={[
          <SidebarItem key={id()} to={ROUTES.OPPORTUNITIES}>
            {t('layout.menu_opportunities')}
          </SidebarItem>,
          <SidebarItem key={id()} to={ROUTES.LEAD_CONTRIBUTORS}>
            {t('layout.menu_lead_contributors')}
          </SidebarItem>,
          <SidebarItem key={id()} to={ROUTES.PRODUCTION_DASHBOARD}>
            {t('layout.menu_dashboard')}
          </SidebarItem>,
        ]}
      >
        {t('layout.menu_production')}
      </SidebarItemExpendable>

      <SidebarItemExpendable
        Icon={PresentationChartLineIcon}
        navItems={[
          <SidebarItem key={id()} to={ROUTES.ORG_MEMBERS}>
            {t('layout.menu_dashboard')}
          </SidebarItem>,
        ]}
      >
        {t('layout.menu_mentoring')}
      </SidebarItemExpendable>

      <SidebarItem
        highlight
        to={t('url.layout.emails')}
        Icon={EnvelopeIcon}
        ActionIcon={ArrowTopRightOnSquareIcon}
        redirect
      >
        {t('layout.menu_emails')}
      </SidebarItem>

      <SidebarItem
        highlight
        to={t('url.layout.calendar')}
        Icon={CalendarDaysIcon}
        ActionIcon={ArrowTopRightOnSquareIcon}
        redirect
      >
        {t('layout.menu_calendar')}
      </SidebarItem>

      <SidebarItem highlight to={ROUTES.ROADMAP} Icon={SparklesIcon}>
        {t('layout.menu_roadmap')}
      </SidebarItem>

      <SidebarItem highlight to={ROUTES.USEFUL_LINKS} Icon={BookOpenIcon}>
        {t('layout.menu_useful_links')}
      </SidebarItem>
    </div>
  )
}
