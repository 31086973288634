import { DocumentArrowUpIcon } from '@heroicons/react/20/solid'
import { useNotificationsContext } from 'contexts/notifications'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'

import { NotificationItem } from 'ui/notifications/notificationItem'

import { Person } from './person'
import { CLICKS, track } from 'helpers/tracking'

type MortgageFilesUploadedNotificationProps = {
  id: string
  opportunityId: string
  mortgagor: {
    firstName: string | null
    lastName: string | null
    email: string | null
    id: string | null
  }
  files: string[]
  timing: string
  interacted: boolean
}

// FIXME
const Icon = (
  <div className="w-8 h-8 p-1.5 text-green-600 bg-green-100 flex justify-center items-center border-0 rounded-lg">
    <DocumentArrowUpIcon />
  </div>
)

export const MortgageFilesUploadedNotification: React.FC<MortgageFilesUploadedNotificationProps> = ({
  id,
  opportunityId,
  mortgagor,
  files,
  timing,
  interacted,
}) => {
  const navigate = useNavigate()
  const { toggleMenu, markAsInteracted } = useNotificationsContext()

  const onClick = () => {
    track(CLICKS.notification, { type: 'mortgage-files-uploaded' })
    toggleMenu()
    markAsInteracted(id)
    navigate(ROUTES.OPPORTUNITY.replace(':opportunityId', opportunityId))
  }

  const docs = files.slice(0, 3)
  const more = files.slice(3).length || null

  return (
    <NotificationItem
      onClick={onClick}
      Icon={Icon}
      interacted={interacted}
      title={
        <>
          {files.length === 1
            ? // FIXME: should be handled by i18n pluralization
              t('notification.files_uploaded.title_one', { count: files.length })
            : t('notification.files_uploaded.title_other', { count: files.length })}
          <Person person={mortgagor} />
        </>
      }
      subtitle={
        <>
          {docs.map((file, index) => (
            <p className="truncate" key={index.toString()}>
              {file}
            </p>
          ))}
          {more && <p className="antialiased font-bold">+{more}</p>}
        </>
      }
      timing={timing}
    />
  )
}
