import { FlagIcon } from '@heroicons/react/20/solid'
import { t } from 'i18next'
import { Opportunity } from 'lib/oleenApi'
import React from 'react'

interface OpportunityInfosProps {
  opportunity: Opportunity
}

const OpportunityInfos: React.FC<OpportunityInfosProps> = ({ opportunity }) => {
  return (
    <>
      {opportunity.assignedBos && (
        <div className="alert alert--accent alert--small">
          {<FlagIcon className="h-5 w-5" />}
          {t('opportunity.infos.assigned_bos', { assignedBos: opportunity.assignedBos })}
        </div>
      )}
    </>
  )
}

export default OpportunityInfos
