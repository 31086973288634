import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'
import { Alert } from 'ui/banners/alert'

import { DropdownOptionValue } from 'ui/dropdowns/dropdown/types'

export type DropdownOptionCalloutProps = {
  className?: string
  label: string
  Label?: React.ReactNode
  value?: DropdownOptionValue
}

export const DropdownOptionCallout: React.FC<PropsWithChildren<DropdownOptionCalloutProps>> = ({
  className,
  label,
  Label,
}) => {
  return (
    <div className={classNames('dropdownItem dropdownItem--callout', className)}>
      <Alert className="alert--warning alert--small">
        <span>{Label || label}</span>
      </Alert>
    </div>
  )
}
