import { useSessionContext } from 'contexts/session'
import React from 'react'
import { t } from 'utils/i18n'

export const Logout: React.FC = () => {
  const { logout } = useSessionContext()

  if (logout) {
    logout()
  }

  return (
    <div className="p-8">
      <div>{t('logout.processing')}</div>
      <div>{t('logout.if_error')}</div>
    </div>
  )
}
