import { useRepresentativeContext } from 'contexts/representative'
import { useSessionContext } from 'contexts/session'
import React from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { FormProvider } from 'ui/forms/form'
import { PageLayout } from 'ui/layouts/pageLayout/pageLayout'
import { AddonsCard } from 'components/account/addonsCard'

import { InformationsCard } from 'components/account/informationsCard'
import { ParametersCard } from 'components/account/parametersCard'
import { AccountSaveBar } from 'components/account/accountSaveBar'

import { Outlet } from 'react-router-dom'
import { AdvancedParametersCard } from 'components/account/advancedParametersCard'
import { CLICKS } from 'helpers/tracking'

const Account: React.FC = () => {
  const { logout } = useSessionContext()
  const { representative } = useRepresentativeContext()

  return (
    <PageLayout title={t('account.title')}>
      <FormProvider>
        <div className="py-6 pb-12 flex flex-col gap-6">
          {representative && <InformationsCard representative={representative} />}
          {representative && <ParametersCard representative={representative} />}
          {representative && <AdvancedParametersCard representative={representative} />}
          <AddonsCard />
          <div className="mt-6">
            <Button
              className="button--solid button--secondary button--small"
              onClick={logout}
              tracking={[CLICKS.logout]}
            >
              {t('logout.label')}
            </Button>
          </div>
        </div>
        <AccountSaveBar />
      </FormProvider>
      <Outlet />
    </PageLayout>
  )
}

export default Account
