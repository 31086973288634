import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { ApiError, ApiErrorDetail, BaseErrorDetailType } from '../apiError'
import { Client } from '../client'
import { ObjectCamelToSnakeCase } from '../misc'
import { Paginated } from '../types'

dayjs.extend(duration)

/**
 * Augments the 'Client' class with a method for accessing tasks services.
 */
declare module '../client' {
  interface Client {
    /**
     * Gets an instance of the TasksService.
     * @returns TasksService instance.
     */
    tasks: () => TasksService
  }
}

/**
 * Extension method to get TasksService.
 */
Client.prototype.tasks = function () {
  return new TasksService(this)
}

export interface Task {
  id: string
  title: string
  description: string | null
  dueDate: string
  duration: string
  taskableId: string
  completedAt: string | null
  archivedAt: string | null
  createdAt: string
}

export const taskDurations = [
  { value: dayjs.duration({ minutes: 15 }).toISOString(), label: '15 minutes' },
  { value: dayjs.duration({ minutes: 30 }).toISOString(), label: '30 minutes' },
  { value: dayjs.duration({ minutes: 45 }).toISOString(), label: '45 minutes' },
  { value: dayjs.duration({ minutes: 60 }).toISOString(), label: '1h' },
  { value: dayjs.duration({ minutes: 90 }).toISOString(), label: '1h30' },
  { value: dayjs.duration({ minutes: 120 }).toISOString(), label: '2h' },
  { value: dayjs.duration({ minutes: 180 }).toISOString(), label: '3h' },
]

export interface TaskMeta {
  remainingDueTodays: number
  remainingOverdues: number
  remainingDueLaters: number
}

export interface TaskFilters {
  due?: string | null
  completed?: string | null
  taskableId?: string | null
}

export type NewTask = Omit<Task, 'id' | 'completedAt' | 'archivedAt' | 'createdAt'>
type SerializedNewTask = ObjectCamelToSnakeCase<NewTask>
export type UpdatedTask = Omit<NewTask, 'taskableId'>
type SerializedUpdatedTask = ObjectCamelToSnakeCase<UpdatedTask>

type TaskResponse = ObjectCamelToSnakeCase<Task>
type TaskMetaResponse = ObjectCamelToSnakeCase<TaskMeta>

/**
 * Represents the type of errors for lead contributors.
 */
export type TaskErrorType = BaseErrorDetailType

/**
 * Represents the attributes for task errors
 */
export type TaskErrorAttribute = keyof (Omit<SerializedNewTask, 'taskable_id'> & { taskable_type: string | null })

/**
 * Represents the error details for task errors
 */
interface TaskErrorDetail extends ApiErrorDetail {
  type: TaskErrorType
  attribute: TaskErrorAttribute
}

/**
 * Represents the error type for task errors
 * @param message - The error message.
 * @param status - The error status.
 * @param details - The error details.
 * @returns A new instance of TaskApiError.
 */
export class TaskApiError extends ApiError<TaskErrorDetail> {
  constructor(message: string, status: number, details: TaskErrorDetail[]) {
    super(message, status, details)
  }
}

/**
 * Service for managing tasks.
 */
interface ITasksService {
  list: (page: number, pageSize: number, filter?: TaskFilters) => Promise<Paginated<Task, TaskMetaResponse>>
  get: (id: string) => Promise<Task>
  create: (data: NewTask) => Promise<Task>
  update: (id: string, data: UpdatedTask) => Promise<Task>
  complete: (id: string, completedAt: string | null) => Promise<Task>
}

class TasksService implements ITasksService {
  constructor(private client: Client) {
    client.setErrorHandler(TaskApiError)
  }

  /**
   * Gets a task by id.
   * @param page - The page number.
   * @param pageSize - The number of items per page.
   */
  list(page = 1, pageSize = 10, filters?: TaskFilters) {
    return this.client.getPaginatedData<TaskResponse, Task, TaskMetaResponse>(
      `/tasks?${serializeTaskFilters(filters)}`,
      page,
      pageSize,
      parseTask
    )
  }

  /**
   * Lists all tasks.
   * @returns A promise resolving to a list of all tasks.
   */
  async listAll(filters?: TaskFilters): Promise<Task[]> {
    let nextPage: null | number = 1
    const allTasks: Task[] = []

    while (nextPage) {
      const paginatedResult: Paginated<Task, TaskMetaResponse> = await this.list(nextPage, 10, filters)
      allTasks.push(...paginatedResult.items)
      nextPage = paginatedResult.nextPage
    }

    return allTasks
  }

  get(id: string) {
    return this.client.request<unknown, Task, TaskResponse>(
      {
        url: `/tasks/${id}`,
        method: 'GET',
      },
      response => parseTask(response.data)
    )
  }

  /**
   * Creates a new lead contributor.
   * @param data - The data of the task to create.
   * @returns A promise resolving to the creation result.
   */
  create(data: NewTask) {
    // Logic to create a lead contributor
    return this.client.request<NewTask, Task, TaskResponse>(
      {
        url: '/tasks',
        method: 'POST',
        data: serializeNewTask(data),
      },
      response => parseTask(response.data)
    )
  }

  update(id: string, data: UpdatedTask) {
    // Logic to create a lead contributor
    return this.client.request<UpdatedTask, Task, TaskResponse>(
      {
        url: `/tasks/${id}`,
        method: 'PUT',
        data: serializeUpdatedTask(data),
      },
      response => parseTask(response.data)
    )
  }

  complete(id: string, completedAt: string | null) {
    return this.client.request<unknown, Task, TaskResponse>(
      {
        url: `/tasks/${id}/complete`,
        method: 'POST',
        data: { completed_at: completedAt },
      },
      response => parseTask(response.data)
    )
  }
}

const parseTask = (data: TaskResponse): Task => {
  return {
    id: data.id,
    title: data.title,
    description: data.description,
    dueDate: data.due_date,
    duration: dayjs.duration({ minutes: parseInt(data.duration) / 60 }).toISOString(),
    taskableId: data.taskable_id,
    completedAt: data.completed_at,
    archivedAt: data.archived_at,
    createdAt: data.created_at,
  }
}

const defaultTime = '09:00:00'

const serializeNewTask = (task: NewTask): SerializedNewTask => {
  task.dueDate = `${task.dueDate} ${defaultTime}`

  return {
    title: task.title,
    description: task.description,
    due_date: task.dueDate,
    duration: task.duration,
    taskable_id: task.taskableId,
  }
}

const serializeUpdatedTask = (task: UpdatedTask): SerializedUpdatedTask => {
  task.dueDate = `${task.dueDate} ${defaultTime}`

  return {
    title: task.title,
    description: task.description,
    due_date: task.dueDate,
    duration: task.duration,
  }
}

const serializeTaskFilters = (filters?: TaskFilters): string => {
  const urlSearchParams = new URLSearchParams()
  if (filters?.due) urlSearchParams.append('due', filters.due)
  if (filters?.completed) urlSearchParams.append('completed', filters.completed.toString())
  if (filters?.taskableId) urlSearchParams.append('taskable_id', filters.taskableId)

  return urlSearchParams.toString()
}
