import React, { forwardRef } from 'react'

import { FieldState } from 'ui/forms/form.hook'
import { PhoneInput } from 'ui/inputs/phoneInput'

import 'react-international-phone/style.css'

export interface FormPhoneInputProps extends FieldState {
  disabled?: boolean
  id?: string
  placeholder?: string
}

export const FormPhoneInput = forwardRef<HTMLInputElement, FormPhoneInputProps>(
  ({ handleFieldChange, handleFieldBlur, value, id }, _ref) => {
    // TODO: improve error handling onBlur
    return (
      <PhoneInput
        id={id}
        onChange={handleFieldChange}
        // TODO: Need a batter way to handle the param to handleFieldBlur, without needing the arrow function
        onBlur={() => {
          handleFieldBlur(value as string)
        }}
        value={value as string}
      />
    )
  }
)
FormPhoneInput.displayName = 'FormPhoneInput'
