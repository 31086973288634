import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

import { DropdownOptionValue } from 'ui/dropdowns/dropdown/types'

export type DropdownOptionSeparatorProps = {
  className?: string
  label: string
  Label?: React.ReactNode
  value?: DropdownOptionValue
}

export const DropdownOptionSeparator: React.FC<PropsWithChildren<DropdownOptionSeparatorProps>> = ({
  className,
  label,
  Label,
}) => {
  return (
    <div className={classNames('dropdownItem dropdownItem--separator', className)}>
      <span className="separatorLabel">{Label || label}</span>
      <span className="separatorBorder">&nbsp;</span>
    </div>
  )
}
