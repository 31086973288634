import React, { createContext, useCallback, useContext, useEffect, useState } from 'react'

export enum EFeatureFlag {
  _ = 'empty', // fixes type inference when no feature flags
}

type FeatureFlags = { name: EFeatureFlag; value: boolean }[]

interface FeatureFlagsContext {
  featureFlags: FeatureFlags
  changeFlag: (flag: string) => void
  isFeatureFlagEnabled: (flag: string) => boolean
}

const FeatureFlagsContext = createContext<FeatureFlagsContext>({
  featureFlags: [],
  changeFlag: () => null,
  isFeatureFlagEnabled: () => false,
})

export const useFeatureFlagsContext: () => FeatureFlagsContext = () => useContext(FeatureFlagsContext)

const getFlag = (flag: string): boolean => JSON.parse(localStorage.getItem(flag) ?? 'false')

export const FeatureFlagsProvider: React.FC = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState<FeatureFlags>([])

  const refreshFeatureFlags = useCallback(() => {
    setFeatureFlags(
      Object.entries(EFeatureFlag)
        .slice(1)
        .map(([, flag]) => ({
          name: flag,
          value: getFlag(flag),
        }))
    )
  }, [setFeatureFlags])

  const isFeatureFlagEnabled = useCallback(
    (flag: string) => {
      return featureFlags.find(({ name }) => name === flag)?.value || false
    },
    [featureFlags]
  )

  useEffect(() => {
    refreshFeatureFlags()
  }, [refreshFeatureFlags])

  const changeFlag = useCallback(
    flag => {
      localStorage.setItem(flag, (!getFlag(flag)).toString())
      refreshFeatureFlags()
    },
    [refreshFeatureFlags]
  )

  return (
    <FeatureFlagsContext.Provider value={{ featureFlags, changeFlag, isFeatureFlagEnabled }}>
      {children}
    </FeatureFlagsContext.Provider>
  )
}
