import React, { useEffect, useState } from 'react'

import { NothingToDo } from './nothingToDo'
import { TaskTabs } from './taskTabs'
import { TasksTable } from './tasksTable'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { TaskFilters } from 'lib/oleenApi'
import { tasksQuery } from 'helpers/queries'

interface TasksTabsTableProps {
  defaultDueFilter: string
}

export const TasksTabsTable: React.FC<TasksTabsTableProps> = ({ defaultDueFilter }) => {
  const [filters, setFilters] = useState<TaskFilters>({ due: defaultDueFilter })
  const { data: tasks } = useQuery(tasksQuery(filters))
  const queryClient = useQueryClient()
  useEffect(() => {
    queryClient.invalidateQueries({ queryKey: ['tasks'] })
  }, [filters, queryClient])

  return (
    <>
      <TaskTabs filters={filters} setFilters={setFilters} />
      {tasks && tasks.length === 0 && <NothingToDo />}
      {tasks && tasks.length > 0 && <TasksTable tasks={tasks} />}
    </>
  )
}
