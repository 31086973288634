import React, { PropsWithChildren } from 'react'

type NotificationTrayProps = {
  title?: string
}

// FIXME : please help ¯\_(ツ)_/¯
export const NotificationTray: React.FC<PropsWithChildren<NotificationTrayProps>> = ({ children, title }) => (
  <div
    className={`bg-white lg:shadow lg:rounded-lg p-4 flex flex-col gap-2 antialiased lg:min-h-[616px] lg:max-h-[616px] overflow-y-scroll`}
  >
    <div className="hidden lg:block text-base font-bold text-gray-900">{title}</div>
    {children}
  </div>
)

export const NotificationTraySection: React.FC<PropsWithChildren<{ title?: string }>> = ({ children, title }) => (
  <div className="flex flex-col gap-2">
    <div className="text-sm leading-6 font-medium text-gray-900">{title}</div>
    {children}
  </div>
)
