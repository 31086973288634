import { track, Tracked } from 'helpers/tracking'
import React from 'react'

export const BUTTON_CIRCLE_VARIANTS = {
  BLUE: 'text-white bg-blue-600 hover:bg-blue-900',
  RED: 'text-white bg-red-600 border-red-600 hover:bg-red-900',
  GREEN: 'text-white bg-green-500 border-green-500 hover:bg-green-700',
  GRAY: 'text-gray-900 bg-gray-200 border-gray-200 hover:bg-gray-300',
  WHITE: 'text-gray-900 bg-white border-white hover:bg-gray-100',
} as const

export interface ButtonCircleProps {
  href?: string
  target?: string
  onClick?: () => void
  disabled?: boolean
  variant: string
  Icon: HeroIcon
}

export const ButtonCircle: React.FC<ButtonCircleProps & Partial<Tracked>> = ({
  variant,
  Icon,
  onClick,
  disabled,
  children,
  tracking,
  ...buttonProps
}) => {
  const trackedOnClick = () => {
    if (tracking) track(...tracking)
    if (onClick) onClick()
  }

  return (
    <div
      tabIndex={0}
      className={`w-8 h-8 flex items-center justify-center py-1 px-3 rounded-[58px] ${variant} cursor-pointer`}
    >
      <button {...buttonProps} disabled={disabled} onClick={trackedOnClick} className="w-5 h-5 absolute">
        <Icon />
      </button>
      {children}
    </div>
  )
}
