import React, { useCallback } from 'react'
import { Opportunity, OpportunityFile } from 'lib/oleenApi'
import { t } from 'utils/i18n'

import { Modal } from 'ui/modals/modal'
import { BOSOpportunityForm } from './bosOpportunityForm'
import { toast } from 'helpers/toast'
import { useFormContext } from 'ui/forms/form'
import { useOleenApi } from 'contexts/oleenApi'

interface BOSModalProps {
  isOpen: boolean
  closeModal: () => void
  opportunityId: Opportunity['id']
}

export const BOSOpportunityModal: React.FC<BOSModalProps> = ({ closeModal, opportunityId, isOpen }) => {
  const { updateFieldData } = useFormContext()
  const { oleenApi } = useOleenApi()

  const handleSuccess = () => {
    // NOTE: We need to set this field to pass the backend validation
    updateFieldData({ name: 'bos_files_uploaded', value: true, valid: true })
    toast.success(t('opportunity.bos_opportunity_modal.success'))
    closeModal()
  }

  const handleError = () => {
    toast.error(t('opportunity.bos_opportunity_modal.error'))
  }

  const action = useCallback(
    (file: OpportunityFile) => {
      return oleenApi.opportunityFiles().create(file, opportunityId)
    },
    [oleenApi, opportunityId]
  )

  const handleSkip = (bosAllowed: boolean) => {
    if (bosAllowed) {
      updateFieldData({ name: 'bos_files_uploaded', value: true, valid: true })
    }
    closeModal()
  }

  return (
    <Modal
      className="modal--form md:w-[450px] fixed"
      isOpen={isOpen}
      onClose={closeModal}
      title={t('opportunity.bos_opportunity_modal.title')}
    >
      <div className="text-xs">
        <p>{t('opportunity.bos_opportunity_modal.description')}</p>
        <div>
          <p className="font-semibold">{t('opportunity.bos_opportunity_modal.warning')}</p>
          <ul className="font-semibold list-disc list-inside ml-2">
            <li>{t('opportunity.bos_opportunity_modal.warning_li_1')}</li>
            <li>{t('opportunity.bos_opportunity_modal.warning_li_2')}</li>
          </ul>
        </div>
        <BOSOpportunityForm action={action} onSuccess={handleSuccess} onError={handleError} onSkip={handleSkip} />
      </div>
    </Modal>
  )
}
