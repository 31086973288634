import classNames from 'classnames'

// To be used with tanstack/react-form
export const textInputProps = (field: any) => ({
  className: classNames('max-md:textInput--medium', { 'textInput--error': field.state.meta.errors.length }),
  id: field.name,
  value: field.state.value,
  onChange: field.handleChange,
  onBlur: field.handleBlur,
})

export const textAreaProps = (field: any, className: string) => ({
  className: classNames('max-md:textArea--medium', className, { 'textArea--error': field.state.meta.errors.length }),
  id: field.name,
  value: field.state.value,
  onChange: field.handleChange,
  onBlur: field.handleBlur,
})
