import React from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { FormProvider } from 'ui/forms/form'

import Opportunity from './opportunity'

export const OpportunityPage: React.FC = () => {
  const { opportunityId } = useParams()

  return (
    <>
      <FormProvider>{opportunityId && <Opportunity opportunityId={opportunityId} />}</FormProvider>
      <Outlet />
    </>
  )
}
