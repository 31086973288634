import { useLeadContributors } from 'contexts/leadContributors'
import { InvitationState, LeadContributor } from 'lib/oleenApi'
import React, { useCallback, useState } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { Badge } from 'ui/badges/badge'
import { useOleenApi } from 'contexts/oleenApi'
import { InvitationApiError, InvitationType, InviteableType } from 'lib/oleenApi/services/invitationsService'
import { toast } from 'helpers/toast'

export const InvitationButton: React.FC<{ leadContributor: LeadContributor }> = ({ leadContributor }) => {
  const { refreshLeadContributors } = useLeadContributors()
  const { oleenApi } = useOleenApi()
  const [invitationIsPending, setInvititationIsPending] = useState(false)

  const inviteLeadContributor = useCallback(async () => {
    try {
      setInvititationIsPending(true)
      await oleenApi.invitations().create({
        type: InvitationType.Yuccanlead,
        inviteableId: leadContributor.id,
        inviteableType: InviteableType.LeadContributor,
      })
      refreshLeadContributors()
    } catch (error) {
      // We only stop the loading state when the call fails. When the call is
      // successful, the loading state will be stopped when the leadContributor
      // is refreshed.
      setInvititationIsPending(false)
      if (error instanceof InvitationApiError) {
        if (error.details.some(detail => detail.type === 'already_invited')) {
          toast.error(t('lead_contributors.invitation.error.already_invited'))
        } else {
          toast.error(t('lead_contributors.invitation.error.generic'))
        }
      }
    }
  }, [setInvititationIsPending, oleenApi, leadContributor, refreshLeadContributors])

  if (leadContributor.invitation === InvitationState.ACCEPTED || leadContributor.yuccanleadId) {
    return <Badge className="badge--green">{t('lead_contributors.invitation.badge.accepted')}</Badge>
  } else if (leadContributor.invitation === InvitationState.PENDING) {
    return <Badge className="badge--gray">{t('lead_contributors.invitation.badge.pending')}</Badge>
  } else if (leadContributor.invitation === InvitationState.SENT) {
    return <Badge className="badge--yellow">{t('lead_contributors.invitation.badge.sent')}</Badge>
  }

  return (
    <Button
      className="button--small button--secondary button--outline"
      onClick={inviteLeadContributor}
      isLoading={invitationIsPending}
    >
      {t('common.invite')}
    </Button>
  )
}
