import { BusinessPartnersProvider } from 'contexts/businessPartners'
import { LeadContributorsProvider } from 'contexts/leadContributors'
import { RepresentativeProvider } from 'contexts/representative'
import { MainLayout } from 'pages/layout/mainLayout'
import React from 'react'
import { Outlet, useSearchParams } from 'react-router-dom'

export const RepresentativeScope: React.FC = () => {
  const [searchParams] = useSearchParams()
  return (
    <>
      <RepresentativeProvider>
        <LeadContributorsProvider>
          <BusinessPartnersProvider>
            <MainLayout>
              <Outlet />
            </MainLayout>
          </BusinessPartnersProvider>
        </LeadContributorsProvider>
      </RepresentativeProvider>
      {searchParams.get('openStonly') && <div id={`stonly-form-${searchParams.get('openStonly')}`} />}
    </>
  )
}
