import { EnvelopeIcon, InformationCircleIcon, LockClosedIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { LabelledCardVerticalItem as VerticalItem } from 'ui/legacy/molecules/card/card.styled'

export const DocumentsCardConfirmationRow: React.FC = () => (
  <VerticalItem>
    <div className="flex justify-between items-center gap-6">
      <div className="flex flex-col items-start">
        <p className="text-sm font-bold antialiased leading-6 text-gray-800">{t('opportunity.mandate_confirmation')}</p>
        <div className="flex gap-1 items-center text-xs text-gray-500 font-normal antialiased">
          <InformationCircleIcon className="w-5 h-5" />
          {t('opportunity.no_mandate_confirmation')}
        </div>
      </div>
      <div className="flex items-center gap-2">
        <div className="bg-gray-700 px-2.5 py-0.5 text-white font-medium text-xs rounded-xl ml-3">{t('soon_msg')}</div>
        <Button className="button--outline button--secondary button--small" disabled>
          <div>{t('opportunity.send')}</div>
          <EnvelopeIcon />
        </Button>
      </div>
    </div>
  </VerticalItem>
)
