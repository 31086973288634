import { formatAddress, formatAmount, formatDate, formatText } from 'helpers/format'
import React from 'react'
import { t } from 'utils/i18n'

import {
  LabelledCardVerticalBody as VerticalBody,
  LabelledCardVerticalItem as VerticalItem,
} from 'ui/legacy/molecules/card/card.styled'
import { CardDataRow } from 'ui/legacy/molecules/card/cardDataRow'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { Opportunity } from 'lib/oleenApi'

interface ProjectCardProps {
  opportunity: Opportunity
}

export const ProjectCard: React.FC<ProjectCardProps> = ({ opportunity }) => (
  <LabelledCard icon={CARD_ICON.HomeIcon} title={t('opportunity.project_title')}>
    <VerticalBody>
      <CardDataRow label={t('opportunity.project_type')} data={t(`project_kind.${opportunity.projectKind}`)} />
      <CardDataRow label={t('opportunity.project_usage')} data={t(`good_usage.${opportunity.goodUsage}`)} />
      <CardDataRow label={t('opportunity.project_localisation')} data={formatAddress(opportunity.goodAddress)} />
      <CardDataRow
        label={t('opportunity.project_good_type')}
        data={formatText(t(`property_kind.${opportunity.propertyKind}`))}
      />
      <CardDataRow label={t('opportunity.project_good_price')} data={formatAmount(opportunity.propertyPrice)} />
      <CardDataRow label={t('opportunity.project_apport')} data={formatAmount(opportunity.contribution)} />
    </VerticalBody>
    <VerticalItem>
      <CardDataRow label={t('opportunity.project_state')} data={t(`maturity.${opportunity.maturity}`)} />
      <CardDataRow label={t('opportunity.project_date_compromise')} data={formatDate(opportunity.agreementSignDate)} />
      <CardDataRow label={t('opportunity.project_date_sign')} data={formatDate(opportunity.saleDate)} />
      <CardDataRow
        label={t('opportunity.project_suspensive_condition')}
        data={formatDate(opportunity.conditionsPrecedentLiftDate)}
      />
    </VerticalItem>
  </LabelledCard>
)
