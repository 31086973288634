import { useCallback, useState } from 'react'

type Props<T> = {
  isOpen: boolean
  openModal: (props?: T) => void
  closeModal: () => void
  props: T | undefined
}

export const useModal = <T>(): Props<T> => {
  const [isOpen, setIsOpen] = useState(false)
  const [props, setProps] = useState<T | undefined>(undefined)

  const openModal = useCallback(
    (props?: T) => {
      setIsOpen(true)
      setProps(props)
    },
    [setIsOpen, setProps]
  )

  const closeModal = useCallback(() => setIsOpen(false), [setIsOpen])

  return { isOpen, openModal, closeModal, props }
}
