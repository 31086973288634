import React from 'react'
import { toast as toastify, ToastOptions } from 'react-toastify'

import { ToastMsg, ToastMsgProps } from 'ui/banners/toast'

export const toast = (title: string, description?: string, options?: ToastOptions) =>
  toastify<ToastMsgProps>(args => <ToastMsg {...args} />, { ...options, data: { title, description } })

toast.success = (title: string, description?: string, options?: ToastOptions) =>
  toastify.success<ToastMsgProps>(args => <ToastMsg {...args} />, { ...options, data: { title, description } })

toast.error = (title: string, description?: string, options?: ToastOptions) =>
  toastify.error<ToastMsgProps>(args => <ToastMsg {...args} />, { ...options, data: { title, description } })

toast.warning = (title: string, description?: string, options?: ToastOptions) =>
  toastify.warning<ToastMsgProps>(args => <ToastMsg {...args} />, { ...options, data: { title, description } })

toast.info = (title: string, description?: string, options?: ToastOptions) =>
  toastify.info<ToastMsgProps>(args => <ToastMsg {...args} />, { ...options, data: { title, description } })
