import { Client } from '../../client'

/**
 * Augments the 'Client' class with a method for accessing OpportunityConsultancyContracts services.
 */
declare module '../../client' {
  interface Client {
    /**
     * Gets an instance of the OpportunityConsultancyContractsService.
     * @returns OpportunityConsultancyContractsService instance.
     */
    opportunityConsultancyContracts: () => OpportunityConsultancyContractsService
  }
}

/**
 * Extension method to get OpportunityConsultancyContractsService.
 */
Client.prototype.opportunityConsultancyContracts = function () {
  return new OpportunityConsultancyContractsService(this)
}

/**
 * Service for managing OpportunityConsultancyContracts.
 */
interface IOpportunityConsultancyContractsService {
  create: (opportunityId: string, data: unknown) => Promise<void>
}

class OpportunityConsultancyContractsService implements IOpportunityConsultancyContractsService {
  constructor(private client: Client) {}

  /**
   * Creates a new consultancy contract.
   * @param opportunityId - The opportunity ID.
   * @param data - The data of the task to create.
   * @returns A promise resolving to the creation result.
   */
  create(opportunityId: string, data: unknown) {
    // Logic to create a lead contributor
    return this.client.request<unknown, void, unknown>({
      url: `/mortgage/opportunities/${opportunityId}/send-consultancy-contract`,
      method: 'POST',
      data,
    })
  }
}
