import { TasksCounter } from 'components/tasks/tasksCounter'
import { TasksTabsTable } from 'components/tasks/tasksTabsTable'
import React, { useMemo } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { CLICKS } from 'helpers/tracking'
import { PlusIcon } from '@heroicons/react/20/solid'
import { useQuery } from '@tanstack/react-query'
import { tasksMetaQuery } from 'helpers/queries'

export const TasksPage: React.FC = () => {
  const navigate = useNavigate()
  const tasksMeta = useQuery(tasksMetaQuery())
  const dueFilter = useMemo(() => {
    return tasksMeta.data?.meta?.remaining_due_todays !== 0
      ? 'today'
      : tasksMeta.data?.meta?.remaining_overdues !== 0
        ? 'overdue'
        : tasksMeta.data?.meta?.remaining_due_laters !== 0
          ? 'later'
          : 'today'
  }, [
    tasksMeta.data?.meta?.remaining_due_laters,
    tasksMeta.data?.meta?.remaining_due_todays,
    tasksMeta.data?.meta?.remaining_overdues,
  ])

  return (
    <>
      <div className="flex flex-col items-start flex-1 self-stretch divide-y h-full">
        <header className="flex p-6 pb-4 flex-col items-start gap-4 self-stretch antialiased">
          <div className="flex justify-between items-center self-stretch">
            <div className="flex flex-1 items-center gap-2">
              <h1 className="md:text-gray-900 text-xl md:text-3xl font-normal md:font-bold leading-9">
                {t('tasks.page_title')}
              </h1>
            </div>
            <div className="flex items-center gap-4">
              <Button
                className="button--solid button--primary button--small pr-3"
                tracking={[CLICKS.taskCreate]}
                onClick={() => navigate(ROUTES.TASK_CREATE)}
              >
                <PlusIcon />
                {t('tasks.new')}
              </Button>
            </div>
          </div>
        </header>
        <section className="flex md:px-6 py-2 flex-col items-start gap-6 flex-1 w-full">
          <div className="flex flex-col items-start self-stretch">
            <div className="flex flex-col item-start gap-2 self-stretch mt-4 mb-6">
              <TasksCounter />
            </div>
            <div className="rounded-none md:rounded-lg border-0 md:border w-full border-gray-300 md:px-4 pb-4">
              {!tasksMeta.isLoading && <TasksTabsTable defaultDueFilter={dueFilter} />}
            </div>
          </div>
        </section>
      </div>
      <Outlet />
    </>
  )
}
