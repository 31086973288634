import React from 'react'
import classNames from 'classnames'

interface DotIconProps {
  disabled?: boolean
  active: boolean
}

export const DotIcon: React.FC<DotIconProps> = ({ active }) => {
  return (
    <div
      className={classNames('h-1.5 w-1.5 rounded-lg bg-gray-600 box-content border-2', {
        'border-gray-400 bg-gray-50': active,
        'border-gray-800 group-hover:border-gray-600 group-hover:bg-gray-400': !active,
      })}
    />
  )
}

export const getClassName = (disabled?: boolean, active?: boolean, highlight?: boolean) => `
  group flex rounded-lg px-3 h-fit py-2  text-sm whitespace-nowrap justify-between antialiased
  ${disabled ? 'text-gray-600' : 'text-white hover:bg-gray-600'}
  ${highlight || active ? 'font-medium' : 'font-light'}
  ${active ? 'bg-gray-600' : ''}
   `
