import {
  LeadContributorForm,
  LeadContributorFormData,
  LeadContributorFormError,
} from 'components/leadContributors/leadContributorForm'
import { useBusinessPartners } from 'contexts/businessPartners'
import { useLeadContributors } from 'contexts/leadContributors'
import { useOleenApi } from 'contexts/oleenApi'
import { toast } from 'helpers/toast'
import { useAsync } from 'hooks/async.hook'
import { LeadContributor, LeadContributorApiError } from 'lib/oleenApi'
import React, { useCallback, useEffect, useState } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { FormProvider, useFormContext } from 'ui/forms/form'
import { Modal, MODAL_SIZES } from 'ui/legacy/atoms/modals/modal'
import { CLICKS } from 'helpers/tracking'

type EditLeadContributorModalContentProps = {
  leadContributor: LeadContributor
  closeModal: () => void
}

const EditLeadContributorModalContent: React.FC<EditLeadContributorModalContentProps> = ({
  leadContributor,
  closeModal,
}) => {
  const { getFormData, isFormValid } = useFormContext()
  const { upsertLeadContributor } = useLeadContributors()
  const { refreshBusinessPartners } = useBusinessPartners()
  const { oleenApi } = useOleenApi()
  const [formError, setFormError] = useState<LeadContributorFormError>({} as LeadContributorFormError)

  const editLeadContributor = useCallback(() => {
    const leadContributorData = getFormData() as LeadContributorFormData
    return oleenApi.leadContributors().update(leadContributor.id, leadContributorData)
  }, [oleenApi, getFormData, leadContributor.id])

  const { data, error, run } = useAsync(editLeadContributor)

  useEffect(() => {
    if (!data) return

    toast.success(t('lead_contributors.toast.update.success'))
    upsertLeadContributor(data)
    refreshBusinessPartners()
    closeModal()
  }, [data, upsertLeadContributor, refreshBusinessPartners, closeModal])

  useEffect(() => {
    if (!error) return

    toast.error(t('lead_contributors.toast.update.error'))

    if (error instanceof LeadContributorApiError) {
      setFormError({})
      error.details.forEach(detail => {
        setFormError(prev => ({ ...prev, [detail.attribute]: detail.type }))
      })
    }
  }, [error])

  return (
    <LeadContributorForm error={formError} leadContributor={leadContributor}>
      <Button
        className="button--solid button--primary button--medium"
        disabled={!isFormValid}
        onClick={run}
        tracking={[CLICKS.leadContributorSave]}
      >
        <span>{t('lead_contributors.modal.edit.submit')}</span>
      </Button>
      <Button className="button--solid button--secondary button--medium" onClick={closeModal}>
        <span>{t('common.cancel')}</span>
      </Button>
    </LeadContributorForm>
  )
}

interface UpdateLeadContributorModalProps {
  leadContributor: LeadContributor
  isOpen: boolean
  closeModal: () => void
}

export const EditLeadContributorModal: React.FC<UpdateLeadContributorModalProps> = ({
  isOpen,
  closeModal,
  leadContributor,
}) => {
  if (!isOpen) return <></>
  return (
    <FormProvider>
      <Modal
        className="overflow-y-visible"
        size={MODAL_SIZES.MD}
        isOpen={isOpen}
        closeModal={closeModal}
        header={
          <div tabIndex={-1}>
            <p className="text-gray-900 text-base font-bold font-inter leading-6">
              {t('lead_contributors.modal.edit.title')}
            </p>
          </div>
        }
      >
        <EditLeadContributorModalContent leadContributor={leadContributor} closeModal={closeModal} />
      </Modal>
    </FormProvider>
  )
}
