import { CheckCircleIcon, ClockIcon } from '@heroicons/react/20/solid'
import { formatISODate } from 'helpers/format'
import React from 'react'
import { t } from 'utils/i18n'

import { CardSignatureRowContainer, CardSignatureRowLabel } from './card.styled'
import { SignatureRowProps } from './card.types'

export const CardSignatureRow = ({ signerRole, email, signedAt }: SignatureRowProps) => (
  <CardSignatureRowContainer>
    <CardSignatureRowLabel>
      {signedAt ? (
        <CheckCircleIcon className="w-5 h-5 text-green-500" />
      ) : (
        <ClockIcon className="w-5 h-5 text-gray-500" />
      )}
      <span className="font-normal text-gray-800 truncate">{signerRole}</span>
      <span className="text-gray-600 font-normal text-sm ">{email}</span>
    </CardSignatureRowLabel>
    {signedAt && (
      <span className="font-bold text-gray-800 truncate">
        {t('opportunity.mandate_partially_signed', { date: formatISODate(signedAt) })}
      </span>
    )}
  </CardSignatureRowContainer>
)
