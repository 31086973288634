import { DocumentTextIcon, PlusCircleIcon, XCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { forwardRef, ReactNode, useCallback, useImperativeHandle, useRef } from 'react'
import { useDropzone } from 'react-dropzone'
import { FileList } from 'ui/fileLists/fileList'

export type FileInputProps = {
  id?: string
  className?: string
  value?: File
  label?: ReactNode
  onChange: (value: File | undefined) => void
  onBlur?: () => void
}

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ id, className, value, label, onChange, onBlur }, ref: any) => {
    const fileInputRef = useRef<HTMLInputElement>(null)

    const onDrop = useCallback(
      acceptedFiles => {
        onChange(acceptedFiles?.[0])
      },
      [onChange]
    )

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    useImperativeHandle(ref, () => ({
      click: () => {
        if (fileInputRef.current) {
          fileInputRef.current.click()
        }
      },
    }))

    const handleClick = () => {
      if (fileInputRef.current) {
        fileInputRef.current.click()
      }
    }

    return (
      <>
        {value && <FileList files={[value]} handleDelete={() => onChange(undefined)} />}
        {!value && (
          <div {...getRootProps()}>
            <div
              className={classNames('fileInput', className, {
                'border-royalBlue-300 border-solid text-gray-900': isDragActive,
              })}
              onClick={handleClick}
            >
              <PlusCircleIcon />
              {label}
              <input
                id={id}
                className={className}
                type="file"
                accept="application/pdf, image/png, image/jpeg"
                onChange={({ currentTarget: { files } }) => onChange(files?.[0])}
                onBlur={onBlur}
                ref={fileInputRef}
                {...getInputProps()}
              />
            </div>
          </div>
        )}
      </>
    )
  }
)

FileInput.displayName = 'FileInput'
