import { Client } from '../../client'

/**
 * Augments the 'Client' class with a method for accessing OpportunityFiles services.
 */
declare module '../../client' {
  interface Client {
    /**
     * Gets an instance of the OpportunityFilesService.
     * @returns OpportunityFilesService instance.
     */
    opportunityFiles: () => OpportunityFilesService
  }
}

/**
 * Extension method to get OpportunityFilesService.
 */
Client.prototype.opportunityFiles = function () {
  return new OpportunityFilesService(this)
}

export interface OpportunityFile {
  data: File
  filename: string
}

/**
 * Service for managing OpportunityFiles.
 */
interface IOpportunityFilesService {
  create: (data: OpportunityFile, opportunityId: string) => Promise<void>
}

class OpportunityFilesService implements IOpportunityFilesService {
  constructor(private client: Client) {
    client.axiosInstance.defaults.headers.post['Content-Type'] = 'multipart/form-data'
  }

  /**
   * Creates a new lead contributor.
   * @param data - The data of the task to create.
   * @returns A promise resolving to the creation result.
   */
  create(data: OpportunityFile, opportunityId: string) {
    // Logic to create a lead contributor
    return this.client.request<OpportunityFile, void, unknown>({
      url: `/mortgage/opportunities/${opportunityId}/files`,
      method: 'POST',
      data: { file: data },
    })
  }
}
