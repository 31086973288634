import { useMutation } from '@tanstack/react-query'
import { OleenApi } from 'lib/oleenApi'
import React, { createContext, useCallback, useContext, useState } from 'react'

export interface INotificationsContext {
  menuIsOpen: boolean
  openMenu: () => void
  closeMenu: () => void
  toggleMenu: () => void
  markAsInteracted: (id: string) => void
}

export const NotificationsContext = createContext<INotificationsContext>({
  menuIsOpen: false,
  openMenu: () => null,
  closeMenu: () => null,
  toggleMenu: () => null,
  markAsInteracted: () => null,
})

export const NotificationsProvider: React.FC = ({ children }) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  const openMenu = useCallback(() => setMenuIsOpen(true), [setMenuIsOpen])
  const closeMenu = useCallback(() => setMenuIsOpen(false), [setMenuIsOpen])
  const toggleMenu = useCallback(() => (menuIsOpen ? closeMenu() : openMenu()), [menuIsOpen, closeMenu, openMenu])

  const markAsInteractedMutation = useMutation({
    mutationFn: (id: string) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.notifications().markAsInteracted(id)
    },
  })

  return (
    <NotificationsContext.Provider
      value={{
        menuIsOpen,
        openMenu,
        closeMenu,
        toggleMenu,
        markAsInteracted: markAsInteractedMutation.mutate,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotificationsContext: () => INotificationsContext = () => useContext(NotificationsContext)
