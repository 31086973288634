import {
  CheckCircleIcon,
  ExclamationTriangleIcon,
  FireIcon,
  InformationCircleIcon,
  XMarkIcon,
  XMarkIcon as XMarkIconSolid,
} from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { ReactNode, useState } from 'react'
import { ToastContentProps } from 'react-toastify'

import { ICON_BUTTON_VARIANTS, IconButton } from 'ui/buttons/iconButton'

export enum ToastVariants {
  GREEN,
  GRAY,
  ORANGE,
}

interface IToast {
  text: ReactNode
  Icon?: HeroIcon
  variant: ToastVariants
  closable?: boolean
}

const variantStyles = {
  [ToastVariants.GREEN]: 'text-green-900',
  [ToastVariants.GRAY]: 'text-gray-900',
  [ToastVariants.ORANGE]: 'text-orange-700',
}

const closeIconVariants = {
  [ToastVariants.GREEN]: ICON_BUTTON_VARIANTS.GREEN,
  [ToastVariants.GRAY]: ICON_BUTTON_VARIANTS.GRAY,
  [ToastVariants.ORANGE]: ICON_BUTTON_VARIANTS.GRAY,
}

const Toast: React.FC<IToast> = ({ text, Icon, variant, closable }) => {
  const [open, setOpen] = useState(true)

  return (
    <>
      {open && (
        <div
          className={classNames('flex p-2 items-center rounded-lg', {
            'text-green-900': variant === ToastVariants.GREEN,
            'text-gray-900': variant === ToastVariants.GRAY,
            'text-orange-700': variant === ToastVariants.ORANGE,
          })}
        >
          {Icon && <Icon className={`flex-shrink-0 w-5 h-5 ${variantStyles[variant]}`} />}
          <div className={`mx-3 flex-1 text-sm ${variantStyles[variant]}`}>{text}</div>
          {closable ? (
            <IconButton
              variant={closeIconVariants[variant]}
              Icon={XMarkIcon}
              onClick={() => setOpen(false)}
              data-dismiss-target="#alert-1"
            />
          ) : null}
        </div>
      )}
    </>
  )
}

export type ToastMsgProps = {
  title: string
  description?: string
  dismiss?: boolean
}

export const ToastMsg: React.FC<ToastContentProps<ToastMsgProps>> = ({ closeToast, toastProps, data }) => (
  <div
    className={classNames('max-h-sm flex gap-4 justify-between py-2 px-4 text-white rounded-lg mb-4 shadow', {
      'bg-green-500': toastProps.type === 'success',
      'bg-red-600': toastProps.type === 'error',
      'bg-orange-500': toastProps.type === 'warning',
      'bg-gray-900': toastProps.type === 'info',
      'bg-white': toastProps.type === 'default',
      'text-gray-900': toastProps.type === 'default',
    })}
  >
    <div className="flex gap-4 justify-between">
      <div>
        {toastProps.type === 'success' && <CheckCircleIcon className="w-5 h-5" />}
        {toastProps.type === 'error' && <ExclamationTriangleIcon className="w-5 h-5" />}
        {toastProps.type === 'warning' && <ExclamationTriangleIcon className="w-5 h-5" />}
        {toastProps.type === 'info' && <InformationCircleIcon className="w-5 h-5" />}
        {toastProps.type === 'default' && <FireIcon className="w-5 h-5" />}
      </div>
      <div>
        <p className="antialiased text-sm leading-6 font-bold">{data?.title}</p>
        <p className="antialiased text-sm leading-6 font-medium">{data?.description}</p>
      </div>
    </div>
    <div>
      {data?.dismiss && (
        <div onClick={closeToast}>
          <XMarkIconSolid className="w-5 h-5 cursor-pointer" />
        </div>
      )}
    </div>
  </div>
)

export default Toast
