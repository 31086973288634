import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

export type SliderProps = PropsWithChildren<{
  isOpen: boolean
  onClose: () => void
  onTransitionEnd: () => void
}>

export const Slider: React.FC<SliderProps> = ({ isOpen, onTransitionEnd, children, onClose }) => {
  return (
    <>
      <div
        onClick={onClose}
        data-open={isOpen}
        className={classNames(
          'bg-gray-900 fixed w-screen h-screen z-10 top-0 left-0 transition-opacity ease-in-out duration-300',
          'data-[open=true]:opacity-40 data-[open=false]:opacity-0'
        )}
      />
      <div
        data-open={isOpen}
        className={classNames(
          'z-10 fixed top-full bottom-0  w-full h-screen pt-3 bg-white',
          'flex flex-col',
          'transition-transform ease-in-out duration-300',
          'data-[open=true]:-translate-y-full',
          'data-[open=false]:translate-y-full',
          // desktop specific
          'md:top-0 md:w-1/2 md:-right-1/2',
          'data-[open=true]:md:-translate-y-0',
          'data-[open=false]:md:translate-y-0',
          'data-[open=true]:md:-translate-x-full data-[open=false]:md:translate-x-full'
        )}
        onTransitionEnd={onTransitionEnd}
      >
        {children}
      </div>
    </>
  )
}
