import React, { createContext, useContext } from 'react'
import ActionCable from 'actioncable'
import { SessionContext } from './session'

export interface CableApp {
  cable: ActionCable.Cable
}

export interface CableContextProps {
  subscribeOpportunityChannel: (opportunityId: string, reloadOpportunity: (opportunityId: string) => void) => void
}

export const CableContext = createContext<CableContextProps>({
  subscribeOpportunityChannel: () => null,
})

export const CableProvider: React.FC = ({ children }) => {
  const actionCableUrl = process.env.WEBSOCKET_BASE_URL as string
  const { session } = useContext(SessionContext)
  const cableApp: CableApp = {
    cable: ActionCable.createConsumer(`${actionCableUrl}?token=${session?.token}`),
  }

  const subscribeOpportunityChannel = (opportunityId: string, reloadOpportunity: (opportunityId: string) => void) => {
    const params = { channel: 'OpportunityChannel', opportunity_id: opportunityId }

    cableApp?.cable.subscriptions.create(params, {
      received: data => {
        if (data.message === 'finspot_project_pulled') {
          reloadOpportunity(opportunityId)
        }
      },
    })
  }

  return <CableContext.Provider value={{ subscribeOpportunityChannel }}>{children}</CableContext.Provider>
}

export const useCableContext = () => useContext(CableContext)
