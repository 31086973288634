import { useLeadContributors } from 'contexts/leadContributors'
import { EMPTY_SIGN, formatAmount, formatFullName, formatPercentage, formatSource } from 'helpers/format'
import { getOpportunityStatusBadgeColor } from 'helpers/opportunity'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'

import { Badge } from 'ui/badges/badge'
import { Table } from 'ui/tables/table'
import { Opportunity } from 'lib/oleenApi'

interface IOpportunityTableProps {
  opportunities: Opportunity[]
}

const OpportunityTable: React.FC<IOpportunityTableProps> = ({ opportunities }) => {
  const { getLeadContributor } = useLeadContributors()

  return (
    <div className="flex grow relative p-4">
      <div className="absolute inset-0 flex w-full h-full">
        <Table
          items={opportunities}
          cols={[
            {
              name: t('table_project.header.name'),
              className: 'tableCell--title',
              render: opportunity => (
                <Link key={opportunity.id} to={generatePath(ROUTES.OPPORTUNITY, { opportunityId: opportunity.id })}>
                  {formatFullName(opportunity.mortgagorFirstName, opportunity.mortgagorLastName)}
                </Link>
              ),
              sticky: true,
              sort: 'mortgagorLastName',
            },
            {
              name: t('table_project.header.status'),
              className: 'tableCell--text',
              render: opportunity => (
                <Badge key={opportunity.id} className={getOpportunityStatusBadgeColor(opportunity.status)}>
                  {t(`opportunity.statuses.${opportunity.status}`)}
                </Badge>
              ),
            },
            {
              name: t('table_project.header.maturity'),
              className: 'tableCell--text',
              render: opportunity => t(`maturity.${opportunity.maturity}`),
            },

            {
              name: t('table_project.header.gain'),
              className: 'tableCell--text',
              render: opportunity => formatAmount(opportunity.brokerageFees),
            },
            {
              name: t('table_project.header.docsCompletion'),
              className: 'tableCell--text',
              render: opportunity => formatPercentage(opportunity.docsCompletion, 0),
            },
            {
              name: t('table_project.header.source'),
              className: 'tableCell--text',
              render: opportunity =>
                opportunity.leadContributorId
                  ? formatSource(opportunity, getLeadContributor(opportunity.leadContributorId))
                  : EMPTY_SIGN,
            },
            {
              name: t('table_project.header.bank_commission'),
              className: 'tableCell--text',
              sort: 'bankCommission',
              render: opportunity => formatAmount(opportunity.bankCommission),
            },
          ]}
        />
      </div>
    </div>
  )
}
export default OpportunityTable
