import React, { HTMLProps, ReactNode } from 'react'

interface SearchBarOverlayItemProps {
  children: ReactNode
}

const SearchBarOverlayItem = React.forwardRef<HTMLDivElement, SearchBarOverlayItemProps & HTMLProps<HTMLDivElement>>(
  ({ children, ...rest }, ref) => {
    return (
      <div ref={ref} role="option" {...rest}>
        {children}
      </div>
    )
  }
)

SearchBarOverlayItem.displayName = 'SearchBarOverlayItem'

export default SearchBarOverlayItem
