import { DUMMY_CALLBACK } from 'helpers/format'
import React, { createContext, Dispatch, useCallback, useContext, useRef, useState } from 'react'

interface Field {
  name: string
  valid: boolean
  value?: unknown
}

type FormData = Record<string, { valid: boolean; value?: unknown }>

// We need to have any here as we cannot strictly type what value will be emitted by the callback
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FieldSubscription = (fieldName: string, callback: Dispatch<any>) => void

interface FormContext {
  isFormValid: boolean
  hasChanges: boolean
  register: (field: Field) => void
  unregister: (name: string) => void
  updateFieldData: (field: Field) => void
  getFormData: () => Record<string, unknown>
  watchField: FieldSubscription
  resetForm: () => void
}

export const FormContext = createContext<FormContext>({
  isFormValid: false,
  hasChanges: false,
  register: DUMMY_CALLBACK,
  unregister: DUMMY_CALLBACK,
  updateFieldData: DUMMY_CALLBACK,
  getFormData: () => ({}),
  watchField: DUMMY_CALLBACK,
  resetForm: DUMMY_CALLBACK,
})

export const useFormContext: () => FormContext = () => useContext(FormContext)

const checkIfFormValid = (formData: FormData): boolean =>
  Object.values(formData).filter(({ valid }) => !valid).length === 0

export const FormProvider: React.FC = ({ children }) => {
  // This is the object that stores the value and validity of each field inside the form
  const { current: formData } = useRef<FormData>({})
  // This is the object that stores all the listeners registered for fields in the form
  const { current: watchedFields } = useRef<Record<string, Dispatch<unknown>[]>>({})

  const [isFormValid, setIsFormValid] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)

  const watchField = useCallback<FieldSubscription>(
    (fieldName, listener) => {
      // 1 - add callback to watched fields
      const listeners = watchedFields[fieldName]
      watchedFields[fieldName] = !listeners ? [listener] : [...watchedFields[fieldName], listener]

      // 2 - emit value if it exists
      if (formData[fieldName]) {
        listener(formData[fieldName].value)
      }
    },
    [formData, watchedFields]
  )

  const register = useCallback<(field: Field) => void>(
    ({ name, valid, value }) => {
      // When a field renders, we register it inside the context data store with its initial values
      formData[name] = { valid, value }
      // If the updated field is valid, we check the whole form and set the isFormValid state accordingly
      setIsFormValid(checkIfFormValid(formData))
    },
    [formData]
  )

  const unregister = useCallback<(name: string) => void>(
    name => {
      // When a field unmounts, we remove the field from the form data store
      if (formData[name]) {
        delete formData[name]
        setIsFormValid(checkIfFormValid(formData))
      }
    },
    [formData]
  )

  const updateFieldData = useCallback<(field: Field) => void>(
    ({ name: fieldName, value, valid }) => {
      // we store the new value inside the context data store
      formData[fieldName] = { valid, value }

      // we emit the update to all listeners
      const listeners = watchedFields[fieldName] || []
      listeners.map(listener => listener(value))

      // If the updated field is valid, we check the wholeform and set the isFormValid state accordingly
      setIsFormValid(checkIfFormValid(formData))

      // on the first change of the whole form, we update the `hasChanges` state
      if (!hasChanges) {
        setHasChanges(true)
      }
    },
    [formData, hasChanges, watchedFields]
  )

  const getFormData = useCallback(() => {
    return Object.entries(formData).reduce<Record<string, unknown>>((form, [fieldName, { value }]) => {
      return { ...form, [fieldName]: value }
    }, {})
  }, [formData])

  const resetForm = useCallback(() => {
    setHasChanges(false)
  }, [])

  return (
    <FormContext.Provider
      value={{
        register,
        unregister,
        getFormData,
        updateFieldData,
        watchField,
        resetForm,
        isFormValid,
        hasChanges,
      }}
    >
      {children}
    </FormContext.Provider>
  )
}
