import { ChevronRightIcon } from '@heroicons/react/24/solid'
import React, { PropsWithChildren, ReactNode } from 'react'

import { AccordionItem } from 'ui/legacy/atoms/accordionItem/accordionItem'
import { useAccordionItem } from 'ui/legacy/atoms/accordionItem/accordionItem.hook'

interface IModalProps {
  AccordionBarHeader: ReactNode
  AccordionBarBody: ReactNode
  defaultIsOpen?: boolean
}

export const AccordionBar: React.FC<PropsWithChildren<IModalProps>> = ({
  AccordionBarHeader,
  AccordionBarBody,
  defaultIsOpen,
}) => {
  const { isOpen, toggleAccordionItem } = useAccordionItem({
    defaultIsOpen,
  })

  return (
    <div className={`border border-gray-300 rounded-lg ${isOpen ? 'divide-y' : ''} mb-3 px-4 py-2`}>
      <div className={`py-1 ${isOpen ? 'mb-2' : ''} cursor-pointer sticky`} onClick={() => toggleAccordionItem()}>
        <div className="flex">
          {AccordionBarHeader}
          <ChevronRightIcon className={`${isOpen && 'rotate-90'} text-gray-500 w-4 h-4 bold ml-auto mt-auto mb-auto`} />
        </div>
      </div>
      <AccordionItem isOpen={isOpen}>
        <div className="rounded-b-xl shadow-sm sticky">{AccordionBarBody}</div>
      </AccordionItem>
    </div>
  )
}
