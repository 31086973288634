import { ApiError, ApiErrorDetail, BaseErrorDetailType } from '../apiError'
import { Client } from '../client'
import { ObjectCamelToSnakeCase } from '../misc'
import { Paginated } from '../types'

/**
 * Augments the 'Client' class with a method for accessing lead contributors services.
 */
declare module '../client' {
  interface Client {
    /**
     * Gets an instance of the BusinessPartnersService.
     * @returns BusinessPartnersService instance.
     */
    businessPartners: () => BusinessPartnersService
  }
}

/**
 * Extension method to get BusinessPartnersService.
 */
Client.prototype.businessPartners = function () {
  return new BusinessPartnersService(this)
}

/**
 * List of business types for business partners.
 */
export const BUSINESS_TYPES = [
  'real_estate_agency',
  'real_estate_promoter',
  'notary',
  'professional_group',
  'craftsman',
  'wealth_manager',
  'other',
] as const

/**
 * Represents a business type.
 */
export type BusinessType = (typeof BUSINESS_TYPES)[number]

/**
 * Represents a business partner.
 */
export interface BusinessPartner {
  id: string
  name: string
  businessType: BusinessType
  leadContributorIds: string[]
}

type BusinessPartnerResponse = ObjectCamelToSnakeCase<Omit<BusinessPartner, 'leadContributorIds'>> & {
  lead_contributor_ids: string[]
}

/*
 * Represents a data structure for creating a new business partner.
 *
 * This type is used to create a new business partner thouh the create method of the business partners service.
 * It is a subset of the BusinessPartner type, without the id and leadContributorIds fields.
 *
 * @type
 *
 * @template NewBusinessPartner - The type of the new business partner.
 *
 * Example Usage:
 *
 * const newBusinessPartner: NewBusinessPartner = {
 *  name: 'Agence de l\'Etoile',
 *  businessType: 'agency',
 *  leadContributorIds: []
 * }
 *
 * businessPartnersService.create(newBusinessPartner);
 *
 */
export type NewBusinessPartner = Omit<BusinessPartner, 'id' | 'leadContributorIds' | 'businessType'> & {
  businessType: string | null
}

type SerializedNewBusinessPartner = ObjectCamelToSnakeCase<NewBusinessPartner>

/**
 * Represents the data structure for updating a business partner.
 *
 * This type is used to update a business partner through the update method of the business partners service.
 * It is a subset of the BusinessPartner type, without the id field.
 *
 * @type
 *
 * @template UpdatedBusinessPartner - The type of the updated business partner.
 *
 * Example Usage:
 *
 * const updatedBusinessPartner: UpdatedBusinessPartner = {
 *   name: 'Agence de l\'Etoile',
 *   businessType: 'agency',
 *   leadContributorIds: []
 * }
 *
 * businessPartnersService.update(updatedBusinessPartner);
 */
export type UpdatedBusinessPartner = Omit<BusinessPartner, 'id' | 'businessType' | 'leadContributorIds'> & {
  businessType: string
  leadContributorIds: string[]
}

type SerializedUpdatedBusinessPartner = ObjectCamelToSnakeCase<Omit<UpdatedBusinessPartner, 'leadContributorIds'>> & {
  lead_contributor_ids: string[]
}
/**
 * Represents the type of error returned by the business partners service.
 */
export type BusinessPartnerErrorType = BaseErrorDetailType

/**
 * Represents the attributes that can be invalid in a business partner.
 */
export type BusinessPartnerErrorAttribute = keyof SerializedNewBusinessPartner | keyof SerializedUpdatedBusinessPartner

/**
 * Represents the error detail returned by the business partners service.
 */
interface BusinessPartnerErrorDetail extends ApiErrorDetail {
  type: BusinessPartnerErrorType
  attribute: BusinessPartnerErrorAttribute
}

export class BusinessPartnerApiError extends ApiError<BusinessPartnerErrorDetail> {}

/**
 * Service for managing business partners.
 */
interface IBusinessPartnersService {
  list: (page: number, pageSize: number) => Promise<Paginated<BusinessPartner>>
  create: (businessPartner: NewBusinessPartner) => Promise<BusinessPartner>
  get: (id: string) => Promise<BusinessPartner>
  update: (id: string, businessPartner: UpdatedBusinessPartner) => Promise<BusinessPartner>
}

class BusinessPartnersService implements IBusinessPartnersService {
  constructor(private client: Client) {
    client.setErrorHandler(BusinessPartnerApiError)
  }

  /**
   * Gets a business partner by id.
   * @param page - The page number.
   * @param pageSize - The number of items per page.
   */
  list(page = 1, pageSize = 10) {
    return this.client.getPaginatedData<BusinessPartnerResponse, BusinessPartner>(
      '/business_partners',
      page,
      pageSize,
      parseBusinessPartner
    )
  }

  /**
   * Lists all business partners.
   * @returns A promise resolving to a list of all business partners.
   */
  async listAll(): Promise<BusinessPartner[]> {
    let nextPage: null | number = 1
    const allContributors: BusinessPartner[] = []

    while (nextPage) {
      const paginatedResult: Paginated<BusinessPartner> = await this.list(nextPage, 10)
      allContributors.push(...paginatedResult.items)
      nextPage = paginatedResult.nextPage
    }

    return allContributors
  }

  /**
   * Creates a new business partner.
   * @param data - The business partner to create.
   */
  create(data: NewBusinessPartner) {
    return this.client.request<NewBusinessPartner, BusinessPartner, BusinessPartnerResponse>(
      {
        url: '/business_partners',
        method: 'POST',
        data: serializeNewBusinessPartner(data),
      },
      response => parseBusinessPartner(response.data)
    )
  }

  /**
   * Gets a business partner by id.
   * @param id - The id of the business partner to get.
   */
  get(id: string) {
    return this.client.request<BusinessPartnerResponse, BusinessPartner>(
      {
        url: `/business_partners/${id}`,
        method: 'GET',
      },
      response => parseBusinessPartner(response.data)
    )
  }

  /**
   * Updates a business partner.
   * @param id - The id of the business partner to update.
   */
  update(id: string, data: UpdatedBusinessPartner) {
    return this.client.request<UpdatedBusinessPartner, BusinessPartner, BusinessPartnerResponse>(
      {
        url: `/business_partners/${id}`,
        method: 'PUT',
        data: serializeUpdatedBusinessPartner(data),
      },
      response => parseBusinessPartner(response.data)
    )
  }
}

const parseBusinessPartner = (data: BusinessPartnerResponse): BusinessPartner => ({
  id: data.id,
  name: data.name,
  businessType: data.business_type,
  leadContributorIds: data.lead_contributor_ids,
})

const serializeNewBusinessPartner = (data: NewBusinessPartner): SerializedNewBusinessPartner => ({
  name: data.name,
  business_type: data.businessType,
})

const serializeUpdatedBusinessPartner = (data: UpdatedBusinessPartner): SerializedUpdatedBusinessPartner => ({
  name: data.name,
  business_type: data.businessType,
  lead_contributor_ids: data.leadContributorIds,
})
