import { useRepresentativeContext } from 'contexts/representative'
import { formatFullName, formatText } from 'helpers/format'
import React from 'react'
import { t } from 'utils/i18n'

import { LabelledCardVerticalBody } from 'ui/legacy/molecules/card/card.styled'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'

export const SendMandateModalRepresentativeCard: React.FC = () => {
  const { representative } = useRepresentativeContext()

  return (
    <LabelledCard icon={CARD_ICON.UserIcon} title={t('opportunity.mandate_modal.representative_information_card')}>
      {/* FIXME - this card need to be populated with the agent's data */}
      <LabelledCardVerticalBody>
        <div>{formatFullName(representative?.firstName, representative?.lastName)}</div>
        <span className="font-semibold truncate">{representative?.email}</span>
        <div>{representative?.address}</div>
        <div>
          {t('opportunity.mandate_modal.representative_orias_number', {
            orias_id: formatText(representative?.oriasId),
          })}
        </div>
      </LabelledCardVerticalBody>
    </LabelledCard>
  )
}
