import { Paginated, PaginatedResponse, PostProcessFunction } from './types'

/**
 * Converts a paginated API response into a standard format.
 * @template S - The source type of the paginated response.
 * @template T - The target type after processing each item.
 * @template M - The target type after processing the meta object.
 * @param response - The paginated response to convert.
 * @param parser - A function to parse each item in the response.
 * @returns The paginated response in the standard format.
 */
export const parsePaginatedResponse = <S, T, M = undefined>(
  response: PaginatedResponse<S, M>,
  parser: PostProcessFunction<S, T>
): Paginated<T, M> => {
  const { items, page, total, per_page, next_page, prev_page, meta } = response
  return {
    items: items.map(parser),
    page,
    total,
    perPage: per_page,
    nextPage: next_page,
    prevPage: prev_page,
    meta,
  }
}
