import { ChevronLeftIcon } from '@heroicons/react/20/solid'
import { CLICKS } from 'helpers/tracking'
import React from 'react'
import { Button } from 'ui/buttons/button'
import { FormInput } from 'ui/forms/formInput'
import { t } from 'utils/i18n'

type EmailEditionSideProps = {
  emailSubject: string
  emailBody: string
  onReturn: () => void
  onSubjectCopy: () => void
  onBodyCopy: () => void
}

export const EmailEditionSide: React.FC<EmailEditionSideProps> = ({
  emailSubject,
  emailBody,
  onBodyCopy,
  onSubjectCopy,
  onReturn,
}) => {
  return (
    <>
      <div>
        <button className="flex items-center justify-between w-fit text-xs text-blue-600" onClick={onReturn}>
          <ChevronLeftIcon className="h-5 w-5" /> {'Retour'}
        </button>
        <div className="flex flex-col gap-2">
          <FormInput type="text" defaultValue={emailSubject} label="Objet" name="subject" />
          <Button
            className="button--small button--secondary button--outline w-fit"
            onClick={onSubjectCopy}
            tracking={[CLICKS.opportunityEmailModelSubjectCopy]}
          >
            {t('opportunity.emailModal.cta.copySubject')}
          </Button>
        </div>
        <div className="flex flex-col gap-2">
          <FormInput type="textArea" defaultValue={emailBody} label="Contenu" name="content" textAreaClassName="h-80" />
          <Button
            className="button--small button--secondary button--outline w-fit"
            onClick={onBodyCopy}
            tracking={[CLICKS.opportunityEmailModelBodyCopy]}
          >
            {t('opportunity.emailModal.cta.copyBody')}
          </Button>
        </div>
      </div>
    </>
  )
}
