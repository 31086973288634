import React from 'react'

export const ICON_BUTTON_VARIANTS = {
  GREEN: 'text-green-900 focus:ring-green-900',
  GRAY: 'text-gray-500 focus:ring-blue-300',
  DARK_BG: 'bg-gray-800 text-white',
}

interface IIconButtonProps {
  variant: string
  Icon: HeroIcon
  onClick?: () => void
  title?: string
}

const iconButtonBaseStyles = 'rounded-sm focus:ring-2 flex justify-center items-center h-10 w-10 lg:w-6 lg:h-6'

export const IconButton: React.FC<IIconButtonProps> = ({ variant, Icon, ...props }) => (
  <button className={`${iconButtonBaseStyles} ${variant}`} {...props}>
    <Icon className="h-5 w-5" />
  </button>
)
