import { IContact } from 'helpers/opportunityContact'
import React from 'react'

import { LabelledCardHorizontalBody } from 'ui/legacy/molecules/card/card.styled'

import ContactInformation from './contactInformation'
import { Alert } from 'ui/banners/alert'
import { t } from 'utils/i18n'

type IContactCardBodyProps = {
  primaryContact: IContact
  primaryTitle: string
  secondaryContact?: IContact
  secondaryTitle?: string
  projectId?: string | null
}

export const ContactCardBody: React.FC<IContactCardBodyProps> = ({
  primaryContact,
  primaryTitle,
  secondaryContact,
  secondaryTitle,
  projectId,
}) => {
  const hasSecondaryContact = !!secondaryContact
  // NOTE : Handle this condition using a backend validation
  const hasDuplicateEmail = secondaryContact && secondaryContact.email === primaryContact.email

  return (
    <div className="flex flex-col">
      {hasDuplicateEmail && (
        <Alert className="alert--error alert--small mx-4 mt-4">
          <p>
            {t('opportunity.mortgagors_duplicate_email_error.label')}
            <a target="_blank" href={t('url.zou_project_mortgagor', { projectId })} rel="noreferrer">
              {t('opportunity.mortgagors_duplicate_email_error.link')}
            </a>
          </p>
        </Alert>
      )}
      <LabelledCardHorizontalBody>
        <div className="grid grid-cols-2 gap-x-4 gap-y-6">
          <div className={hasSecondaryContact ? 'col-span-1' : 'col-span-2'}>
            <ContactInformation title={primaryTitle} {...primaryContact} />
          </div>
          {hasSecondaryContact && (
            <div>
              <ContactInformation title={secondaryTitle || ''} {...secondaryContact} />
            </div>
          )}
        </div>
      </LabelledCardHorizontalBody>
    </div>
  )
}
