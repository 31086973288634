import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider } from 'react-router-dom'
import 'utils/i18n'
import Sentry from 'utils/sentry'
import { MainRouter, queryClient } from './router/mainRouter'
import './style/index.scss'
import { QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const App: React.FC = Sentry.withProfiler(() => (
  <QueryClientProvider client={queryClient}>
    <RouterProvider router={MainRouter} />
    <ReactQueryDevtools buttonPosition="bottom-right" />
  </QueryClientProvider>
))

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<App />)
