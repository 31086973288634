import { ApiError, ApiErrorDetail, BaseErrorDetailType } from '../apiError'
import { Client } from '../client'
import { ObjectCamelToSnakeCase } from '../misc'

declare module '../client' {
  interface Client {
    invitations: () => InvitationsService
  }
}

Client.prototype.invitations = function () {
  return new InvitationsService(this)
}

/**
 * Represents an invitation.
 */
export interface Invitation {
  id: string
  type: string
  inviteableId: string
  inviteableType: string
  createdAt: string
  endedAt: string | null
  state: InvitationState
}

/**
 * Represents the status of an invitation.
 */
export enum InvitationState {
  PENDING = 'pending',
  SENT = 'sent',
  ACCEPTED = 'accepted',
}

/**
 * Represents the type of an invitation.
 */
export enum InvitationType {
  Yuccanlead = 'Yuccanlead',
}

/**
 * Represents the type of an inviteable.
 */

export enum InviteableType {
  LeadContributor = 'LeadContributor',
}

/**
 * Represents the shape of a new invitation.
 */
export type NewInvitation = Omit<Invitation, 'id' | 'createdAt' | 'endedAt' | 'state'>

/**
 * Represents the shape of a serialized new invitation.
 */
type SerializedNewInvitation = ObjectCamelToSnakeCase<NewInvitation>

export type InvitationErrorType = BaseErrorDetailType | 'already_invited'

export type InvitationErrorAttribute = 'base' | keyof SerializedNewInvitation

interface InvitationErrorDetail extends ApiErrorDetail {
  type: InvitationErrorType
  attribute: InvitationErrorAttribute
}

/**
 * Represents the shape of a new or updated invitation.
 */
export type InvitationResponse = ObjectCamelToSnakeCase<Invitation>

export class InvitationApiError extends ApiError<InvitationErrorDetail> {
  constructor(message: string, status: number, details: InvitationErrorDetail[]) {
    super(message, status, details)
  }
}

/**
 * Represents the service for managing invitations.
 */
interface IInvitationsService {
  create: (newInvitation: NewInvitation) => Promise<Invitation>
}

class InvitationsService implements IInvitationsService {
  constructor(private client: Client) {
    client.setErrorHandler(InvitationApiError)
  }

  create(newInvitation: NewInvitation) {
    return this.client.request<NewInvitation, Invitation, InvitationResponse>(
      {
        url: '/invitations',
        method: 'POST',
        data: serializeNewInvitation(newInvitation),
      },
      response => parseInvitation(response.data)
    )
  }
}

const serializeNewInvitation = (newInvitation: NewInvitation): SerializedNewInvitation => {
  return {
    type: newInvitation.type,
    inviteable_id: newInvitation.inviteableId,
    inviteable_type: newInvitation.inviteableType,
  }
}

const parseInvitation = (data: InvitationResponse): Invitation => {
  return {
    id: data.id,
    type: data.type,
    inviteableId: data.inviteable_id,
    inviteableType: data.inviteable_type,
    createdAt: data.created_at,
    endedAt: data.ended_at,
    state: data.state as InvitationState,
  }
}
