import React from 'react'
import { OleenApiProvider } from 'contexts/oleenApi'
import { SessionProvider } from 'contexts/session'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { Outlet, useMatches } from 'react-router-dom'
import { FeatureFlagsProvider } from 'contexts/featureFlags'
import { IEvent, track } from 'helpers/tracking'

export const ApplicationLayout: React.FC = () => {
  const matches = useMatches()

  React.useEffect(() => {
    const lastMatch = matches[matches.length - 1].handle
    if (!!lastMatch && typeof lastMatch === 'object' && 'page' in lastMatch) {
      track(lastMatch.page as IEvent)
    }
  }, [matches])

  return (
    <SessionProvider>
      <ToastContainer
        closeOnClick={false}
        closeButton={false}
        hideProgressBar={true}
        icon={false}
        toastClassName={() => ''}
        bodyClassName={() => ''}
      />
      <OleenApiProvider>
        <FeatureFlagsProvider>
          <Outlet />
        </FeatureFlagsProvider>
      </OleenApiProvider>
    </SessionProvider>
  )
}
