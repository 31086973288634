import { ArrowTopRightOnSquareIcon } from '@heroicons/react/24/solid'
import { OpportunityInterrogation } from 'components/opportunity/atoms/interrogation'
import { OpportunityInterrogationHeader } from 'components/opportunity/atoms/interrogationHeader'
import React from 'react'
import { t } from 'utils/i18n'

import { AccordionBar } from 'ui/legacy/atoms/accordionBar/accordionBar'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { Opportunity } from 'lib/oleenApi'
import { CLICKS, track } from 'helpers/tracking'

interface InterrogationsCardProps {
  opportunity: Opportunity
}

export const InterrogationsCard: React.FC<InterrogationsCardProps> = ({
  opportunity: { interrogations, projectId },
}) => {
  if (!interrogations) return <></>

  const editLink = (
    <div
      className="flex flex-row gap-2 text-blue-700 cursor-pointer"
      onClick={() => {
        track(CLICKS.opportunityOpenZouInterrogations)
        window.open(t('url.zou_interrogation', { projectId }), '_blank')
      }}
    >
      <p className="aliased text-sm font-medium leading-6">{t('opportunity.interrogation.edit')}</p>
      <ArrowTopRightOnSquareIcon className="w-5 h-5" />
    </div>
  )

  return (
    <LabelledCard
      icon={CARD_ICON.RocketLaunchIcon}
      title={t('opportunity.interrogations.title')}
      headerRightElement={editLink}
    >
      <div className="px-4 py-2">
        {projectId &&
          interrogations.map((i, index) => {
            return (
              <AccordionBar
                key={`interrogation_${i.id}`}
                AccordionBarHeader={<OpportunityInterrogationHeader index={index + 1} interrogation={i} />}
                AccordionBarBody={<OpportunityInterrogation interrogation={i} projectId={projectId} />}
                defaultIsOpen={true}
              />
            )
          })}
      </div>
    </LabelledCard>
  )
}
