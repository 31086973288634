export type CertificateItem = {
  label: string
  certificateType: CertificateType
  hasModal?: boolean
  Icon?: HeroIcon
}

export enum CertificateType {
  Feasability = 'Feasability',
  Deposit = 'Deposit',
  Simulation = 'Simulation',
}
