import React from 'react'

interface IKanbanSeparatorProps {
  label?: string
}

export const KanbanSeparator: React.FC<IKanbanSeparatorProps> = ({ label }) => {
  return (
    <div className="flex relative items-center justify-center text-[10px] text-gray-500 leading-4 antialiased cursor-default">
      <span className="relative bg-white [writing-mode:vertical-lr] rotate-180 py-2 z-10">{label}</span>
      <span className="absolute h-full border-l border-gray-300 z-0">&nbsp;</span>
    </div>
  )
}
