import { ErrorHandler, Validation } from 'helpers/errorHandler'
import { DUMMY_CALLBACK } from 'helpers/format'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { EMPTY_FIELD_VALUE, FieldErrorState } from 'types/input'

import { useFormContext } from 'ui/forms/form'

export interface UseFieldProps {
  name: string
  errorHandler?: ErrorHandler // deprecated
  validations?: Validation[]
  defaultValue?: string | number | boolean | null
  onBlur?: () => void
  onChange?: (value: string | number | boolean | null | undefined) => void
  autoFocus?: boolean
}

export const useFieldWatcher: <T>(fieldName: string) => T | null = <T>(fieldName: string) => {
  const [watchedValue, setWatchedValue] = useState<(null | T) & unknown>(null)
  const [isWathcing, setIsWatching] = useState<boolean>(false)
  const { watchField } = useFormContext()

  if (!isWathcing) {
    watchField(fieldName, setWatchedValue)
    setIsWatching(true)
  }

  return watchedValue
}

export const useField = ({ name, errorHandler, defaultValue, onBlur, onChange }: UseFieldProps): FieldState => {
  const { updateFieldData, register, unregister } = useFormContext()
  const updateFieldInContext = updateFieldData || DUMMY_CALLBACK

  const [value, setValue] = useState<string | number | boolean | null | undefined>(defaultValue || EMPTY_FIELD_VALUE)
  const [error, setError] = useState<string | null>(null)

  // This helps re-renering the component when the default value changes
  // It could be refactored in a custom hook (useUpdateEffect)
  const isInitialMount = useRef(true)
  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      return
    }

    setValue(defaultValue)
  }, [defaultValue])

  const checkErrors = useCallback(
    (value: string): FieldErrorState => ({
      valid: !errorHandler ? true : !errorHandler.hasError(value),
      message: errorHandler?.getErrorsHasText(value) || null,
    }),
    [errorHandler]
  )

  useEffect(() => {
    // registering should occur only at initial render.
    // We check that the default value is valid so that initial form state is correct
    // FIXME : checkErrors should be in the dependency array but it causes element reloading
    // which wrongly invalidates the form
    const { valid } = checkErrors((defaultValue as string) || EMPTY_FIELD_VALUE)
    register({ name, value: defaultValue, valid })
  }, [defaultValue, name, register])

  useEffect(() => () => unregister(name), [name, unregister])

  // this callback is responsible to set the value both locally in the state and in the form context
  const handleFieldChange = useCallback(
    (value?: string | number | boolean | null) => {
      setValue(value)
      const { valid } = checkErrors(value as string)
      updateFieldInContext({ name, value, valid })
      if (onChange) onChange(value)
    },
    [checkErrors, name, onChange, updateFieldInContext]
  )

  const handleFieldBlur = useCallback(
    (forcedValue?: string | number | boolean) => {
      setError(checkErrors((forcedValue as string) || (value as string)).message)
      if (onBlur) onBlur()
    },
    [checkErrors, value, onBlur]
  )

  const fieldState: FieldState = useMemo(
    () => ({
      value,
      error,
      handleFieldBlur,
      handleFieldChange,
    }),
    [error, handleFieldBlur, handleFieldChange, value]
  )

  return fieldState
}

export interface FieldState {
  value?: string | number | boolean | null
  error: string | null
  handleFieldBlur: (forcedValue?: string | number | boolean) => void
  handleFieldChange: (value?: string | number | boolean | null) => void
}
