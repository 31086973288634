import React from 'react'
import { FeeColorPillProps, StackedBarProps } from './tooltipSumCategory.types'

export const Wrapper = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex flex-col gap-4">{children}</div>
}

export const StackedBarWrapper = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex">{children}</div>
}

export const StackedBarItem: React.FC<StackedBarProps> = ({ share, color, isFirst, isLast, children }) => {
  const borderRadius = `
    ${isFirst ? 'rounded-l-[24px]' : ''}
    ${isLast ? 'rounded-r-[24px]' : ''}
  `

  const style = {
    width: `${share}%`,
    border: `2px solid ${color}`,
  }

  return (
    <div className={`${borderRadius}`} style={style}>
      {children}
    </div>
  )
}

export const TooltipDetailsWrapper = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex justify-between mt-[-12px] gap-24">{children}</div>
}

export const FeeColorPill = ({ color }: FeeColorPillProps) => {
  return <div className="min-w-[10px] h-[10px] mr-2" style={{ backgroundColor: color }}></div>
}

export const TooltipLabelWrapper = ({ children }: { children: React.ReactNode }) => {
  return <div className="flex items-center gap-2">{children}</div>
}
