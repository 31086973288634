import { ArrowLeftIcon } from '@heroicons/react/20/solid'
import { NavBar } from 'components/navBar/navBar'
import { Notification } from 'components/notifications/notification'
import { useNotificationsContext } from 'contexts/notifications'
import { useElementOnScreen } from 'hooks/elementOnScreen.hook'
import React, { useEffect } from 'react'
import { t } from 'utils/i18n'

import { NotificationTray, NotificationTraySection } from 'ui/notifications/notificationTray'
import { Spinner } from 'ui/spinner/spinner'
import { useInfiniteQuery } from '@tanstack/react-query'
import { OleenApi } from 'lib/oleenApi'

export const NotificationMenuList: React.FC = () => {
  const [logoref, isVisible] = useElementOnScreen()

  const notificationsQuery = useInfiniteQuery({
    queryKey: ['notifications'],
    queryFn: ({ pageParam }) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.notifications().list(pageParam)
    },
    initialPageParam: 0,
    getNextPageParam: lastPage => {
      return lastPage.nextPage
    },
  })

  useEffect(() => {
    if (isVisible) notificationsQuery.fetchNextPage()
  }, [isVisible, notificationsQuery])

  const yesterday = new Date(new Date().getTime() - 24 * 60 * 60 * 1000)

  const justNowNotifications = notificationsQuery.data?.pages
    .flatMap(n => n.items)
    .filter(notification => notification.createdAt >= yesterday)

  const earlierNotifications = notificationsQuery.data?.pages
    .flatMap(n => n.items)
    .filter(notification => notification.createdAt < yesterday)

  return (
    <>
      <MenuListNavBar />
      <NotificationTray title={t('notification.title')}>
        {justNowNotifications && (
          <NotificationTraySection title={t('notification.new')}>
            {justNowNotifications.map(n => (
              <Notification key={n.id} notification={n} />
            ))}
          </NotificationTraySection>
        )}
        {earlierNotifications && (
          <NotificationTraySection title={t('notification.earlier')}>
            {earlierNotifications.map(n => (
              <Notification key={n.id} notification={n} />
            ))}
          </NotificationTraySection>
        )}

        <div className={`self-center ${notificationsQuery.hasNextPage ? 'block' : 'hidden'}`} ref={logoref}>
          <Spinner />
        </div>
      </NotificationTray>
    </>
  )
}

// top bar for mobile
const MenuListNavBar = () => {
  const { closeMenu } = useNotificationsContext()

  return (
    <div className="lg:hidden text-white">
      <NavBar>
        <ArrowLeftIcon onClick={closeMenu} className="h-6 w-6 cursor-pointer" />
        <p>Notifications</p>
        <ArrowLeftIcon className="h-6 w-6 opacity-0" /> {/* cheating */}
      </NavBar>
    </div>
  )
}
