import { Opportunity, Representative } from 'lib/oleenApi'
import { DropdownOption } from 'ui/dropdowns/dropdown'
import { t } from 'utils/i18n'

export const mapDataToFrench = (opportunity: Opportunity, representative: Representative) => {
  return {
    statut: opportunity.status,
    avancementDuProjet: opportunity.maturity === 'offer_accepted' ? 'offre_acceptée' : opportunity.maturity,
    rendezVousBancaire: opportunity.bankAppointmentOn,
    offreBancaireReçueLe: opportunity.bankOfferReceivedOn,
    offreBancaireAcceptéeLe: opportunity.bankOfferAcceptedOn,
    aCoEmprunteur: opportunity.hasCoMortgagor,
    prénomEmprunteur: opportunity.mortgagorFirstName,
    nomEmprunteur: opportunity.mortgagorLastName,
    adresseEmprunteur: opportunity.mortgagorAddress,
    emailEmprunteur: opportunity.mortgagorEmail,
    téléphoneEmprunteur: opportunity.mortgagorPhone,
    professionEmprunteur: opportunity.mortgagorJobTitle,
    dateNaissanceEmprunteur: opportunity.mortgagorBirthdate,
    prénomCoEmprunteur: opportunity.coMortgagorFirstName,
    nomCoEmprunteur: opportunity.coMortgagorLastName,
    adresseCoEmprunteur: opportunity.coMortgagorAddress,
    emailCoEmprunteur: opportunity.coMortgagorEmail,
    téléphoneCoEmprunteur: opportunity.coMortgagorPhone,
    professionCoEmprunteur: opportunity.coMortgagorJobTitle,
    dateNaissanceCoEmprunteur: opportunity.coMortgagorBirthdate,
    avancementDuDossierEnPourcentage: opportunity.docsCompletion,
    dernièreMiseÀJourDuDossier: opportunity.docsCompletionLastUpdateDate,
    typeDeProjet: opportunity.projectKind === 'purchase' ? 'Achat' : opportunity.projectKind,
    usageDuBien: opportunity.goodUsage === 'primary_residence' ? 'Résidence principale' : opportunity.goodUsage,
    adresseDuBien: opportunity.goodAddress,
    typeDeBien: opportunity.propertyKind === 'old' ? 'Ancien' : opportunity.propertyKind,
    dateLevéeDesConditionsPrécédentes: opportunity.conditionsPrecedentLiftDate,
    dateVente: opportunity.saleDate,
    dateSignatureAccord: opportunity.agreementSignDate,
    prixAchat: opportunity.propertyPrice,
    apport: opportunity.contribution,
    montantDuPrêt: opportunity.loanAmount,
    duréeDuPrêtEnMois: opportunity.loanDuration,
    valeurDuPont: opportunity.bridgeValue,
    tauxDIntérêtEstimé: opportunity.mainLoansRate,
    remboursementMensuelDuPrêt: opportunity.monthlyLoanRepayment,
    statutDuMandat: opportunity.mandateStatus,
    signaturesDuMandat: opportunity.mandateSignatures,
    tauxDendettement: opportunity.indebtednessRate,
    fraisTotals: opportunity.totalFees,
    coûtTotalDuProjet: opportunity.totalProjectCost,
    dateDeLevéeDesConditionsSuspensives: opportunity.last_mortgage_deal_loss,
    adresseCourtier: representative.address,
    marqueCourtier: representative.brandName,
    raisonSocialeCourtier: representative.companyName,
    prénomCourtier: representative.firstName,
    nomCourtier: representative.lastName,
    idORIAS: representative.oriasId,
    emailCourtier: representative.email,
    téléphoneCourtier: representative.phone,
  }
}

const EMAIL_TYPES = [
  'askForAMeeting',
  'meetingRecap',
  'askForDocs',
  'searchUpdate',
  'askForSponsorship',
  'insurance',
] as const

export const EMAIL_TYPE_OPTIONS: DropdownOption[] = EMAIL_TYPES.map(value => ({
  value,
  label: t(`opportunity.emailModal.inputs.emailType.options.${value}`),
  type: 'text',
}))

const EMAIL_STYLES = ['formal', 'informal'] as const

export const EMAIL_STYLE_OPTIONS: DropdownOption[] = EMAIL_STYLES.map(value => ({
  value,
  label: t(`opportunity.emailModal.inputs.emailStyle.options.${value}`),
  type: 'text',
}))
