import { BellIcon } from '@heroicons/react/20/solid'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { NotificationMenuList } from 'components/notifications/notificationMenuList'
import { NotificationMenuListBackdrop } from 'components/notifications/notificationMenuListBackdrop'
import { NotificationMenuListWrapper } from 'components/notifications/notificationMenuListWrapper'
import { NotificationsProvider, useNotificationsContext } from 'contexts/notifications'
import { CLICKS, track } from 'helpers/tracking'
import { OleenApi } from 'lib/oleenApi'
import React, { useEffect, useMemo } from 'react'

import { BADGED_BUTTON_CIRCLE_VARIANTS, BadgedButtonCircle } from 'ui/buttons/badgedButtonCircle'

export const NotificationMenuButtonAndList = () => {
  const { menuIsOpen, toggleMenu } = useNotificationsContext()
  const queryClient = useQueryClient()

  const markNotificationsAsViewedMutation = useMutation({
    mutationFn: () => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.notifications().markAsSeen()
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['notifications', { unseen: true }] })
    },
  })

  const unseenNotificationsQuery = useQuery({
    queryKey: ['notifications', { unseen: true }],
    queryFn: () => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.notifications().list(1, 1, { unseen: true })
    },
  })

  const badgeNumber = useMemo(() => unseenNotificationsQuery.data?.total, [unseenNotificationsQuery])

  const onClick = () => {
    toggleMenu()
    markNotificationsAsViewedMutation.mutate()
  }

  return (
    <div className="relative">
      <div onClick={onClick}>
        <BadgedButtonCircle
          Icon={BellIcon}
          onClick={onClick}
          showBadge={!menuIsOpen && unseenNotificationsQuery.data?.total !== 0}
          badgeNumber={badgeNumber}
          variant={BADGED_BUTTON_CIRCLE_VARIANTS.GRAY}
          tracking={[CLICKS.notificationsMenu]}
        />
      </div>

      {menuIsOpen && <NotificationMenuListBackdrop onClick={onClick} />}
      <NotificationMenuListWrapper show={menuIsOpen}>
        <NotificationMenuList />
      </NotificationMenuListWrapper>
    </div>
  )
}

export const NotificationMenu = () => (
  <NotificationsProvider>
    <NotificationMenuButtonAndList />
  </NotificationsProvider>
)
