import React from 'react'
import { Opportunity } from 'lib/oleenApi'
import { BellIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import { EMPTY_SIGN } from 'helpers/format'
import { t } from 'utils/i18n'

export const ConditionsPrecedentLiftDateInfo: React.FC<{ opportunity: Opportunity }> = ({ opportunity }) => (
  <div className="flex gap-2 text-gray-500" title={t('opportunity.project_suspensive_condition')}>
    <BellIcon className="w-5 h-5" />
    <p className="text-xs font-normal">
      {opportunity.conditionsPrecedentLiftDate
        ? dayjs(opportunity.conditionsPrecedentLiftDate).format('DD/MM/YYYY')
        : EMPTY_SIGN}
    </p>
  </div>
)
