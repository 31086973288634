import classNames from 'classnames'
import { id } from 'helpers/ref'
import React from 'react'
import { t } from 'utils/i18n'

import { TooltipSumCategoryDetails } from './components/tooltipSumCategoryDetails'
import { TooltipSumCategoryStackedBar } from './components/tooltipSumCategoryStackedBar'
import { Wrapper } from './tooltipSumCategory.styled'
import { TooltipSumCategoryProps } from './tooltipSumCategory.types'

export const TooltipSumCategory: React.FC<TooltipSumCategoryProps> = ({ title, items, className }) => {
  return (
    <div
      className={classNames('bg-white rounded-xl shadow-xl p-4 border-2 border-[#1C64F2]/[.16]', className)}
      key={id()}
    >
      <Wrapper>
        <span className="text-1xl font-semibold text-gray-900 truncate">{t(`opportunity.statuses.${title}`)}</span>
        <TooltipSumCategoryStackedBar items={items} />
        <span className="uppercase text-black-500 font-semibold text-xs leading-6  truncate">
          {t('opportunity_kanban.amount_tooltip.turnover_detail')}
        </span>
        <TooltipSumCategoryDetails items={items} />
      </Wrapper>
    </div>
  )
}
