import React from 'react'

import { ToggleButtonProps } from 'ui/legacy/atoms/toggleButton/toggleButton.types'

export const ToggleButton = ({ checked, onChange }: ToggleButtonProps) => (
  <label className="inline-flex relative items-center cursor-pointer">
    <input type="checkbox" value="" className="sr-only peer" checked={checked} onChange={onChange} />
    <div className="w-11 h-6 bg-gray-200 peer-focus:outline-none rounded-full peer peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-blue-600" />
    <span className="ml-3 text-sm font-medium text-gray-900" />
  </label>
)
