import {
  DROP_MORTGAGE_OPPORTUNITY_FIELDS,
  MORTGAGE_OPPORTUNITY_LOSS_REASONS,
  REASON_FIELD_OPTIONS,
} from 'helpers/dropMortgageOpportunity'
import { errorHandlerMode } from 'helpers/errorHandler'
import React, { useMemo } from 'react'
import { CompetitorListType } from 'types/competitor'
import { t } from 'utils/i18n'

import { DropdownOption } from 'ui/dropdowns/dropdown'
import { useFieldWatcher } from 'ui/forms/form.hook'
import { FormInput } from 'ui/forms/formInput'

interface DropFormProps {
  competitors: CompetitorListType
}

export const DropOpportunityForm: React.FC<DropFormProps> = ({ competitors }) => {
  const reason = useFieldWatcher<MORTGAGE_OPPORTUNITY_LOSS_REASONS>(DROP_MORTGAGE_OPPORTUNITY_FIELDS.REASON)

  const secondaryFields = useMemo(() => {
    const isBankSelected = reason === MORTGAGE_OPPORTUNITY_LOSS_REASONS.BANK_COMPETITOR

    switch (reason) {
      case MORTGAGE_OPPORTUNITY_LOSS_REASONS.BANK_COMPETITOR:
      case MORTGAGE_OPPORTUNITY_LOSS_REASONS.BROKER_COMPETITOR: {
        const label = isBankSelected
          ? t('opportunity.drop_opportunity_modal.bank_competitor_name_label')
          : t('opportunity.drop_opportunity_modal.broker_competitor_name_label')

        const { banks, brokers } = competitors
        const banksOptions: DropdownOption[] = banks
          .filter(({ value }) => value !== 'autre')
          .map(bank => ({ type: 'text', ...bank }))
        const brokerOptions: DropdownOption[] = brokers.map(broker => ({ type: 'text', ...broker }))

        return (
          <>
            {isBankSelected ? (
              <FormInput
                isSearchable={true}
                selectClassName="selectInput--small"
                dropdownClassName="w-full"
                type="select"
                name={DROP_MORTGAGE_OPPORTUNITY_FIELDS.COMPETITOR_NAME}
                label={label}
                options={[banksOptions]}
              />
            ) : (
              <FormInput
                type="select"
                selectClassName="selectInput--small"
                name={DROP_MORTGAGE_OPPORTUNITY_FIELDS.COMPETITOR_NAME}
                defaultValue={brokers[0].value}
                options={[brokerOptions]}
              />
            )}
            <FormInput
              textInputClassName="textInput--small"
              name={DROP_MORTGAGE_OPPORTUNITY_FIELDS.LOAN_RATE}
              label={t('opportunity.drop_opportunity_modal.loan_rate_label')}
              validations={[{ type: errorHandlerMode.NUMBER }]}
            />
            <FormInput
              textInputClassName="textInput--small"
              name={DROP_MORTGAGE_OPPORTUNITY_FIELDS.LOAN_DURATION}
              label={t('opportunity.drop_opportunity_modal.loan_duration_label')}
              validations={[{ type: errorHandlerMode.NUMBER }]}
            />
          </>
        )
      }
      case MORTGAGE_OPPORTUNITY_LOSS_REASONS.NON_FUNDABLE:
        return (
          <FormInput
            textInputClassName="textInput--small"
            name={DROP_MORTGAGE_OPPORTUNITY_FIELDS.COMMENT}
            label={t('opportunity.drop_opportunity_modal.comment_label')}
          />
        )
      default:
        return <></>
    }
  }, [competitors, reason])

  return (
    <div className="text-sm flex-grow space-y-5">
      <FormInput
        type="select"
        selectClassName="selectInput--small"
        className="w-full"
        dropdownClassName="w-full"
        name={DROP_MORTGAGE_OPPORTUNITY_FIELDS.REASON}
        label={t('opportunity.drop_opportunity_modal.reason_label')}
        defaultValue={MORTGAGE_OPPORTUNITY_LOSS_REASONS.INACTIVE}
        options={[REASON_FIELD_OPTIONS]}
      />
      {secondaryFields}
    </div>
  )
}
