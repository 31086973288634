import { Client } from '../client'

/**
 * Augments the 'Client' class with a method for accessing tasks services.
 */
declare module '../client' {
  interface Client {
    /**
     * Gets an instance of the ZapierWebhooksService.
     * @returns ZapierWebhooksService instance.
     */
    zapierWebhooks: () => ZapierWebhooksService
  }
}

/**
 * Extension method to get ZapierWebhooksService.
 */
Client.prototype.zapierWebhooks = function () {
  return new ZapierWebhooksService(this)
}

/**
 * Service for managing tasks.
 */
interface ITasksService {
  run: (path: string, payload: unknown) => Promise<unknown>
}

class ZapierWebhooksService implements ITasksService {
  constructor(private client: Client) {}

  /**
   * Gets a task by id.
   * @param path - The path to the Zapier endpoint.
   * @param payload - The payload to send to the Zapier endpoint.
   */
  run(zap_path: string, payload: unknown): Promise<unknown> {
    return this.client.request({
      url: '/external/zapier/webhooks',
      method: 'POST',
      data: { zap_path, payload },
    })
  }
}
