import { getCompetitorLabel } from 'helpers/competitors'
import { getCompetitorTypeTranslated } from 'helpers/opportunity'
import React from 'react'
import { CompetitorType } from 'types/competitor'
import { MORTGAGE_DEAL_LOSS_REASON } from 'types/mortgageDealLoss'
import { t } from 'utils/i18n'

import { LabelledCardVerticalBody as VerticalBody } from 'ui/legacy/molecules/card/card.styled'
import { CardDataRow } from 'ui/legacy/molecules/card/cardDataRow'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { Opportunity } from 'lib/oleenApi'

interface LostReasonCardProps {
  opportunity: Opportunity
}

export const LostReasonCard: React.FC<LostReasonCardProps> = ({ opportunity }) => {
  const last_mortgage_deal_loss = opportunity.last_mortgage_deal_loss

  if (!last_mortgage_deal_loss) {
    return <></>
  }

  const renderLostReasonDetails = () => {
    if (last_mortgage_deal_loss.competitor_name) {
      let competitorType = CompetitorType.bank
      let loanRate = '-'
      let loanDuration = '-'

      if (last_mortgage_deal_loss.reason === 'broker_competitor') competitorType = CompetitorType.broker

      if (last_mortgage_deal_loss.loan_rate)
        loanRate = t('opportunity.opportunity_lost_reason_loan_rate_percentage', {
          number: last_mortgage_deal_loss.loan_rate,
        })

      if (last_mortgage_deal_loss.loan_duration)
        loanDuration = t('opportunity.opportunity_lost_reason_loan_duration_month', {
          number: last_mortgage_deal_loss.loan_duration,
        })

      return (
        <>
          <CardDataRow
            label={getCompetitorTypeTranslated(competitorType)}
            data={getCompetitorLabel(last_mortgage_deal_loss.competitor_name)}
          />
          <CardDataRow label={t('opportunity.opportunity_lost_reason_loan_rate')} data={loanRate} />
          <CardDataRow label={t('opportunity.opportunity_lost_reason_loan_duration')} data={loanDuration} />
        </>
      )
    }

    if (last_mortgage_deal_loss.comment) {
      return (
        <CardDataRow
          label={t('opportunity.opportunity_lost_reason_comment')}
          data={last_mortgage_deal_loss.comment ?? '-'}
        />
      )
    }

    return <></>
  }

  return (
    <LabelledCard icon={CARD_ICON.FolderOpenIcon} title={t('opportunity.opportunity_lost_reason_title')}>
      <VerticalBody>
        <CardDataRow
          label={t('opportunity.opportunity_lost_reason')}
          data={MORTGAGE_DEAL_LOSS_REASON[last_mortgage_deal_loss.reason] ?? '-'}
        />
        {renderLostReasonDetails()}
      </VerticalBody>
    </LabelledCard>
  )
}
