import React from 'react'

import _ from 'lodash'
import classNames from 'classnames'

type IProgressBar = {
  className?: string
  value: number
  isValueVisible?: boolean
}

// We can't use tailwind for width because it is computed dynamically.
// Eg: `w-[${value}%]` won't work
const ProgressBar: React.FC<IProgressBar> = ({ className, value }) => (
  <div
    className={classNames(
      'progressBar',
      {
        'progressBar-right': !className?.includes('progressBar-'),
      },
      className
    )}
  >
    <span className="text-xs text-gray-500 leading-5 mb-0.5">{value || 0}%</span>
    <div className="w-full rounded-sm h-1.5 bg-gray-200">
      <div className={`bg-blue-600 h-1.5 rounded-sm`} style={{ width: `${value}%` }} />
    </div>
  </div>
)

export default ProgressBar
