import classNames from 'classnames'
import { id as getId } from 'helpers/ref'
import React, { forwardRef, PropsWithChildren } from 'react'

export type CheckBoxToggleProps = PropsWithChildren<{
  name?: string
  className?: string
  label?: string
  value: boolean
  onChange: (value: boolean) => void
  onBlur?: () => void
  disabled?: boolean
}>

// TODO: refact without peer utility (maybe with data-attribute) to avoid style
// in label className
export const CheckBoxToggle = forwardRef<HTMLInputElement, CheckBoxToggleProps>((props, ref) => {
  const inputId = getId()

  return (
    <div className={classNames('toggle', props.className)}>
      {props.label && <label htmlFor={inputId}>{props.label}</label>}
      <div>
        <input
          id={inputId}
          ref={ref}
          type="checkbox"
          name={props.name}
          className="sr-only peer"
          value={props.value.toString()}
          onBlur={props.onBlur}
          onChange={() => {
            props.onChange(!props.value)
          }}
        />
        <label htmlFor={inputId} />
      </div>
    </div>
  )
})
CheckBoxToggle.displayName = 'CheckBoxToggle'
