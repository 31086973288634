import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOleenApi } from 'contexts/oleenApi'
import { useRepresentativeContext } from 'contexts/representative'
import { trackEvent, TRACKING_EVENT } from 'helpers/segment'
import { Representative } from 'lib/oleenApi'
import React, { useCallback } from 'react'

import { useFormContext } from 'ui/forms/form'
import { SaveBar } from 'ui/legacy/atoms/saveBar/saveBar'
import { SAVEBAR_SCREEN } from 'ui/legacy/atoms/saveBar/saveBar.styled'

type MutationParams = {
  representative: Representative
}

export const AccountSaveBar: React.FC = () => {
  const { representative } = useRepresentativeContext()
  const { isFormValid, hasChanges, getFormData, resetForm } = useFormContext()
  const { oleenApi } = useOleenApi()
  const queryClient = useQueryClient()

  const update = useMutation({
    mutationFn: ({ representative }: MutationParams) =>
      oleenApi.representatives().update(representative.id, representative),
    onSuccess: () => {
      resetForm()
      trackEvent(TRACKING_EVENT.ACCOUNT_PARAMETERS.FEES_UPDATED)
      queryClient.invalidateQueries({ queryKey: ['representative'] })
    },
  })

  const onSubmit = useCallback(async () => {
    if (!representative) return false

    await update.mutate({
      representative: {
        ...representative,
        defaultBrokerageFees: Number(getFormData().value),
      },
    })
    return true
  }, [getFormData, representative, update])

  if (!representative) {
    return <></>
  }

  return <SaveBar shown={isFormValid && hasChanges} submit={onSubmit} screen={SAVEBAR_SCREEN.account} />
}
