import { Client } from '../client'

/**
 * Augments the 'Client' class with a method for accessing mistral api key services.
 */
declare module '../client' {
  interface Client {
    /**
     * Gets an instance of the MistralApiKeyService.
     * @returns MistralApiKeyService instance.
     */
    mistralApiKey: () => MistralApiKeyService
  }
}

/**
 * Extension method to get MistralApiKeyService.
 */
Client.prototype.mistralApiKey = function () {
  return new MistralApiKeyService(this)
}

type MistralApiKey = {
  api_key: string
}

/**
 * Service for managing mistral api key.
 */
interface IMistralApiKeyService {
  get: () => Promise<MistralApiKey>
}

class MistralApiKeyService implements IMistralApiKeyService {
  constructor(private client: Client) {}

  /**
   * Gets the mistrals api key.
   * @returns The mistral api key.
   */
  get() {
    return this.client.request<MistralApiKey, MistralApiKey>({
      url: '/external/mistral/api_key',
      method: 'GET',
    })
  }
}
