export type MortgageDealLossReasonType =
  | 'inactive'
  | 'non_fundable'
  | 'bank_competitor'
  | 'broker_competitor'
  | 'project_stopped'
  | 'duplicate'
  | 'representative_stopped'
  | 'non_attractive_buyback'

export const MORTGAGE_DEAL_LOSS_REASON: Record<MortgageDealLossReasonType, string> = {
  inactive: 'Projet lointain / pas de visites actives',
  non_fundable: 'Dossier non finançable',
  bank_competitor: 'Perdu contre une autre banque',
  broker_competitor: 'Perdu contre un autre courtier',
  project_stopped: "Projet arrêté - plus d'achat/rachat",
  duplicate: 'Doublon',
  representative_stopped: 'No-go mandataire',
  non_attractive_buyback: 'Rachat pas intéressant',
}

export type MortgageDealLoss = {
  reason: MortgageDealLossReasonType
  competitor_name: string | null
  loan_duration: number | null
  loan_rate: number | null
  comment: string | null
}
