import { SortConfig, SortDirection } from './types'
import { getValueByPath, isNullish } from './utils'

/**
 * Function to sort table items based on the given sort configuration.
 *
 * @param {T[]} items - The items to sort.
 * @param {SortConfig<T>} sortConfig - The configuration for sorting.
 * @returns {T[]} The sorted array of items.
 */
export const sortItems = <T>(items: T[], sortConfig: SortConfig<T> | null): T[] => {
  const sortableItems = [...items]
  if (sortConfig !== null) {
    sortableItems.sort((a, b) => {
      // const ak = a[sortConfig.key as keyof typeof a]
      // const bk = b[sortConfig.key as keyof typeof b]
      const ak = getValueByPath(a, sortConfig.key)
      const bk = getValueByPath(b, sortConfig.key)

      // Gérer null ou undefined pour 'ak' et 'bk'
      if (isNullish(ak) && isNullish(bk)) return 0
      if (isNullish(ak)) return sortConfig.direction === SortDirection.ASCENDING ? -1 : 1
      if (isNullish(bk)) return sortConfig.direction === SortDirection.ASCENDING ? 1 : -1

      if (ak < bk) {
        return sortConfig.direction === SortDirection.ASCENDING ? -1 : 1
      }
      if (ak > bk) {
        return sortConfig.direction === SortDirection.ASCENDING ? 1 : -1
      }
      return 0
    })
  }
  return sortableItems
}
