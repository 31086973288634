import { Buffer } from 'buffer'
import { Client } from '../../client'

/**
 * Augments the 'Client' class with a method for accessing OpportunityMandates services.
 */
declare module '../../client' {
  interface Client {
    /**
     * Gets an instance of the OpportunityMandatesService.
     * @returns OpportunityMandatesService instance.
     */
    opportunityMandates: () => OpportunityMandatesService
  }
}

/**
 * Extension method to get OpportunityMandatesService.
 */
Client.prototype.opportunityMandates = function () {
  return new OpportunityMandatesService(this)
}

export type MandateParams = {
  comment: string
}

export const buildMandateParams = (comment?: string): MandateParams => ({
  comment: (comment ? Buffer.from(comment, 'utf-8') : '').toString('base64'),
})

type PreviewParams = MandateParams

type PreviewResponse = {
  url: string
}

/**
 * Service for managing OpportunityMandates.
 */
interface IOpportunityMandatesService {
  preview: (opportunityId: string, params: PreviewParams) => Promise<PreviewResponse>
  send: (opportunityId: string, params: MandateParams) => Promise<PreviewResponse>
}

class OpportunityMandatesService implements IOpportunityMandatesService {
  constructor(private client: Client) {}

  preview(opportunityId: string, params: PreviewParams): Promise<PreviewResponse> {
    return this.client.request<PreviewResponse, PreviewResponse>({
      url: `/mortgage/opportunities/${opportunityId}/mandates/preview`,
      method: 'POST',
      data: params,
    })
  }

  send(opportunityId: string, params: MandateParams): Promise<PreviewResponse> {
    return this.client.request<PreviewResponse, PreviewResponse>({
      url: `/mortgage/opportunities/${opportunityId}/mandates`,
      method: 'POST',
      data: params,
    })
  }
}
