import { Client } from '../client'

/**
 * Augments the 'Client' class with a method for accessing product board token services.
 */
declare module '../client' {
  interface Client {
    /**
     * Gets an instance of the ProductBoardTokenService.
     * @returns ProductBoardTokenService instance.
     */
    productBoardToken: () => ProductBoardTokenService
  }
}

/**
 * Extension method to get ProductBoardTokenService.
 */
Client.prototype.productBoardToken = function () {
  return new ProductBoardTokenService(this)
}

type ProductBoardToken = {
  token: string
}

/**
 * Service for managing product board token.
 */
interface IProductBoardTokenService {
  get: () => Promise<ProductBoardToken>
}

class ProductBoardTokenService implements IProductBoardTokenService {
  constructor(private client: Client) {}

  /**
   * Gets the product board token.
   * @returns The product board token.
   */
  get() {
    return this.client.request<ProductBoardToken, ProductBoardToken>({
      url: '/external/product_board/token',
      method: 'GET',
    })
  }
}
