import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { AddonName } from 'lib/oleenApi'
import { YuccanleadSubscriptionModal } from 'components/addons/yuccanleadSusbscriptionModal'
import { toast } from 'helpers/toast'
import { t } from 'utils/i18n'

import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useOleenApi } from 'contexts/oleenApi'
import { useRepresentativeContext } from 'contexts/representative'
import { AddonSubscriptionApiError, NewAddonSubscription } from 'lib/oleenApi/services/addonSubscriptionService'

export const AddonSubscriptionPage: React.FC = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const { addonName } = useParams()
  const { oleenApi } = useOleenApi()
  const { representative } = useRepresentativeContext()
  const [isSubscriptionSuccessful, setIsSubscriptionSuccessful] = React.useState(false)

  const onClose = () => navigate(-1)

  const subscribe = useMutation({
    mutationFn: ({ addon, representativeId }: NewAddonSubscription) =>
      oleenApi.addonSubscriptions().create({ representativeId, addon }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['addons'] })
      setIsSubscriptionSuccessful(true)
    },
    onError: error => {
      if (error instanceof AddonSubscriptionApiError) {
        error.details?.forEach(detail => {
          toast.error(t(`addon_subscriptions.errors.${detail.type}`))
        })
      }
    },
  })

  if (!representative) {
    return <></>
  }

  if (addonName === AddonName.YUCCANLEAD) {
    return (
      <YuccanleadSubscriptionModal
        onClose={onClose}
        isSubscriptionSuccessful={isSubscriptionSuccessful}
        isSubscriptionPending={subscribe.isPending}
        subscribe={() => subscribe.mutate({ addon: addonName, representativeId: representative.id })}
      />
    )
  }

  return <> </>
}
