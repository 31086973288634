import { Buffer } from 'buffer'
import { useSearchParams } from 'react-router-dom'
import { Auth0 } from 'utils/auth0'

const IMPERSONATE_QUERY_PARAM = 'impersonate'

export const LOGOUT_APPSTATE_QUERY_PARAM = 'appState'
export const LOGOUT_USER_QUERY_PARAM = 'user'

export const useImpersonate = (isSessionActive: boolean): Promise<void> | void | string | null => {
  const [searchParams] = useSearchParams()

  const impersonate = searchParams.get(IMPERSONATE_QUERY_PARAM)
  const impersonationAsked = impersonate !== null

  if (isSessionActive && impersonationAsked) {
    return Auth0.getUser().then(u => {
      const appState = Buffer.from(window.location.origin + window.location.pathname).toString('base64')

      // returning to window.location.origin + pathname would be much easier
      // but Auth0 only allows logout url wildcards on subdomains (not
      // routes). Thus, we have to bypass that limitation with the
      // `appState` query param
      const returnTo = new URL(window.location.origin)
      returnTo.searchParams.append(IMPERSONATE_QUERY_PARAM, impersonate)
      returnTo.searchParams.append(LOGOUT_USER_QUERY_PARAM, u?.email ?? '')
      returnTo.searchParams.append(LOGOUT_APPSTATE_QUERY_PARAM, appState)

      return Auth0.logout({ logoutParams: { returnTo: returnTo.toString() } })
    })
  }

  return impersonate
}
