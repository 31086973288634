import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'

import { Client } from '../client'
import { recursiveToCamel, urlSearchParamsFromObject } from '../misc'
import { Paginated } from '../types'
import { parseDate, parseNullableDate } from 'helpers/date'

dayjs.extend(duration)

/**
 * Augments the 'Client' class with a method for accessing notifications services.
 */
declare module '../client' {
  interface Client {
    /**
     * Gets an instance of the NotificationsService.
     * @returns NotificationsService instance.
     */
    notifications: () => NotificationsService
  }
}

/**
 * Extension method to get NotificationsService.
 */
Client.prototype.notifications = function () {
  return new NotificationsService(this)
}

export interface Notification {
  id: string
  createdAt: Date
  updatedAt: Date
  seenAt: Date | null
  interactedAt: Date | null
  type: string
  data: any
}

type NotificationResponse = {
  id: string
  created_at: string
  updated_at: string
  seen_at: string | null
  interacted_at: string | null
  type: string
  data: any
}

/**
 * Service for managing notifications.
 */
interface INotificationsService {
  list: (page: number, pageSize: number) => Promise<Paginated<Notification>>
  markAsSeen: () => Promise<void>
  markAsInteracted: (id: string) => Promise<void>
}

type NotificationsFilters = {
  unseen?: boolean
}

/**
 * Service for managing notifications.
 */
class NotificationsService implements INotificationsService {
  constructor(private client: Client) {}

  /**
   * Gets a notification by id.
   * @param page - The page number.
   * @param pageSize - The number of items per page.
   */
  list(page = 1, pageSize = 10, filters: NotificationsFilters = {}): Promise<Paginated<Notification>> {
    return this.client.getPaginatedData<NotificationResponse, Notification>(
      `/notifications?`.concat(urlSearchParamsFromObject(filters).toString()),
      page,
      pageSize,
      parseNotification
    )
  }

  /**
   * Marks a notification as seen.
   * @param id - The notification id.
   */
  markAsSeen(): Promise<void> {
    return this.client.request({
      method: 'POST',
      url: `/notifications_views`,
    })
  }

  /**
   * Marks a notification as interacted.
   * @param id - The notification id.
   */
  markAsInteracted(id: string): Promise<void> {
    return this.client.request({
      method: 'POST',
      url: `/notifications/${id}/interacted`,
    })
  }
}

const parseNotification = (data: NotificationResponse): Notification => {
  return {
    id: data.id,
    type: data.type,
    createdAt: parseDate(data.created_at),
    updatedAt: parseDate(data.updated_at),
    seenAt: parseNullableDate(data.seen_at),
    interactedAt: parseNullableDate(data.interacted_at),
    data: recursiveToCamel(data.data),
  }
}
