import React from 'react'

export enum BannerVariants {
  GREEN,
  GRAY,
  ORANGE,
}

interface BannerProps {
  variant: BannerVariants
  children: React.ReactNode
  className?: string
}

export const Banner = ({ variant, children, className }: BannerProps) => {
  const baseStyles = 'flex gap-x-4 py-2 px-4 items-center rounded-lg text-sm font-medium'

  let variantStyles = ''

  switch (variant) {
    case BannerVariants.GREEN:
      variantStyles = 'bg-green-600 text-green-900'
      break
    case BannerVariants.GRAY:
      variantStyles = 'bg-gray-100 text-gray-900'
      break
    case BannerVariants.ORANGE:
      variantStyles = 'bg-amber-100 text-amber-700'
      break
    default:
      break
  }

  return (
    <div className={`${baseStyles} ${variantStyles} ${className}`} role="alert">
      {children}
    </div>
  )
}
