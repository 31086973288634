import { determineStackedBarItemPercentageShare } from 'helpers/opportunity'
import { id } from 'helpers/ref'
import React from 'react'

import { StackedBarItem } from '../tooltipSumCategory.styled'
import { FeesColors, TooltipStackedBarProps } from '../tooltipSumCategory.types'

export const TooltipSumCategoryStackedBar = ({ items }: TooltipStackedBarProps) => {
  const stackedBar = items.filter(item => item.amount !== 0)

  if (stackedBar.length) {
    return (
      <div className="flex">
        {stackedBar.map(item => (
          <StackedBarItem
            key={id()}
            isFirst={item === items[0]}
            isLast={item === items[items.length - 1]}
            color={FeesColors[item.label]}
            share={determineStackedBarItemPercentageShare(stackedBar, item.amount)}
          />
        ))}
      </div>
    )
  }

  return <StackedBarItem color={FeesColors.default} share={100} isFirst isLast />
}
