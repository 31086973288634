import React from 'react'

export const Logo: React.FC = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16 32C24.8365 32 32 24.8366 32 16C32 7.16344 24.8365 0 16 0C7.80864 0 1.05501 6.15554 0.112427 14.0933L9.54962 14.0933L5.05555 9.59919C4.9908 9.53444 4.9908 9.42946 5.05555 9.36471L7.5176 6.90265C7.58235 6.8379 7.68733 6.8379 7.75208 6.90266L14.2591 13.4097V6.63209C14.2591 6.54052 14.3333 6.46629 14.4249 6.46629H24.8705C24.9621 6.46629 25.0363 6.54052 25.0363 6.63209V10.114C25.0363 10.2055 24.9621 10.2798 24.8705 10.2798H18.0726V14.0933H24.0412C24.1327 14.0933 24.207 14.1675 24.207 14.2591V17.7409C24.207 17.8325 24.1327 17.9067 24.0412 17.9067H18.0726V25.3678C18.0726 25.4594 17.9984 25.5336 17.9068 25.5336H14.4249C14.3333 25.5336 14.2591 25.4594 14.2591 25.3678V18.643L7.71933 25.1828C7.65458 25.2476 7.54959 25.2476 7.48484 25.1828L5.02279 22.7208C4.95804 22.656 4.95804 22.551 5.02279 22.4863L9.60235 17.9067L0.112427 17.9067C1.055 25.8445 7.80864 32 16 32Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.23296 13.2642C1.53085 5.73103 8.09614 0 16 0C24.8365 0 32 7.16344 32 16C32 24.8366 24.8365 32 16 32C8.09614 32 1.53085 26.269 0.23296 18.7358C0.18575 18.4617 0.145509 18.1853 0.112427 17.9067L9.60235 17.9067L5.02279 22.4863C4.95804 22.551 4.95804 22.656 5.02279 22.7208L7.48485 25.1828C7.5496 25.2476 7.65458 25.2476 7.71933 25.1828L14.2591 18.643V25.3678C14.2591 25.4594 14.3333 25.5336 14.4249 25.5336H17.9068C17.9984 25.5336 18.0726 25.4594 18.0726 25.3678V17.9067H24.0412C24.1327 17.9067 24.207 17.8325 24.207 17.7409V14.2591C24.207 14.1675 24.1327 14.0933 24.0412 14.0933H18.0726V10.2798H24.8705C24.9621 10.2798 25.0363 10.2055 25.0363 10.114V6.63209C25.0363 6.54052 24.9621 6.46629 24.8705 6.46629H14.4249C14.3333 6.46629 14.2591 6.54052 14.2591 6.63209V13.4097L7.75208 6.90266C7.68733 6.8379 7.58235 6.8379 7.5176 6.90265L5.05555 9.36471C4.9908 9.42946 4.9908 9.53444 5.05555 9.59919L9.54962 14.0933L0.112427 14.0933C0.145509 13.8147 0.18575 13.5383 0.23296 13.2642Z"
        fill="white"
      />
    </svg>
  )
}
