import { CalendarIcon } from '@heroicons/react/20/solid'
import { CLICKS, track } from 'helpers/tracking'
import { Task } from 'lib/oleenApi'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'

export const ActionsMenu: React.FC<{ task: Task }> = ({ task }) => {
  const navigate = useNavigate()

  return (
    <button
      className="w-full h-full flex justify-center items-center"
      onClick={() => {
        track(CLICKS.taskExport)
        navigate(`./${task.id}/${ROUTES.TASK_ADD_TO_CALENDAR}`)
      }}
    >
      <CalendarIcon className="w-5 h-5 text-gray-900" />
    </button>
  )
}
