import { Person } from 'components/notifications/person'
import { useNotificationsContext } from 'contexts/notifications'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'

import { NewIcon, NotificationItem } from 'ui/notifications/notificationItem'
import { CLICKS, track } from 'helpers/tracking'

type IWebsiteOpportunityCreatedNotificationProps = {
  id: string
  opportunityId: string
  mortgagor: {
    firstName: string | null
    lastName: string | null
    email: string | null
  }
  timing: string
  interacted: boolean
}

export const WebsiteOpportunityCreatedNotification: React.FC<IWebsiteOpportunityCreatedNotificationProps> = ({
  id,
  opportunityId,
  mortgagor,
  timing,
  interacted,
}) => {
  const navigate = useNavigate()
  const { toggleMenu, markAsInteracted } = useNotificationsContext()

  const onClick = () => {
    track(CLICKS.notification, { type: 'website-opportunity-created-notification' })
    toggleMenu()
    markAsInteracted(id)
    navigate(`${ROUTES.OPPORTUNITIES}/${opportunityId}`)
  }

  return (
    <NotificationItem
      onClick={onClick}
      Icon={<NewIcon />}
      interacted={interacted}
      subtitle={
        <>
          {t('notification.opportunity_created.subtitle')}
          <Person person={mortgagor} />.
        </>
      }
      timing={timing}
    />
  )
}
