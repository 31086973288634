import React from 'react'

import { BannerVariants, Banner as StyledBanner } from './banner.styled'

interface IBanner {
  variant: BannerVariants
  className?: string
  Icon?: HeroIcon
}

const iconVariantStyles = {
  [BannerVariants.GREEN]: 'text-green-900',
  [BannerVariants.GRAY]: 'text-gray-900',
  [BannerVariants.ORANGE]: 'text-orange-700',
}

const Banner: React.FC<IBanner> = ({ Icon, variant, children, className }) => {
  return (
    <StyledBanner className={className} variant={variant}>
      {Icon && <Icon className={`flex-none w-5 h-5 ${iconVariantStyles[variant]}`} />}

      {children}
    </StyledBanner>
  )
}

export default Banner
