import { useCallback, useState } from 'react'

// asyncFunction should use useCallback to memoize the function and avoid unnecessary re-renders.
export function useAsync<T = unknown, E = unknown>(asyncFunction: () => Promise<T>) {
  const [data, setData] = useState<T | null>(null)
  const [error, setError] = useState<E | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const unsafeExecute = useCallback(async () => {
    setError(null)
    setIsLoading(true)
    try {
      const response = await asyncFunction()
      setData(response)
    } catch (error) {
      setError(error as E)
    } finally {
      setIsLoading(false)
    }
  }, [asyncFunction])

  const run = useCallback(async () => {
    if (isLoading) return
    if (data) return
    await unsafeExecute()
  }, [isLoading, data, unsafeExecute])

  const retry = useCallback(async () => {
    setData(null)
    await run()
  }, [run])

  return { data, error, isLoading, unsafeExecute, run, retry }
}
