import { EMPTY_SIGN, formatDateWithTime } from 'helpers/format'
import { t } from 'utils/i18n'

export const formatTiming = (date?: Date | null) => {
  if (!date) return EMPTY_SIGN

  const minute = 60 * 1000
  const oneMinuteAgo = new Date(Date.now() - minute)

  if (date > oneMinuteAgo) {
    return t('notification.just_now')
  }
  return formatDateWithTime(date)
}
