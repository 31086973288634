import React, { forwardRef } from 'react'

import { SelectBadgeInput } from 'ui/dropdowns/selectBadgeInput'
import { InnerClassNames, SelectSection } from 'ui/dropdowns/selectInput'
import { FieldState } from 'ui/forms/form.hook'

export type { SelectOption, InnerClassNames } from 'ui/dropdowns/selectInput'

export type FormSelectBadgeInputCustomProps = InnerClassNames

export type FormSelectBadgeInputProps = {
  className?: string
  disabled?: boolean
  options: SelectSection[]
} & FormSelectBadgeInputCustomProps &
  FieldState

export const FormSelectBadgeInput = forwardRef<HTMLInputElement, FormSelectBadgeInputProps>(
  ({ className, ...props }, ref) => {
    return (
      <SelectBadgeInput
        className={className}
        ref={ref}
        {...props}
        value={props.value as string}
        onChange={props.handleFieldChange}
        onBlur={props.handleFieldBlur}
      />
    )
  }
)
FormSelectBadgeInput.displayName = 'FormSelectBadgeInput'
