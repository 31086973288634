import { useSessionContext } from 'contexts/session'
import { OleenApi } from 'lib/oleenApi'
import React, { createContext, ReactNode, useContext, useMemo } from 'react'

interface OleenApiContextType {
  oleenApi: OleenApi
}

const OleenApiContext = createContext<OleenApiContextType>({ oleenApi: new OleenApi() })

interface OleenApiProviderProps {
  children: ReactNode
}

export const OleenApiProvider: React.FC<OleenApiProviderProps> = ({ children }) => {
  const { session } = useSessionContext()

  const oleenApi = useMemo(() => {
    const client = new OleenApi()
    client.setAuthToken(session?.token ?? '')
    return client
  }, [session])

  return <OleenApiContext.Provider value={{ oleenApi }}>{children}</OleenApiContext.Provider>
}

export const useOleenApi = (): OleenApiContextType => {
  const context = useContext(OleenApiContext)
  if (context === undefined) {
    throw new Error('useOleenApi must be used within a OleenApiProvider')
  }
  return context
}
