import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { forwardRef, PropsWithChildren } from 'react'

import { Dropdown, DropdownOptionValue } from 'ui/dropdowns/dropdown'
import { useSelect } from 'ui/dropdowns/selectInput/helpers'

import { SelectSection } from './selectInput/types'
import { Tracked } from 'helpers/tracking'

export * from './selectInput/types'

// FIXME: rename this type to something more explicit to tell this are props
// to be passthrought from the parent component.
export type InnerClassNames = {
  buttonClassName?: string
  dropdownClassName?: string
  isSearchable?: boolean
  searchPlaceholder?: string
}

export type SelectInputProps = PropsWithChildren<
  {
    id?: string
    className?: string
    value: DropdownOptionValue
    options: SelectSection[]
    onChange: (value: DropdownOptionValue, data?: unknown) => void
    onBlur?: () => void
    disabled?: boolean
  } & InnerClassNames &
    Partial<Tracked>
>

export const SelectInput = forwardRef<HTMLDivElement, SelectInputProps>(
  (
    {
      id,
      isSearchable,
      className,
      buttonClassName,
      dropdownClassName,
      searchPlaceholder,
      value,
      options,
      onChange,
      onBlur,
      disabled,
      tracking,
    },
    ref
  ) => {
    const { isOpen, buttonRef, onButtonClick, dropdownRef, allOptions, currentOption } = useSelect({
      options,
      onChange,
      onBlur,
      isSearchable,
      searchPlaceholder,
      value,
      tracking,
    })

    return (
      <div
        ref={ref}
        className={classNames(className, 'selectInput', {
          'selectInput--medium': !className?.includes('selectInput--small'),
          'selectInput--disabled': disabled,
        })}
      >
        <button type="button" ref={buttonRef} onClick={onButtonClick} className={buttonClassName} disabled={disabled}>
          <span>{currentOption && 'label' in currentOption && currentOption.label}</span>
          <ChevronDownIcon className={classNames({ 'rotate-180': isOpen })} />
        </button>
        <Dropdown className={dropdownClassName} ref={dropdownRef} isVisible={isOpen} options={allOptions} />
      </div>
    )
  }
)
SelectInput.displayName = 'SelectInput'
