import { Tracked } from 'helpers/tracking'
import React from 'react'

import { BUTTON_CIRCLE_VARIANTS, ButtonCircle, ButtonCircleProps } from 'ui/buttons/buttonCircle'

type BadgeProps = {
  n: number
  onClick?: () => void
}

const Badge: React.FC<BadgeProps> = ({ n, onClick }) => (
  <div
    onClick={onClick}
    className="w-6 h-6 flex items-center justify-center py-0.5 px-2.5 absolute left-1 -top-[25px] rounded-[80px] bg-red-600"
  >
    <p className="text-white text-center text-xs leading-[18px] font-medium antialiased">{n}</p>
  </div>
)

type ButtonProps = {
  badgeNumber?: number
  showBadge: boolean
  variant: string
  Icon: HeroIcon
  onClick: () => void
} & ButtonCircleProps &
  Partial<Tracked>

export const BADGED_BUTTON_CIRCLE_VARIANTS = BUTTON_CIRCLE_VARIANTS

export const BadgedButtonCircle: React.FC<ButtonProps> = ({
  onClick,
  badgeNumber,
  showBadge,
  variant,
  Icon,
  tracking,
}) => (
  <ButtonCircle variant={variant} Icon={Icon} onClick={onClick} tracking={tracking}>
    <div className="relative">{showBadge && badgeNumber && <Badge n={badgeNumber} onClick={onClick} />}</div>
  </ButtonCircle>
)
