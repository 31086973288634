import React from 'react'

export interface SaveBarScreenType {
  class: string
  css?: string
}

interface SaveBarProps {
  screen?: SaveBarScreenType
  shown?: boolean
  children?: React.ReactNode
}

export const SAVEBAR_SCREEN = {
  opportunity: { class: 'fixed right-0 bottom-0 w-full' },
  account: { class: 'fixed right-[1rem] bottom-0', css: 'width: calc(100% - 18rem);' },
}

export const SaveBar = ({ screen, shown, children }: SaveBarProps) => {
  const className = `bg-white flex justify-end z-[100] ${
    screen ? screen.class : 'bottom-0 fixed w-full right-0 left-0'
  } ${shown ? 'h-[65px] border-t border-gray-300' : 'h-0 overflow-hidden'}`

  // const additionalStyles = shown
  //   ? ''
  //   : `* { display: none }`

  return (
    <div
      className={className}
      style={{ transition: 'height 0.2s linear', ...(screen?.css && { cssText: screen.css }) }}
    >
      {React.Children.map(children, (child, index) => (
        <div key={index} className="mt-auto mb-auto mr-5 text-sm leading-5">
          {child}
        </div>
      ))}
    </div>
  )
}
