import { DocumentTextIcon, XCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

export type FileListProps = {
  className?: string
  files: File[]
  handleDelete: (index: number) => void
  onBlur?: () => void
}

export const FileList = forwardRef<HTMLInputElement, FileListProps>(({ className, files, handleDelete }, ref) => {
  const openFile = (file: File) => {
    const url = window.URL.createObjectURL(file)
    window.open(url, '_blank')
  }

  return (
    <div ref={ref} className={classNames('fileList', className)}>
      <ul>
        {files.map((file, index) => (
          <li key={index}>
            <DocumentTextIcon />
            <button onClick={() => openFile(file)}>{file.name}</button>
            <button onClick={() => handleDelete(index)}>
              <XCircleIcon />
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
})

FileList.displayName = 'FileList'
