import React from 'react'

import { CardDataRowProps } from './card.types'

export const CardDataRow: React.FC<CardDataRowProps> = ({ label, children, data }) => (
  <div className="flex flex-row grow justify-between leading-5">
    <div className="flex justify-between gap-2">
      {children}
      <div className="text-gray-800 font-normal">{label}</div>
    </div>
    <span className="font-semibold truncate">{data}</span>
  </div>
)
