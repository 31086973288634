import React, { PropsWithChildren } from 'react'

type CardProps = PropsWithChildren<{ title: string }>

export const Card: React.FC<CardProps> = ({ children, title }) => {
  return (
    <div className="card">
      <header>
        <h2>{title}</h2>
      </header>
      {children}
    </div>
  )
}
