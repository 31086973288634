import {
  LeadContributorForm,
  LeadContributorFormData,
  LeadContributorFormError,
} from 'components/leadContributors/leadContributorForm'
import { useLeadContributors } from 'contexts/leadContributors'
import { useOleenApi } from 'contexts/oleenApi'
import { toast } from 'helpers/toast'
import { useAsync } from 'hooks/async.hook'
import { LeadContributor, LeadContributorApiError } from 'lib/oleenApi'
import React, { useCallback, useEffect, useState } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { FormProvider, useFormContext } from 'ui/forms/form'
import { Modal, MODAL_SIZES } from 'ui/legacy/atoms/modals/modal'
import { useRepresentativeContext } from 'contexts/representative'
import { CLICKS } from 'helpers/tracking'

type CreateLeadContributorModalContentProps = {
  closeModal: () => void
}

const CreateLeadContributorModalContent: React.FC<CreateLeadContributorModalContentProps> = ({ closeModal }) => {
  const { getFormData, isFormValid } = useFormContext()
  const { oleenApi } = useOleenApi()
  const { upsertLeadContributor } = useLeadContributors()
  const [formError, setFormError] = useState<LeadContributorFormError>({} as LeadContributorFormError)
  const { representative } = useRepresentativeContext()

  const createLeadContributor = useCallback(() => {
    // TODO : generalize `getFormData` to any type to avoid use of `as`
    const leadContributor = getFormData() as LeadContributorFormData
    return oleenApi.leadContributors().create({ ...leadContributor, representativeId: representative?.id ?? '' })
  }, [oleenApi, getFormData, representative])

  const { data, error, run } = useAsync<LeadContributor>(createLeadContributor)

  useEffect(() => {
    if (!data) return

    toast.success(t('lead_contributors.toast.creation.success'))
    upsertLeadContributor(data)
    closeModal()
  }, [data, closeModal, upsertLeadContributor])

  useEffect(() => {
    if (!error) return

    toast.error(t('lead_contributors.toast.creation.error'))

    // TODO: refactor form error handling to avoid code duplication in components
    // Duplicated in editLeadContributorModal.tsx, createBusinessPartnerModal.tsx, editBusinessPartnerModal.tsx, createTaskModal.tsx
    if (error instanceof LeadContributorApiError) {
      setFormError({})
      error.details.forEach(detail => {
        setFormError(prev => ({ ...prev, [detail.attribute]: detail.type }))
      })
    }
  }, [error])

  return (
    <LeadContributorForm error={formError}>
      <Button
        className="button--solid button--primary button--medium"
        disabled={!isFormValid}
        onClick={run}
        tracking={[CLICKS.leadContributorSave]}
      >
        <span>{t('lead_contributors.modal.create.submit')}</span>
      </Button>
      <Button className="button--solid button--secondary button--medium" onClick={closeModal}>
        <span>{t('common.cancel')}</span>
      </Button>
    </LeadContributorForm>
  )
}

interface CreateLeadContributorModalProps {
  isOpen: boolean
  closeModal: () => void
  onLeadContributorCreated?: (leadContributor: LeadContributor) => void
}

export const CreateLeadContributorModal: React.FC<CreateLeadContributorModalProps> = ({ isOpen, closeModal }) => {
  if (!isOpen) return <></>
  return (
    <FormProvider>
      <Modal
        className="overflow-y-visible"
        size={MODAL_SIZES.MD}
        isOpen={isOpen}
        closeModal={closeModal}
        header={
          <div>
            <p className="text-gray-900 text-base font-bold font-inter leading-6">
              {t('lead_contributors.modal.create.title')}
            </p>
          </div>
        }
      >
        <CreateLeadContributorModalContent closeModal={closeModal} />
      </Modal>
    </FormProvider>
  )
}
