import { id as getId } from 'helpers/ref'
import React, { forwardRef, PropsWithChildren } from 'react'
import { track, Tracked } from 'helpers/tracking'

export type BigCheckProps = PropsWithChildren<{
  name?: string
  value: boolean
  defaultChecked?: boolean
  onChange: (value: boolean) => void
  onBlur?: () => void
  disabled?: boolean
}> &
  Partial<Tracked>

const AnimatedSVGCheck: React.FC<{ onAnimationEnd?: () => void }> = ({ onAnimationEnd }) => {
  return (
    <svg
      className="icon"
      onAnimationEnd={onAnimationEnd}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M6.06641 12.1106C6.30078 12.345 6.69922 12.345 6.93359 12.1106L13.8242 5.21997C14.0586 4.9856 14.0586 4.58716 13.8242 4.35278L12.9805 3.50903C12.7461 3.27466 12.3711 3.27466 12.1367 3.50903L6.51172 9.13403L3.86328 6.50903C3.62891 6.27466 3.25391 6.27466 3.01953 6.50903L2.17578 7.35278C1.94141 7.58716 1.94141 7.9856 2.17578 8.21997L6.06641 12.1106Z"
          fill="white"
        />
      </g>
    </svg>
  )
}

const StaticSvgCheck: React.FC = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" fill="#0E9F6E" stroke="#0E9F6E" />
      <path
        d="M10.0664 16.1107C10.3008 16.3451 10.6992 16.3451 10.9336 16.1107L17.8242 9.22005C18.0586 8.98568 18.0586 8.58724 17.8242 8.35286L16.9805 7.50911C16.7461 7.27474 16.3711 7.27474 16.1367 7.50911L10.5117 13.1341L7.86328 10.5091C7.62891 10.2747 7.25391 10.2747 7.01953 10.5091L6.17578 11.3529C5.94141 11.5872 5.94141 11.9857 6.17578 12.2201L10.0664 16.1107Z"
        fill="white"
      />
    </svg>
  )
}
// TODO: refact without peer utility (maybe with data-attribute) to avoid style
// in label className
export const BigCheck = forwardRef<HTMLInputElement, BigCheckProps>((props, ref) => {
  const inputId = getId()

  const [animate, setAnimate] = React.useState(false)

  const trackedOnChange = () => {
    setAnimate(true)
    if (props.tracking) track(...props.tracking)
    props.onChange(!props.value)
  }

  return (
    <div className="bigCheck">
      <input
        id={inputId}
        ref={ref}
        type="checkbox"
        name={props.name}
        className="sr-only peer"
        value={props.value.toString()}
        checked={props.value}
        onBlur={props.onBlur}
        onChange={trackedOnChange}
      />
      <label htmlFor={inputId}>
        {animate && (
          <>
            <span className="scale-fill-anim"></span>
            <span className="scale-overflow-anim"></span>
            <AnimatedSVGCheck onAnimationEnd={() => setAnimate(false)} />
          </>
        )}
        {!animate && props.value && (
          <>
            <StaticSvgCheck />
          </>
        )}
      </label>
    </div>
  )
})
BigCheck.displayName = 'BigCheck'
