import { useForm } from '@tanstack/react-form'
import { useMutation, useQuery } from '@tanstack/react-query'
import { I18NPath, t } from 'utils/i18n'
import { NewTask, Task, TaskApiError, taskDurations, TaskErrorAttribute, TaskErrorType } from 'lib/oleenApi'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { TextInput } from 'ui/inputs/textInput'
import { TanstackFormInput } from 'ui/misc/tanstackFormInput'
import { textAreaProps, textInputProps } from 'helpers/tanstackForm'
import { Button } from 'ui/buttons/button'
import { CLICKS } from 'helpers/tracking'
import { SelectInput, SelectOption } from 'ui/dropdowns/selectInput'
import { mortgageOpportunitiesQuery } from 'helpers/queries'
import { EMPTY_SIGN } from 'helpers/format'
import { RadioGroup } from 'ui/buttons/radioGroup'
import { TextArea } from 'ui/textareas/textArea'
import { DateInput } from 'ui/inputs/dateInput'
import dayjs from 'dayjs'
import { TimeInput } from 'ui/inputs/timeInput'
import duration from 'dayjs/plugin/duration'

dayjs.extend(duration)

enum TemplateTypes {
  send_funding_plan = 'send_funding_plan',
  attestation_reminder = 'attestation_reminder',
  prospection_reminder = 'prospection_reminder',
  missing_documents_reminder = 'missing_documents_reminder',
  mandate_reminder = 'mandate_reminder',
  make_quotation = 'make_quotation',
}

export type TaskFormProps = PropsWithChildren<{
  task?: Task
  defaultTaskableId?: string
  action: (task: NewTask) => Promise<Task>
  onSuccess: (task: Task) => void
  onError: (error: Error) => void
}>

export type TaskFormError = Partial<Record<TaskErrorAttribute, TaskErrorType>>

export const TaskForm: React.FC<TaskFormProps> = ({
  task,
  defaultTaskableId,
  action,
  onSuccess,
  onError,
  children,
}) => {
  const [formError, setFormError] = useState<TaskFormError>({} as TaskFormError)
  const createTask = useMutation({
    mutationFn: action,
    onSuccess: data => {
      onSuccess(data)
    },
    onError: error => {
      if (error instanceof TaskApiError) {
        setFormError({})
        error.details.forEach(detail => {
          setFormError(prev => ({ ...prev, [detail.attribute]: detail.type }))
        })
      }
      // NOTE: Should be possible to set backend errors to fields states here
      // setFieldMeta('title', prev => ({ ...prev, errors: ['true'] }))
      onError(error)
    },
  })
  const [isPredefined, setIsPredefined] = useState(!task?.title)
  const { data: opportunities } = useQuery(mortgageOpportunitiesQuery())
  const opportunitiesOptions: SelectOption[] = useMemo(() => {
    if (!opportunities || opportunities.length === 0) return [{ value: null, label: EMPTY_SIGN, type: 'text' }]

    return opportunities.map(opportunity => ({
      type: 'text',
      value: opportunity.id,
      label: t('tasks.mortgagor.fullname', {
        first_name: opportunity.mortgagorFirstName,
        last_name: opportunity.mortgagorLastName,
      }),
    }))
  }, [opportunities])
  const templateOptions: SelectOption[] = Object.keys(TemplateTypes).map(option => ({
    type: 'text',
    value: t(`tasks.form.templates.${option}` as I18NPath),
    label: t(`tasks.form.templates.${option}` as I18NPath),
  }))
  const durationOptions: SelectOption[] = taskDurations.map(option => ({
    type: 'text',
    value: option.value,
    label: option.label,
  }))
  const { Field, handleSubmit } = useForm({
    defaultValues: {
      title: isPredefined ? (templateOptions[0].value as string) : task?.title || '',
      description: task?.description || '',
      dueDate: task?.dueDate ? dayjs(task.dueDate).format('YYYY-MM-DD') : dayjs().add(1, 'day').format('YYYY-MM-DD'),
      dueTime: task?.dueDate ? dayjs(task.dueDate).format('HH:mm') : '09:00',
      duration: task?.duration ? task.duration : (durationOptions[1].value as string),
      taskableId: task?.taskableId || defaultTaskableId || '',
    },
    onSubmit: ({ value }) => {
      const payload = {
        title: value.title,
        description: value.description,
        dueDate: `${value.dueDate} ${value.dueTime}`,
        duration: value.duration,
        taskableId: value.taskableId,
      }

      createTask.mutate(payload)
    },
  })

  return (
    <form onSubmit={handleSubmit}>
      <Field name="taskableId">
        {field => (
          <TanstackFormInput
            className="w-full"
            label={t('tasks.form.opportunity')}
            field={field}
            apiError={formError.taskable_type}
          >
            <SelectInput
              value={field.state.value}
              options={[opportunitiesOptions]}
              onChange={value => field.handleChange(value as string)}
              onBlur={field.handleBlur}
              isSearchable={opportunitiesOptions?.length > 10}
              className="selectInput--small"
              dropdownClassName="w-full"
              disabled={!!defaultTaskableId}
            />
          </TanstackFormInput>
        )}
      </Field>
      <RadioGroup
        value={isPredefined.toString()}
        className="flex gap-6"
        name="isPredefined"
        onChange={value => setIsPredefined(value === 'true')}
        options={[
          { value: 'true', label: t('tasks.form.predefined_option') },
          { value: 'false', label: t('tasks.form.custom_option') },
        ]}
      />
      {isPredefined && (
        <Field name="title">
          {field => (
            <TanstackFormInput
              className="w-full"
              label={t('tasks.form.task_type')}
              field={field}
              apiError={formError.title}
            >
              <SelectInput
                value={field.state.value}
                options={[templateOptions]}
                onChange={value => field.handleChange(value || '')}
                onBlur={field.handleBlur}
                className="selectInput--small"
                dropdownClassName="w-full"
              />
            </TanstackFormInput>
          )}
        </Field>
      )}
      {!isPredefined && (
        <Field name="title">
          {field => (
            <TanstackFormInput
              className="w-full"
              label={t('tasks.form.title')}
              field={field}
              apiError={formError.title}
            >
              <TextInput placeholder={'Titre'} {...textInputProps(field)} />
            </TanstackFormInput>
          )}
        </Field>
      )}
      <Field name="description">
        {field => (
          <TanstackFormInput
            className="w-full"
            label={t('tasks.form.description')}
            field={field}
            apiError={formError.description}
          >
            <TextArea placeholder={'Détails (facultatif)'} {...textAreaProps(field, 'textArea--small')} />
          </TanstackFormInput>
        )}
      </Field>
      <Field name="dueDate">
        {field => (
          <TanstackFormInput
            className="w-full"
            label={t('tasks.form.due_date')}
            field={field}
            apiError={formError.due_date}
          >
            <DateInput {...textInputProps(field)} />
          </TanstackFormInput>
        )}
      </Field>
      <div className="flex flex-row gap-x-2">
        <Field name="dueTime">
          {field => (
            <TanstackFormInput className="w-full" label={t('tasks.form.due_time')} field={field}>
              <TimeInput {...textInputProps(field)} />
            </TanstackFormInput>
          )}
        </Field>
        <Field name="duration">
          {field => (
            <TanstackFormInput className="w-full" label={t('tasks.form.duration')} field={field}>
              <SelectInput
                value={field.state.value}
                options={[durationOptions]}
                onChange={value => field.handleChange(value as string)}
                onBlur={field.handleBlur}
                className="selectInput--small"
                dropdownClassName="w-full !max-h-40"
              />
            </TanstackFormInput>
          )}
        </Field>
      </div>
      <Button
        className="button--small button--primary button--solid w-full"
        onClick={handleSubmit}
        tracking={[CLICKS.taskSave]}
      >
        {task?.id ? t('tasks.modal.edit.submit') : t('tasks.modal.create.submit')}
      </Button>
    </form>
  )
}
