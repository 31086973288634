import React from 'react'
import { InformationCircleIcon } from '@heroicons/react/20/solid'
import { Opportunity } from 'lib/oleenApi'
import { MORTGAGE_DEAL_LOSS_REASON } from 'types/mortgageDealLoss'
import { EMPTY_SIGN } from 'helpers/format'

export const LostReasonInfo: React.FC<{ opportunity: Opportunity }> = ({ opportunity }) => (
  <>
    {opportunity.last_mortgage_deal_loss && (
      <div className="border border-gray-300 rounded p-2 bg-gray-100 flex gap-2 items-center">
        <InformationCircleIcon className="w-5 h-5 text-gray-500" />
        <p className="text-xs font-normal text-gray-500">
          {MORTGAGE_DEAL_LOSS_REASON[opportunity.last_mortgage_deal_loss.reason] ?? EMPTY_SIGN}
        </p>
      </div>
    )}
  </>
)
