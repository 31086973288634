import classNames from 'classnames'
import React from 'react'

import { Radio } from './radioGroup/radio'

export type RadioGroupProps = {
  className?: string
  options: { value: string; label: string }[]
  onChange?: (v: string) => void
  onFocus?: () => void
  onBlur?: () => void
  value: string
  name: string
}

export const RadioGroup: React.FC<RadioGroupProps> = ({ className, onChange, options, name, value }) => {
  return (
    <div className={classNames('radioGroup', className)}>
      {options.map((option, index) => (
        <Radio
          key={index}
          name={name}
          label={option.label}
          value={option.value}
          onChange={onChange}
          checked={value === option.value}
        />
      ))}
    </div>
  )
}
