import { formatAmount } from 'helpers/format'
import { id } from 'helpers/ref'
import React from 'react'
import { t } from 'utils/i18n'

import { FeeColorPill, TooltipDetailsWrapper, TooltipLabelWrapper } from '../tooltipSumCategory.styled'
import { FeesColors, TooltipDetailsProps } from '../tooltipSumCategory.types'

export const TooltipSumCategoryDetails = ({ items }: TooltipDetailsProps) => (
  <>
    {items.map(item => (
      <TooltipDetailsWrapper key={id()}>
        <TooltipLabelWrapper>
          <FeeColorPill color={FeesColors[item.label]} />
          <span className="text-gray-600 font-normal text-sm truncate">
            {t(`opportunity_kanban.amount_tooltip.${item.label}`)}
          </span>
        </TooltipLabelWrapper>
        <span className="text-1xl font-semibold text-gray-900 truncate">{formatAmount(item.amount)}</span>
      </TooltipDetailsWrapper>
    ))}
  </>
)
