import { LinkCard, LinkCardIconColor, LinkCardProps } from 'components/usefulLinks/atoms/linkCard/linkCard'
import { useRepresentativeContext } from 'contexts/representative'
import { id } from 'helpers/ref'
import React from 'react'
import { t } from 'utils/i18n'

import {
  AcademicCapIcon,
  BanknotesIcon,
  BuildingOfficeIcon,
  CubeTransparentIcon,
  DocumentDuplicateIcon,
  DocumentIcon,
  NewspaperIcon,
  PresentationChartLineIcon,
  UserGroupIcon,
} from '@heroicons/react/24/solid'

export const getLinkCardProps: (representativeEmail?: string) => Array<LinkCardProps> = (
  representativeEmail?: string
) => [
  {
    label: t('useful_links.academy.label'),
    description: t('useful_links.academy.description'),
    Icon: AcademicCapIcon,
    url: t('useful_links.academy.url'),
    badgeColor: LinkCardIconColor.GREEN,
  },
  {
    label: t('useful_links.bank.label'),
    description: t('useful_links.bank.description'),
    Icon: DocumentDuplicateIcon,
    url: t('useful_links.bank.url'),
    badgeColor: LinkCardIconColor.BLUE,
  },
  {
    label: t('useful_links.digital.label'),
    description: t('useful_links.digital.description'),
    Icon: CubeTransparentIcon,
    url: t('useful_links.digital.url'),
    badgeColor: LinkCardIconColor.PURPLE,
  },
  {
    label: t('useful_links.finalgo.label'),
    description: t('useful_links.finalgo.description'),
    Icon: BuildingOfficeIcon,
    url: t('useful_links.finalgo.url'),
    badgeColor: LinkCardIconColor.DARK_GREEN,
  },
  {
    label: t('useful_links.forecast.label'),
    description: t('useful_links.forecast.description'),
    Icon: PresentationChartLineIcon,
    url: t('useful_links.forecast.url'),
    badgeColor: LinkCardIconColor.GREY,
  },
  {
    label: t('useful_links.lead_contribution_form.label'),
    description: t('useful_links.lead_contribution_form.description'),
    Icon: DocumentIcon,
    url: t('useful_links.lead_contribution_form.url', { representativeSlug: representativeEmail?.split('@')[0] }),
    badgeColor: LinkCardIconColor.YELLOW,
  },
  {
    label: t('useful_links.qobra.label'),
    description: t('useful_links.qobra.description'),
    Icon: BanknotesIcon,
    url: t('useful_links.qobra.url', { representativeSlug: representativeEmail?.split('@')[0] }),
    badgeColor: LinkCardIconColor.BLUE,
  },
  {
    label: t('useful_links.yuccanlead.label'),
    description: t('useful_links.yuccanlead.description'),
    Icon: UserGroupIcon,
    url: t('useful_links.yuccanlead.url'),
    badgeColor: LinkCardIconColor.DARK_GREEN,
  },
  {
    label: t('useful_links.slack.label'),
    description: t('useful_links.slack.description'),
    Icon: NewspaperIcon,
    url: t('useful_links.slack.url'),
    badgeColor: LinkCardIconColor.GREY,
  },
]

export const LinksCardList: React.FC = () => {
  const { representative } = useRepresentativeContext()
  const linCardProps = getLinkCardProps(representative?.email)

  return (
    <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3">
      {linCardProps.map(props => (
        <LinkCard key={id()} {...props} />
      ))}
    </div>
  )
}
