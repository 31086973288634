import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { forwardRef, PropsWithChildren } from 'react'

import { Badge } from 'ui/badges/badge'
import { Dropdown } from 'ui/dropdowns/dropdown'
import { useSelect, UseSelectProps } from 'ui/dropdowns/selectInput/helpers'

export type SelectBadgeInputProps = PropsWithChildren<{
  className?: string
  buttonClassName?: string
  dropdownClassName?: string
}> &
  UseSelectProps

export const SelectBadgeInput = forwardRef<HTMLDivElement, SelectBadgeInputProps>(
  ({ className, buttonClassName, dropdownClassName, value, options, onChange, onBlur, tracking }, ref) => {
    const { isOpen, buttonRef, onButtonClick, dropdownRef, allOptions, currentOption } = useSelect({
      options,
      onChange,
      onBlur,
      value,
      tracking,
    })

    return (
      <div ref={ref} className={classNames('selectInput selectInputBadge', className)}>
        <button ref={buttonRef} className={classNames('selectInputButton', buttonClassName)} onClick={onButtonClick}>
          <Badge
            className={classNames(currentOption && 'badgeClassName' in currentOption && currentOption.badgeClassName)}
            RightIcon={ChevronDownIcon}
          >
            {currentOption && 'label' in currentOption && currentOption?.label}
          </Badge>
        </button>
        <Dropdown
          ref={dropdownRef}
          className={classNames('selectInputDropdown', dropdownClassName)}
          isVisible={isOpen}
          options={allOptions}
        />
      </div>
    )
  }
)
SelectBadgeInput.displayName = 'SelectBadgeInput'
