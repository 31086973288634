import { useCallback, useMemo, useState } from 'react'
import { useOleenApi } from 'contexts/oleenApi'
import { useQuery } from '@tanstack/react-query'
import MistralClient from '@mistralai/mistralai'

// Set the default settings for the chat stream
// The model is the model used by the chat stream
// The temperature is the randomness of the responses
const DEFAULT_SETTINGS = {
  model: 'mistral-medium',
  temperature: 0.2,
}

// Set the chat stream options
const chatStreamOptions = (content: string) => ({
  ...DEFAULT_SETTINGS,
  messages: [{ role: 'user', content }],
})

// Consume the chat stream response
// It will append the response to the streamText and set the response
const consumeResponse = async (
  chatStreamResponse: AsyncGenerator<any, void, unknown>,
  setResponse: (response: string) => void
) => {
  let streamText = ''
  for await (const chunk of chatStreamResponse) {
    if (chunk.choices[0].delta.content !== undefined) {
      streamText += chunk.choices[0].delta.content
      setResponse(streamText)
    }
  }
}

// Hook to use the Mistral chat stream
export const useMistral = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [response, setResponse] = useState<string | undefined>(undefined)
  const { oleenApi } = useOleenApi()

  const mistralApiKeyQuery = useQuery({
    queryKey: ['mistralApiKey'],
    queryFn: () => oleenApi.mistralApiKey().get(),
  })

  const mistralClient = useMemo(
    () => new MistralClient(mistralApiKeyQuery.data?.api_key || ''),
    [mistralApiKeyQuery.data]
  )

  const run = useCallback(
    async (content: string) => {
      setIsLoading(true)

      const chatStreamResponse = mistralClient.chatStream(chatStreamOptions(content))

      consumeResponse(chatStreamResponse, setResponse)

      setIsLoading(false)
    },
    [mistralClient]
  )

  return { isLoading, run, response }
}
