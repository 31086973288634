import { QueryClient } from '@tanstack/react-query'
import { NewOpportunity, OleenApi, Opportunity, Task, TaskFilters } from 'lib/oleenApi'

export const mortgageOpportunityQuery = (id: string) => ({
  // TODO: centralize query key management to facilitate invalidation
  queryKey: ['mortgageOpportunity', id],
  queryFn: async () => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') ?? '')
    return client.mortgageOpportunities().get(id)
  },
})

export const mortgageOpportunitiesQuery = () => ({
  // TODO: centralize query key management to facilitate invalidation
  queryKey: ['mortgageOpportunities'],
  queryFn: async () => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') ?? '')
    return client.mortgageOpportunities().listAll()
  },
})

export const getMortgageOpportunityQueryData = (queryClient: QueryClient, opportunityId: string) => {
  const opportunities: Opportunity[] = queryClient.getQueryData(['mortgageOpportunities']) || []
  return opportunities.find((opp: Opportunity) => opp.id === opportunityId)
}

export const updateMortgageOpportunityQueryData = (queryClient: QueryClient, opportunity: Opportunity) => {
  queryClient.setQueryData(['mortgageOpportunities'], (opportunities: Opportunity[]) => {
    return opportunities.map((opp: Opportunity) => (opp.id === opportunity?.id ? opportunity : opp))
  })
}

type NewOpportunityMutationProps = {
  queryClient: QueryClient
  onSuccess: (id: string) => void
}

export const getNewMortgageOpportunityMutation = ({ queryClient, onSuccess }: NewOpportunityMutationProps) => ({
  mutationFn: async (newOpportunity: NewOpportunity) => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') ?? '')
    return client.newMortgageOpportunity().create(newOpportunity)
  },
  onSuccess: (data: { id: string }) => {
    queryClient.invalidateQueries({ queryKey: ['mortgageOpportunities'] })
    onSuccess(data.id)
  },
})

export const tasksQuery = (filters: TaskFilters) => ({
  queryKey: ['tasks'],
  queryFn: async () => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') ?? '')
    return client.tasks().listAll(filters)
  },
})

export const tasksMetaQuery = () => ({
  queryKey: ['tasks', 'meta'],
  queryFn: async () => {
    const client = new OleenApi()
    client.setAuthToken(localStorage.getItem('token') ?? '')
    return client.tasks().list(1, 0)
  },
})

export const updateTaskCompletionQuery = ({ task, value }: { task: Task; value: boolean }) => {
  const client = new OleenApi()
  client.setAuthToken(localStorage.getItem('token') ?? '')
  const completedAt = value ? new Date().toString() : null
  task.completedAt = completedAt
  return client.tasks().complete(task.id, completedAt)
}

export const updateTaskQueryData = (queryClient: QueryClient, task: Task) => {
  const tasks: Task[] = queryClient.getQueryData(['tasks']) || []
  queryClient.setQueryData(
    ['tasks'],
    tasks.map((t: Task) => (t.id === task.id ? task : t))
  )
}

export const sendDERQuery = (opportunityId: string) => {
  const client = new OleenApi()
  client.setAuthToken(localStorage.getItem('token') ?? '')
  return client.opportunityDER().create(opportunityId)
}
