import { useRepresentativeContext } from 'contexts/representative'
import React from 'react'
import { t } from 'utils/i18n'
import { useQuery } from '@tanstack/react-query'
import { tasksMetaQuery } from 'helpers/queries'

export const TasksCounter: React.FC = () => {
  const { representative } = useRepresentativeContext()
  const todayTasksQuery = useQuery(tasksMetaQuery())
  const remainingTasksCounter = todayTasksQuery.data?.meta?.remaining_due_todays ?? 0

  return (
    <>
      <h2 className="text-gray-900 md:text-lg font-bold md:leading-9 md:mx-0 mx-6">
        {t('tasks.welcome', { firstName: representative?.firstName })}
      </h2>
      <p className="text-gray-900 hidden md:block text-base font-bold font-inter leading-9">
        {remainingTasksCounter > 0 && t('tasks.remaining_tasks', { count: remainingTasksCounter })}
        {remainingTasksCounter === 0 && t('tasks.no_remaining_tasks')}
      </p>
    </>
  )
}
