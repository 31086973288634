import classNames from 'classnames'
import { track, Tracked } from 'helpers/tracking'
import React from 'react'

export type TabProps = {
  children: React.ReactNode
  isActive: boolean
  onClick?: () => void
} & Partial<Tracked>

export const Tab = ({ children, isActive, onClick, tracking }: TabProps & Partial<Tracked>) => {
  const trackedOnClick = () => {
    if (tracking) track(...tracking)
    if (onClick) onClick()
  }

  return (
    <button onClick={trackedOnClick} className={classNames('tab shrink-0', { 'tab--active': isActive })}>
      {children}
    </button>
  )
}
