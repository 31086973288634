import { t } from 'utils/i18n'

import { DropdownOption } from 'ui/dropdowns/dropdown'

export enum MORTGAGE_OPPORTUNITY_LOSS_REASONS {
  INACTIVE = 'inactive',
  NON_FUNDABLE = 'non_fundable',
  BANK_COMPETITOR = 'bank_competitor',
  BROKER_COMPETITOR = 'broker_competitor',
  PROJECT_STOPPED = 'project_stopped',
  DUPLICATE = 'duplicate',
  REPRESENTATIVE_STOPPED = 'representative_stopped',
  NON_ATTRACTIVE_BUYBACK = 'non_attractive_buyback',
}

export const REASON_OPTIONS_MAP = {
  [MORTGAGE_OPPORTUNITY_LOSS_REASONS.INACTIVE]: t(
    `opportunity.drop_opportunity_modal.reasons.${MORTGAGE_OPPORTUNITY_LOSS_REASONS.INACTIVE}`
  ),
  [MORTGAGE_OPPORTUNITY_LOSS_REASONS.NON_FUNDABLE]: t(
    `opportunity.drop_opportunity_modal.reasons.${MORTGAGE_OPPORTUNITY_LOSS_REASONS.NON_FUNDABLE}`
  ),
  [MORTGAGE_OPPORTUNITY_LOSS_REASONS.BANK_COMPETITOR]: t(
    `opportunity.drop_opportunity_modal.reasons.${MORTGAGE_OPPORTUNITY_LOSS_REASONS.BANK_COMPETITOR}`
  ),
  [MORTGAGE_OPPORTUNITY_LOSS_REASONS.BROKER_COMPETITOR]: t(
    `opportunity.drop_opportunity_modal.reasons.${MORTGAGE_OPPORTUNITY_LOSS_REASONS.BROKER_COMPETITOR}`
  ),
  [MORTGAGE_OPPORTUNITY_LOSS_REASONS.PROJECT_STOPPED]: t(
    `opportunity.drop_opportunity_modal.reasons.${MORTGAGE_OPPORTUNITY_LOSS_REASONS.PROJECT_STOPPED}`
  ),
  [MORTGAGE_OPPORTUNITY_LOSS_REASONS.DUPLICATE]: t(
    `opportunity.drop_opportunity_modal.reasons.${MORTGAGE_OPPORTUNITY_LOSS_REASONS.DUPLICATE}`
  ),
  [MORTGAGE_OPPORTUNITY_LOSS_REASONS.REPRESENTATIVE_STOPPED]: t(
    `opportunity.drop_opportunity_modal.reasons.${MORTGAGE_OPPORTUNITY_LOSS_REASONS.REPRESENTATIVE_STOPPED}`
  ),
  [MORTGAGE_OPPORTUNITY_LOSS_REASONS.NON_ATTRACTIVE_BUYBACK]: t(
    `opportunity.drop_opportunity_modal.reasons.${MORTGAGE_OPPORTUNITY_LOSS_REASONS.NON_ATTRACTIVE_BUYBACK}`
  ),
}

export const DROP_MORTGAGE_OPPORTUNITY_FIELDS = {
  REASON: 'reason',
  COMMENT: 'comment',
  COMPETITOR_NAME: 'competitor_name',
  LOAN_DURATION: 'loan_duration',
  LOAN_RATE: 'loan_rate',
}

export const REASON_FIELD_OPTIONS: DropdownOption[] = Object.values(MORTGAGE_OPPORTUNITY_LOSS_REASONS).map(value => ({
  type: 'text',
  value,
  label: REASON_OPTIONS_MAP[value],
}))
