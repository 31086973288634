declare global {
  interface Window {
    StonlyWidget: (action: string, data: object) => void
  }
}

export const setAdditionalInfo = (additionalInfo: object) => {
  window.StonlyWidget('sendData', {
    contactForm: { additionalInfo },
  })
}

export const setUserEmail = (email: string) => {
  window.StonlyWidget('sendData', {
    contactForm: { email },
  })
}
