import { Client } from '../../client'

/**
 * Augments the 'Client' class with a method for accessing OpportunityToken services.
 */
declare module '../../client' {
  interface Client {
    /**
     * Gets an instance of the OpportunityTokenService.
     * @returns OpportunityTokenService instance.
     */
    opportunityToken: () => OpportunityTokenService
  }
}

/**
 * Extension method to get OpportunityTokenService.
 */
Client.prototype.opportunityToken = function () {
  return new OpportunityTokenService(this)
}

type Token = string

/**
 * Service for managing OpportunityToken.
 */
interface IOpportunityTokenService {
  get: (opportunityId: string) => Promise<Token>
}

class OpportunityTokenService implements IOpportunityTokenService {
  constructor(private client: Client) {}

  /**
   * Gets an opportunity token.
   * @param opportunityId - The id of the opportunity.
   * @returns A promise resolving to the opportunity token.
   */
  get(opportunityId: string) {
    return this.client.request<{ token: Token }, Token>(
      {
        url: `/opportunity/${opportunityId}/token`,
        method: 'GET',
      },
      response => response.data.token
    )
  }
}
