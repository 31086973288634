import classNames from 'classnames'
import { track, Tracked } from 'helpers/tracking'
import React, { forwardRef, PropsWithChildren } from 'react'

import { Spinner } from 'ui/spinner/spinner'

interface HTMLButtonProps {
  className: string
  href?: string
  target?: string
  onClick?: () => void
  disabled?: boolean
  isLoading?: boolean
  title?: string
  type?: 'button' | 'submit' | 'reset'
}

export type ButtonRef = HTMLButtonElement
export type ButtonProps = PropsWithChildren<HTMLButtonProps & Partial<Tracked>>

export const Button = forwardRef<ButtonRef, ButtonProps>(
  ({ className, tracking, onClick, type = 'button', disabled = false, isLoading, title, children }, ref) => {
    const trackedOnclick = () => {
      if (tracking) track(...tracking)
      if (onClick) onClick()
    }

    return (
      <button
        type={type}
        ref={ref}
        className={classNames('button', className)}
        disabled={disabled || isLoading}
        onClick={trackedOnclick}
        data-loading={isLoading}
        title={title}
      >
        {isLoading && <Spinner />}
        <span>{children}</span>
      </button>
    )
  }
)
Button.displayName = 'Button'
