import React from 'react'
import { t } from 'utils/i18n'

import { Tab } from 'ui/navigation/tab'
import { CLICKS } from 'helpers/tracking'
import { TaskFilters } from 'lib/oleenApi'
import { Badge } from 'ui/badges/badge'
import { tasksMetaQuery } from 'helpers/queries'
import { useQuery } from '@tanstack/react-query'

interface ITaskTabsProps {
  filters: TaskFilters
  setFilters: (filters: TaskFilters) => void
}

export const TaskTabs: React.FC<ITaskTabsProps> = ({ filters, setFilters }) => {
  const tasksQuery = useQuery(tasksMetaQuery())

  return (
    <div className="flex flex-row mb-3 justify-start">
      <Tab
        isActive={filters.due === 'today'}
        onClick={() => setFilters({ due: 'today' })}
        tracking={[CLICKS.tasksFilter, { dueFilter: 'today' }]}
      >
        {t('tasks.tabs.due_today')}
        <Badge className="badge--green ml-0.5">{tasksQuery.data?.meta?.remaining_due_todays ?? 0}</Badge>
      </Tab>
      <Tab
        isActive={filters.due === 'overdue'}
        onClick={() => setFilters({ due: 'overdue' })}
        tracking={[CLICKS.tasksFilter, { dueFilter: 'overdue' }]}
      >
        {t('tasks.tabs.overdue')}
        <Badge className="badge--red ml-0.5">{tasksQuery.data?.meta?.remaining_overdues ?? 0}</Badge>
      </Tab>
      <Tab
        isActive={filters.due === 'later'}
        onClick={() => setFilters({ due: 'later' })}
        tracking={[CLICKS.tasksFilter, { dueFilter: 'overdue' }]}
      >
        {t('tasks.tabs.due_later')}
        <Badge className="badge--blue ml-0.5">{tasksQuery.data?.meta?.remaining_due_laters ?? 0}</Badge>
      </Tab>
    </div>
  )
}
