import { Transition } from '@headlessui/react'
import React, { PropsWithChildren } from 'react'

type NotificationMenuListWrapperProps = {
  show: boolean
}

export const NotificationMenuListWrapper: React.FC<PropsWithChildren<NotificationMenuListWrapperProps>> = ({
  show,
  children,
}) => (
  <Transition
    show={show}
    enter="transition-all duration-300"
    enterFrom="opacity-0 lg:-translate-y-2"
    enterTo="opacity-100"
    leave="transition-all duration-300"
    leaveFrom="opacity-100"
    leaveTo="opacity-0 lg:-translate-y-2"
    unmount={true}
    className="fixed left-0 top-0 w-screen lg:absolute lg:w-[426px] lg:top-9 h-screen lg:-left-[338px] z-40 flex flex-col"
  >
    {children}
  </Transition>
)
