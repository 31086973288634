import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import { useEffect } from 'react'
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from 'react-router-dom'

Sentry.init({
  enabled: process.env.CONTEXT === 'production',
  dsn: 'https://ebb2ca4758b549ddabf7a8d899d90b3e@o1073095.ingest.sentry.io/6459259',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new Sentry.Replay({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  release: process.env.SENTRY_RELEASE,

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.1,

  // Replays settings
  replaysSessionSampleRate: 0,
  replaysOnErrorSampleRate: 1.0,
})

export default Sentry
