import React from 'react'
import { Card } from 'ui/sections/card'
import { errorHandlerMode } from 'helpers/errorHandler'
import { t } from 'utils/i18n'

import { FormInput } from 'ui/forms/formInput'
import { Representative } from 'lib/oleenApi'

type ParametersCardProps = {
  representative: Representative
}

export const ParametersCard: React.FC<ParametersCardProps> = ({ representative }) => {
  return (
    <Card title={t('account.default_parameters')}>
      <div className="card--content">
        <FormInput
          name={'value'}
          className="w-fit"
          defaultValue={representative?.defaultBrokerageFees?.toString()}
          label={t('account.brokerage_fees_default')}
          validations={[{ type: errorHandlerMode.NUMBER }]}
        />
      </div>
    </Card>
  )
}
