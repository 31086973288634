import { LeadContributor, Opportunity } from 'lib/oleenApi'
import { Address } from 'types/address'
import { t } from 'utils/i18n'

export const isNonEmptyString = (value: unknown): boolean => typeof value === 'string' && value.length > 0
export const isAddress = (value: unknown): boolean =>
  typeof value === 'object' && value !== null && 'street' in value && isNonEmptyString((value as Address).street)
export const isDefined = (data: unknown): boolean => data !== null && data !== undefined

export const EMPTY_SIGN = '-'
export const DUMMY_CALLBACK = () => null

export const formatText = (text: string | null | undefined): string => {
  if (!isDefined(text)) return EMPTY_SIGN
  // we can safely cast the string type here since we tested that text is not null or undefined
  return text as string
}

export const formatNumber = (amount: number | string | null | undefined, digits = 2): string => {
  if (!isDefined(amount)) return EMPTY_SIGN
  return Number(amount).toLocaleString('fr', { maximumFractionDigits: digits, minimumFractionDigits: digits })
}

export const formatAmount = (amount: number | string | null | undefined, digits = 2): string => {
  if (!isDefined(amount)) return EMPTY_SIGN
  return `${Number(amount).toLocaleString('fr', { maximumFractionDigits: digits, minimumFractionDigits: digits })} €`
}

export const formatPercentage = (amount: number | string | null | undefined, digits = 2): string => {
  if (!isDefined(amount) || Number.isNaN(amount)) return EMPTY_SIGN
  return `${Number(amount).toLocaleString('fr', { maximumFractionDigits: digits, minimumFractionDigits: digits })} %`
}

export const formatAddress = (address?: Address | null): string => {
  if (!address?.street && !address?.zipcode && !address?.city) return EMPTY_SIGN
  const { street, zipcode, city } = address
  return `${street}, ${zipcode} ${city}`
}

export const formatAdressLine1 = (address?: Address | null): string => {
  if (!address?.street) return EMPTY_SIGN
  return address.street
}

export const formatAdressLine2 = (address?: Address | null): string => {
  if (!address?.zipcode && !address?.city) return EMPTY_SIGN
  const { zipcode, city } = address
  return `${zipcode} ${city}`
}

export const formatDate = (iso8601?: string | null): string => {
  if (!iso8601) return EMPTY_SIGN

  const date = new Date(Date.parse(iso8601))
  return `${date.toLocaleDateString('fr')}`
}

export const formatDateWithTime = (date: Date): string => {
  if (!date) return EMPTY_SIGN

  return t('utils.datetime', {
    date: date.toLocaleDateString('fr'),
    time: date.toLocaleTimeString('fr'),
  })
}

export const formatISODate = (isoDate: string | undefined) => {
  if (!isoDate) return EMPTY_SIGN
  const date = new Date(isoDate)
  return date.toLocaleDateString('fr')
}

export const convertISODateToTimestamp = (isoDate: string | undefined) => {
  if (!isoDate) return 0
  const date = new Date(isoDate)
  return Math.trunc(date.getTime() / 1000)
}

export const normaliseString = (str: string): string =>
  // we remove diacritics (accents etc) and convert string to lowercase
  str
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase()

export const formatFullName = (
  firstName?: string | null,
  lastName?: string | null,
  fallback: string = EMPTY_SIGN
): string => {
  return !firstName && !lastName ? fallback : `${firstName} ${lastName}`
}

export const formatSource = (opportunity: Opportunity, leadContributor?: LeadContributor | null): string => {
  const { sourceType, sourceAmountPaid } = opportunity
  const source = sourceType ? t(`opportunity.source_type.${sourceType}`) : EMPTY_SIGN
  const email = leadContributor?.email || EMPTY_SIGN
  const amountPaid = sourceAmountPaid ? formatAmount(sourceAmountPaid) : EMPTY_SIGN
  return `${source} / ${email} / ${amountPaid}`
}
