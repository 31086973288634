import React from 'react'
import { FolderIcon } from '@heroicons/react/20/solid'
import ProgressBar from 'ui/progressBar/progressBar'
import { Opportunity } from 'lib/oleenApi'

export const CompletionInfo: React.FC<{ opportunity: Opportunity }> = ({ opportunity }) => (
  <div className="flex justify-center items-center gap-2">
    <FolderIcon className="w-5 h-5 text-gray-500" />
    <ProgressBar className="progressBar-inline" value={opportunity.docsCompletion} />
  </div>
)
