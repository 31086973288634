import { Client } from '../client'

declare module '../client' {
  interface Client {
    newMortgageOpportunity: () => NewMortgageOpportunitiesService
  }
}

export type NewOpportunity = {
  opportunity: {
    project: {
      projectKind: string
      propertyPrice: number
      mortgagors: {
        firstName: string
        lastName: string
        phone: string
        email: string
      }[]
    }
  }
}

type SerializedNewOpportunity = {
  opportunity: {
    project: {
      project_kind: string
      property_price: number
      mortgagors: {
        first_name: string
        last_name: string
        phone: string
        email: string
      }[]
    }
  }
}

Client.prototype.newMortgageOpportunity = function () {
  return new NewMortgageOpportunitiesService(this)
}

/**
 * Represents the service for managing mortgageOpportunities.
 */
interface INewMortgageOpportunitiesService {
  create: (opportunity: NewOpportunity) => Promise<{ id: string }>
}

class NewMortgageOpportunitiesService implements INewMortgageOpportunitiesService {
  constructor(private client: Client) {}

  /**
   * Creates a new mortgageOpportunity.
   */
  create = (opportunity: NewOpportunity): Promise<{ id: string }> => {
    return this.client.request<{ opportunity_id: string }, { id: string }>(
      {
        method: 'POST',
        url: '/mortgage/opportunities',
        data: serializeNewOpportunity(opportunity),
      },
      response => ({ id: response.data.opportunity_id })
    )
  }
}

const serializeNewOpportunity = (opportunity: NewOpportunity): SerializedNewOpportunity => {
  return {
    opportunity: {
      project: {
        project_kind: opportunity.opportunity.project.projectKind,
        property_price: opportunity.opportunity.project.propertyPrice,
        mortgagors: opportunity.opportunity.project.mortgagors.map(mortgagor => ({
          first_name: mortgagor.firstName,
          last_name: mortgagor.lastName,
          phone: mortgagor.phone,
          email: mortgagor.email,
        })),
      },
    },
  }
}
