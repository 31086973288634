import { EMPTY_SIGN } from 'helpers/format'
import React from 'react'

type PersonProps = {
  person: {
    firstName: string | null
    lastName: string | null
    email: string | null
  }
}

export const Person = ({ person }: PersonProps) => {
  if (person.firstName || person.lastName) {
    return (
      <>
        {person.firstName && <span className="font-semibold capitalize">{person.firstName}</span>}
        {person.firstName && person.lastName && ' '}
        {person.lastName && <span className="font-semibold uppercase">{person.lastName}</span>}
      </>
    )
  } else if (person.email) {
    return <span className="font-semibold">{person.email}</span>
  }
  return <span className="font-semibold">{EMPTY_SIGN}</span>
}
