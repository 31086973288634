import { SearchBar } from 'components/opportunities/atoms/searchBar/searchBar'
import { LeadContributorsSelect } from 'components/opportunities/leadContributorsSelect'
import { OpportunityKanban } from 'components/opportunities/molecules/kanban'
import OpportunityTable from 'components/opportunities/molecules/table'
import { ViewModeSelect } from 'components/opportunities/viewModeSelect'
import { OPPORTUNITY_VIEW_MODE } from 'helpers/opportunity'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'
import { Button } from 'ui/buttons/button'
import { DropdownOptionValue } from 'ui/dropdowns/dropdown/types'
import { PageLayout } from 'ui/layouts/pageLayout/pageLayout'
import { Loader } from 'ui/legacy/atoms/loader/loader'
import { useCableContext } from 'contexts/cable'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { mortgageOpportunitiesQuery } from 'helpers/queries'
import { CLICKS } from 'helpers/tracking'
import { PlusIcon } from '@heroicons/react/20/solid'

/*
 * Main component
 */
export default function OpportunitiesPage() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const openNewOpportunityForm = useCallback(() => {
    navigate(ROUTES.OPPORTUNITY_CREATE)
  }, [navigate])
  const [viewMode, setViewMode] = useState<OPPORTUNITY_VIEW_MODE>(OPPORTUNITY_VIEW_MODE.KANBAN)
  const queryClient = useQueryClient()

  const { data: opportunities, isPending } = useQuery(mortgageOpportunitiesQuery())

  const viewModeParam = searchParams.get('viewMode')

  const leadContributorIdParam = searchParams.get('leadContributorId')
  const [selectedLeadContributorId, setSelectedLeadContributorId] =
    useState<DropdownOptionValue>(leadContributorIdParam)
  const [leadContributorIds, setLeadContributorIds] = useState<null | string[]>(
    leadContributorIdParam ? [leadContributorIdParam] : null
  )
  const { subscribeOpportunityChannel } = useCableContext()

  useEffect(() => {
    opportunities?.forEach(opportunity => {
      subscribeOpportunityChannel(opportunity.id, () =>
        queryClient.invalidateQueries({ queryKey: ['mortgageOpportunities'] })
      )
    })
  }, [subscribeOpportunityChannel, opportunities, queryClient])

  const filteredOpportunities = useMemo(() => {
    if (!leadContributorIds) return opportunities
    return opportunities?.filter(
      opportunity => opportunity.leadContributorId && leadContributorIds.includes(opportunity.leadContributorId)
    )
  }, [opportunities, leadContributorIds])

  useEffect(() => {
    if (viewModeParam && Object.values(OPPORTUNITY_VIEW_MODE).includes(viewModeParam as OPPORTUNITY_VIEW_MODE)) {
      setViewMode(viewModeParam as OPPORTUNITY_VIEW_MODE)
    }
  }, [viewModeParam, setViewMode])

  return (
    <>
      <PageLayout
        title={t('opportunity.list_page_title')}
        topRightActions={
          <Button
            className="button--solid button--primary button--small pr-3"
            tracking={[CLICKS.opportunityCreate]}
            onClick={openNewOpportunityForm}
          >
            <PlusIcon />
            {t('opportunity.create_file')}
          </Button>
        }
      >
        {isPending ? (
          <Loader />
        ) : (
          <>
            <div className="flex justify-between mb-6">
              <div className="flex items-center gap-4 flex-1">
                <ViewModeSelect viewMode={viewMode} setViewMode={setViewMode} />
                <LeadContributorsSelect
                  value={selectedLeadContributorId}
                  setValue={setSelectedLeadContributorId}
                  setLeadContributorIds={setLeadContributorIds}
                />
              </div>

              <SearchBar opportunities={opportunities ?? []} />
            </div>
            {viewMode === OPPORTUNITY_VIEW_MODE.KANBAN ? (
              <OpportunityKanban opportunities={filteredOpportunities ?? []} />
            ) : (
              <div className="flex grow h-full relative">
                <div className="absolute inset-0 flex space-x-4 w-full overflow-x-auto">
                  <OpportunityTable opportunities={filteredOpportunities ?? []} />
                </div>
              </div>
            )}
          </>
        )}
      </PageLayout>

      <Outlet />
    </>
  )
}
