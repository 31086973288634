import { useBusinessPartners } from 'contexts/businessPartners'
import { useLeadContributors } from 'contexts/leadContributors'
import { EMPTY_SIGN, formatFullName } from 'helpers/format'
import { CLICKS } from 'helpers/tracking'
import React, { useCallback, useMemo } from 'react'

import { DropdownOptionValue } from 'ui/dropdowns/dropdown/types'
import { SelectInput } from 'ui/dropdowns/selectInput'

type LeadContributorsSelectProps = {
  value: DropdownOptionValue
  setValue: (value: DropdownOptionValue) => void
  setLeadContributorIds: (leadContributorIds: null | string[]) => void
}

export const LeadContributorsSelect: React.FC<LeadContributorsSelectProps> = ({
  value,
  setValue,
  setLeadContributorIds,
}) => {
  const { leadContributors } = useLeadContributors()
  const { businessPartners } = useBusinessPartners()

  const allOption = {
    label: 'Tous les apporteurs',
    value: null,
    type: 'text' as const,
    data: null,
  }

  const leadContributorOptions = useMemo(() => {
    if (!leadContributors) return []
    return leadContributors.map(leadContributor => ({
      label: formatFullName(leadContributor.firstName, leadContributor.lastName, EMPTY_SIGN),
      value: leadContributor.id,
      type: 'text' as const,
      data: leadContributor,
    }))
  }, [leadContributors])

  const businessPartnerOptions = useMemo(() => {
    if (!businessPartners) return []
    return businessPartners.map(businessPartner => ({
      label: businessPartner.name,
      value: businessPartner.id,
      type: 'text' as const,
      data: businessPartner,
    }))
  }, [businessPartners])

  const selectOnChange = useCallback(
    (value: DropdownOptionValue, data?: unknown) => {
      setValue(value)

      // no filter
      if (!value) {
        return setLeadContributorIds(null)
      }

      // filter by business partner
      if (data && typeof data === 'object' && 'leadContributorIds' in data) {
        // Necessary type cast because 'data' is of type 'unknown'
        const typedData = data as { leadContributorIds: string[] }
        return setLeadContributorIds(typedData.leadContributorIds)
      }

      // filter by lead contributor
      setLeadContributorIds([value])
    },
    [setValue, setLeadContributorIds]
  )

  return (
    <SelectInput
      className="selectInput--small"
      buttonClassName="w-[200px]"
      dropdownClassName="w-[240px] !z-50"
      value={value}
      onChange={selectOnChange}
      options={[[allOption], businessPartnerOptions, leadContributorOptions]}
      isSearchable={true}
      tracking={[CLICKS.opportunitiesFilter, { filter: 'lead-contributor' }]}
    />
  )
}
