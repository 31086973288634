import React from 'react'
import { Link, useMatch } from 'react-router-dom'
import { t } from 'utils/i18n'

import { DotIcon, getClassName } from './sidebarItem.styled'

export interface ISidebarItemContentProps {
  Icon?: HeroIcon
  ActionIcon?: HeroIcon
  active?: boolean
  disabled?: boolean
}

const SidebarItemContent: React.FC<ISidebarItemContentProps> = ({ Icon, ActionIcon, active, disabled, children }) => {
  return (
    <>
      <div className="flex items-center space-x-2">
        {(Icon && <Icon className={`w-5 h-5 mr-2 ${active ? '' : 'text-gray-400'}`} />) || (
          <span className="ml-1 mr-4">
            <DotIcon disabled={disabled} active={active ?? false} />
          </span>
        )}
        {children}
      </div>
      {disabled && (
        <div className="bg-gray-700 px-2.5 py-0.5 text-white font-medium text-xs rounded-xl ml-3">{t('soon_msg')}</div>
      )}
      {ActionIcon && <ActionIcon className={`w-5 h-5`} />}
    </>
  )
}

export interface ISidebarItemProps {
  Icon?: HeroIcon
  ActionIcon?: HeroIcon
  to: string
  disabled?: boolean
  highlight?: boolean
  redirect?: boolean
  onClick?: () => void
}

export const SidebarItem: React.FC<ISidebarItemProps> = ({
  to,
  Icon,
  ActionIcon,
  disabled,
  highlight,
  redirect,
  onClick,
  children,
}) => {
  const isActive = !!useMatch(to)

  const renderSidebarItemContent = () => (
    <SidebarItemContent active={isActive} Icon={Icon} disabled={disabled} ActionIcon={ActionIcon}>
      {children}
    </SidebarItemContent>
  )

  if (onClick)
    return (
      <div className={getClassName(disabled, isActive, highlight)} onClick={onClick}>
        {renderSidebarItemContent()}
      </div>
    )

  if (redirect)
    return (
      <a
        className={getClassName(disabled, isActive, highlight)}
        href={to}
        target={'_blank'}
        rel={'noopener noreferrer'}
      >
        {renderSidebarItemContent()}
      </a>
    )

  return (
    <Link className={getClassName(disabled, isActive, highlight)} to={to}>
      {renderSidebarItemContent()}
    </Link>
  )
}
