import React from 'react'
import { SendMandateModal } from 'components/opportunity/molecules/sendMandateModal'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { FormProvider } from 'ui/forms/form'
import { useQuery } from '@tanstack/react-query'
import { mortgageOpportunityQuery } from 'helpers/queries'

export const NewMandatePage: React.FC = () => {
  const navigate = useNavigate()
  const { opportunityId } = useParams() as { opportunityId: string }

  const opportunityQuery = useQuery(mortgageOpportunityQuery(opportunityId))

  return (
    <>
      {opportunityQuery.data && (
        <FormProvider>
          <SendMandateModal closeModal={() => navigate(ROUTES.BACK)} opportunity={opportunityQuery.data} />
        </FormProvider>
      )}
    </>
  )
}
