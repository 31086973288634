import { useRepresentativeContext } from 'contexts/representative'
import {
  CONSULTANCY_MORTGAGOR_TITLES,
  getConsultancyCoMortgagorInfos,
  getConsultancyMortgagorInfos,
} from 'helpers/documents'
import { EMPTY_SIGN, formatAmount, formatFullName } from 'helpers/format'
import React from 'react'
import { t } from 'utils/i18n'

import { CardContainer, LabelledCardVerticalBody as VerticalBody } from 'ui/legacy/molecules/card/card.styled'
import { CardDataColumns } from 'ui/legacy/molecules/card/cardDataColumns'
import { CardDataRow } from 'ui/legacy/molecules/card/cardDataRow'
import { Opportunity } from 'lib/oleenApi'

type SendConsultancyContractModalInfosProps = {
  opportunity: Opportunity
}

export const SendConsultancyContractInfos: React.FC<SendConsultancyContractModalInfosProps> = ({ opportunity }) => {
  const { representative } = useRepresentativeContext()

  const getLigthTableContent = () => [
    CONSULTANCY_MORTGAGOR_TITLES,
    getConsultancyMortgagorInfos(opportunity),
    ...(opportunity.hasCoMortgagor ? [getConsultancyCoMortgagorInfos(opportunity)] : []),
  ]

  return (
    <div className="flex flex-col w-full gap-4">
      <CardContainer>
        <VerticalBody>
          <CardDataRow
            label={t('opportunity.consultancy_modal.project_kind')}
            data={t(`project_kind.${opportunity.projectKind}`)}
          />
          <CardDataRow
            label={t('opportunity.consultancy_modal.good_usage')}
            data={t(`good_usage.${opportunity.goodUsage}`)}
          />
          <CardDataRow
            label={t('opportunity.consultancy_modal.total_project_cost')}
            data={formatAmount(opportunity.totalProjectCost)}
          />
          <CardDataRow
            label={t('opportunity.consultancy_modal.financing_amount_loan')}
            data={formatAmount(opportunity.loanAmount)}
          />
        </VerticalBody>
      </CardContainer>
      <CardContainer>
        <VerticalBody>
          <CardDataColumns content={getLigthTableContent()} />
        </VerticalBody>
      </CardContainer>
      <CardContainer>
        <VerticalBody>
          <CardDataRow
            label={t('opportunity.consultancy_modal.representative_identity')}
            data={formatFullName(representative?.firstName, representative?.lastName)}
          />
          <CardDataRow
            label={t('opportunity.consultancy_modal.representative_address')}
            data={representative?.address || EMPTY_SIGN}
          />
          <CardDataRow
            label={t('opportunity.consultancy_modal.representative_email')}
            data={representative?.email || EMPTY_SIGN}
          />
          ()
        </VerticalBody>
      </CardContainer>
    </div>
  )
}
