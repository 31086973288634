import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { ApiError, DefaultApiError } from './apiError'
import { parsePaginatedResponse } from './responseHandlers'
import { Paginated, PaginatedResponse, PostProcessFunction } from './types'

/**
 * Class for making API requests using Axios.
 *
 * FIXME: remove use af `any` type in this class. For now, it should only be used to handle errors
 */
export class Client {
  public axiosInstance: AxiosInstance
  private currentErrorHandler: new (...args: any[]) => ApiError<any> = DefaultApiError

  /**
   * Creates an instance of Client.
   */
  constructor(contentType = 'application/json') {
    const baseURL = process.env.API_BASE_URL
    this.axiosInstance = axios.create({
      baseURL,
    })
    this.axiosInstance.defaults.headers.common['Access-Control-Allow-Origin'] = '*'
    this.axiosInstance.defaults.headers.common.Accept = 'application/json'
    this.axiosInstance.defaults.headers.post['Content-Type'] = contentType
  }

  public hasAuthToken(): boolean {
    return !!this.axiosInstance.defaults.headers.common.Authorization
  }

  public setAuthToken(authToken: string): void {
    this.axiosInstance.defaults.headers.common.Authorization = `Bearer ${authToken}`
  }

  public setErrorHandler<T extends ApiError<any>>(errorHandler: new (...args: any[]) => T): void {
    this.currentErrorHandler = errorHandler
  }

  /**
   * Sends an API request using Axios and optionally processes the response.
   * @template S - The expected source type of the Axios response.
   * @template T - The expected return type after processing the response.
   * @param config - The Axios request configuration.
   * @param postProcessFn - An optional function to process the Axios response.
   * @returns A promise that resolves with type T.
   */
  public async request<S, T, U = S>(
    config: AxiosRequestConfig,
    postProcessFn?: PostProcessFunction<AxiosResponse<U>, T>
  ): Promise<T> {
    try {
      const response = await this.axiosInstance.request(config)
      if (!postProcessFn) return response.data as unknown as T
      return postProcessFn(response)
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        throw new this.currentErrorHandler(error.response.statusText, error.response.status, error.response.data.errors)
      }
      throw error
    }
  }

  /**
   * Retrieves paginated data via a GET request.
   * @template S - The source type of items in the paginated response.
   * @template T - The target type of items after processing.
   * @template M - The target type of the meta object after processing.
   * @param path - The API path to fetch the data from.
   * @param page - The current page number.
   * @param pageSize - The number of items per page.
   * @param postProcessFn - The function to process each item in the paginated response.
   * @returns A promise that resolves with a paginated response of type T.
   */
  public getPaginatedData<S, T, M = undefined>(
    path: string,
    page: number,
    pageSize: number,
    postProcessFn: PostProcessFunction<S, T>
  ): Promise<Paginated<T, M>> {
    return this.request<PaginatedResponse<S, M>, Paginated<T, M>>(
      {
        url: path,
        method: 'GET',
        params: { page, per_page: pageSize },
      },
      response => {
        const { data } = response
        return parsePaginatedResponse(data, postProcessFn)
      }
    )
  }
}
