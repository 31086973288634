import { useForm } from '@tanstack/react-form'
import { useMutation } from '@tanstack/react-query'
import { CLICKS, track } from 'helpers/tracking'
import { OpportunityFile } from 'lib/oleenApi'
import React, { useState } from 'react'
import { Trans } from 'react-i18next'
import { Button } from 'ui/buttons/button'
import { FileInput } from 'ui/inputs/fileInput'
import { Checkbox } from 'ui/legacy/atoms/checkbox/checkbox.component'
import { TanstackFormInput } from 'ui/misc/tanstackFormInput'
import { Spinner } from 'ui/spinner/spinner'
import { t } from 'utils/i18n'

interface BOSFormProps {
  action: (file: OpportunityFile) => Promise<void>
  onSuccess: () => void
  onError: (error: Error) => void
  onSkip: (bosAllowed: boolean) => void
}

export const BOSOpportunityForm: React.FC<BOSFormProps> = ({ action, onSuccess, onError, onSkip }) => {
  const opportunityToBos = useMutation({
    mutationFn: action,
    onSuccess,
    onError: error => {
      onError(error)
    },
  })
  const { Field, handleSubmit, useStore } = useForm<{
    file: File | undefined
  }>({
    defaultValues: {
      file: undefined,
    },
    onSubmit: ({ value }) => {
      if (value.file === undefined) {
        track(CLICKS.skipBosForm, { fileIsPresent: value.file !== undefined, alreadyUploadChecked: alreadyUpload })
        onSkip(true)
        return
      }

      const payload = {
        data: value.file,
        filename: `0-BOS-${value.file.name}`,
      }

      track(CLICKS.submitBosForm, { fileIsPresent: value.file !== undefined, alreadyUploadChecked: alreadyUpload })
      opportunityToBos.mutate(payload)
    },
  })

  const fileIsSelected = useStore(state => state.values.file !== undefined)
  const [alreadyUpload, setAlreadyUpload] = useState(false)

  return (
    <form className="flex flex-col gap-4">
      <Checkbox
        name={'alreadyUpload'}
        label={t('opportunity.bos_opportunity_modal.form.checkbox')}
        onChange={value => setAlreadyUpload(value)}
      />
      <p>{t('opportunity.bos_opportunity_modal.form.dragndrop')}</p>
      <Field name="file">
        {field => (
          <TanstackFormInput className="w-full" label={''} field={field}>
            <FileInput
              value={field.state.value}
              onChange={value => field.handleChange(value as File)}
              label={
                <Trans i18nKey="notification.opportunity_contributed.file_input_label">
                  <p>
                    Déposer un document ou{' '}
                    <button className="text-royalBlue-600 hover:text-blue-700 transition-colors">
                      parcourir mon ordinateur
                    </button>
                    {'\n(format: pdf, png, jpeg)'}
                  </p>
                </Trans>
              }
            ></FileInput>
          </TanstackFormInput>
        )}
      </Field>

      <div className="flex justify-center">
        {opportunityToBos.isPending && <Spinner />}
        {!opportunityToBos.isPending && (
          <Button
            className="button--medium button--primary button--solid w-full"
            onClick={handleSubmit}
            disabled={!(alreadyUpload || fileIsSelected)}
          >
            {t('opportunity.bos_opportunity_modal.submit')}
          </Button>
        )}
      </div>
    </form>
  )
}
