import { ChevronUpDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

type THProps = PropsWithChildren<{ id: string; sticky?: boolean; sort?: () => void; className?: string }>

export const TH: React.FC<THProps> = ({ className, children, id, sticky, sort }) => (
  <th id={id} className={classNames('tableHead', { 'tableHead--sticky': sticky }, className)}>
    {sort !== undefined ? (
      <button onClick={sort}>
        {children}
        <ChevronUpDownIcon />
      </button>
    ) : (
      <span>{children}</span>
    )}
  </th>
)
