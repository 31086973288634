import React, { useCallback, useMemo, useState } from 'react'
import { Modal } from 'ui/modals/modal'
import { t } from 'utils/i18n'
import { useFormContext } from 'ui/forms/form'
import { getPrompt } from './prompts'
import { mapDataToFrench } from './misc'
import { useMistral } from 'hooks/mistral.hook'
import { Opportunity, Representative } from 'lib/oleenApi'
import { EmailGenerationSide } from './emailGenerationSide'
import { EmailEditionSide } from './emailEditionSide'
import { toast } from 'helpers/toast'

enum Sides {
  GENERATION = 'generation',
  EDITION = 'edition',
}

type EmailModalProps = {
  onClose: () => void
  opportunity: Opportunity
  representative: Representative
}

export const EmailModal: React.FC<EmailModalProps> = ({ onClose, opportunity, representative }) => {
  const [side, setSide] = useState(Sides.GENERATION)
  const { getFormData } = useFormContext()
  const { run, response } = useMistral()
  const [userPrompt, setUserPrompt] = useState<string>('')

  const onGenerationSubmit = useCallback(() => {
    const data = getFormData()
    const prompt = getPrompt({
      emailType: data.emailType as string,
      emailStyle: data.emailStyle as string,
      userPrompt: data.userPrompt as string,
      data: mapDataToFrench(opportunity, representative),
    })
    setUserPrompt(data.userPrompt as string)
    run(prompt)
    setSide(Sides.EDITION)
  }, [setSide, opportunity, representative, run, getFormData, setUserPrompt])

  const splitResponse = (s: string) => s.replace('Objet :', '').split(/\n\n/)

  const emailSubject = useMemo(() => splitResponse(response || '')[0], [response])

  const emailBody = useMemo(
    () =>
      splitResponse(response || '')
        .slice(1)
        .join('\n\n') || '',
    [response]
  )

  const onSubjectCopy = useCallback(() => {
    navigator.clipboard.writeText(emailSubject)
    toast.success(t('opportunity.emailModal.alerts.subjectCopied'))
  }, [emailSubject])

  const onBodyCopy = useCallback(() => {
    navigator.clipboard.writeText(emailBody)
    toast.success(t('opportunity.emailModal.alerts.bodyCopied'))
  }, [emailBody])

  return (
    <Modal
      className="modal--form !md:w-[600px]"
      isOpen={true}
      onClose={onClose}
      title={t('opportunity.emailModal.title')}
    >
      {side === Sides.GENERATION && <EmailGenerationSide onSubmit={onGenerationSubmit} userPrompt={userPrompt} />}
      {side === Sides.EDITION && (
        <EmailEditionSide
          onReturn={() => setSide(Sides.GENERATION)}
          emailSubject={emailSubject}
          emailBody={emailBody}
          onBodyCopy={onBodyCopy}
          onSubjectCopy={onSubjectCopy}
        />
      )}
    </Modal>
  )
}
