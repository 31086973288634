import { ClockIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { id as genId } from 'helpers/ref'
import React, { forwardRef, useCallback } from 'react'

dayjs.extend(customParseFormat)

type FormTimeInputProps = {
  value?: string
  className?: string
  disabled?: boolean
  id?: string
  placeholder?: string
  step?: number
  onFocus?: () => void
  onChange: (value: string) => void
  onBlur?: () => void
}

export const TimeInput = forwardRef<HTMLInputElement, FormTimeInputProps>(
  ({ value, id, placeholder, step = 300, onChange, onBlur, onFocus, disabled, className }, ref) => {
    const inputId = id || genId()

    const handleFocus = useCallback(
      (event: React.FocusEvent<HTMLInputElement>) => {
        // This code was copy-pasted from the Zou app, where it complies successfully.
        // No idea why it doesn't work here. Works on runtime, so I'm disabling
        // typescript and the lint rule.
        if ('showPicker' in event.target) {
          /* eslint-disable @typescript-eslint/ban-ts-comment */
          // @ts-ignore
          ;(event.target as HTMLInputElement).showPicker()
          onFocus?.()
        }
      },
      [onFocus]
    )

    return (
      <div
        className={classNames(className, 'group', 'textInput textInput--small', {
          'textInput--disabled': disabled,
        })}
      >
        {
          <label htmlFor={inputId}>
            <ClockIcon className="group-focus-within:text-blue-600" />
          </label>
        }
        <input
          ref={ref}
          id={id}
          className={classNames({
            // highlight: isHighlighted,
            'bg-gray-50 text-gray-400 pointer-events-none': disabled,
            'bg-white hover:border-gray-500 focus:border-blue-500': !disabled,
          })}
          type="time"
          placeholder={placeholder}
          disabled={disabled}
          value={value as string}
          onChange={e => onChange(e.currentTarget.value as string)}
          onBlur={onBlur}
          onFocus={handleFocus}
          step={step}
        />
      </div>
    )
  }
)
TimeInput.displayName = 'TimeInput'
