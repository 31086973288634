import classNames from 'classnames'
import React, { ReactElement, useState } from 'react'

import { sortItems } from './table/sorting'
import { TH } from './table/th'
import { Col, Path, SortDirection } from './table/types'

type TableProps<T> = { cols: Col<T>[]; items: T[]; className?: string; theadClassName?: string }

export const Table = <T,>({ cols, items, className, theadClassName }: TableProps<T>): ReactElement => {
  const [sortConfig, setSortConfig] = useState<{ key: Path<T>; direction: SortDirection } | null>(null)

  const sortedItems = React.useMemo(() => {
    return sortItems(items, sortConfig)
  }, [items, sortConfig])

  const sortBy = (key: Path<T>) => {
    let direction = SortDirection.ASCENDING
    if (sortConfig && sortConfig.key === key && sortConfig.direction === SortDirection.ASCENDING) {
      direction = SortDirection.DESCENDING
    }
    setSortConfig({ key, direction })
  }

  return (
    <div className={classNames('table--wrapper', className)}>
      <table>
        <thead className={theadClassName}>
          <tr>
            {cols.map(({ name, sticky, sort, headerClassName }, i) => {
              const onSort = sort === undefined || typeof sort === 'function' ? sort : () => sortBy(sort)
              return (
                <TH id={i.toString()} key={i} sticky={sticky} sort={onSort} className={headerClassName}>
                  {name}
                </TH>
              )
            })}
          </tr>
        </thead>
        <tbody>
          {sortedItems.map((item, i) => (
            <tr key={i}>
              {cols.map(({ render, sticky, className }, j) => (
                <td className={classNames('tableCell', className, { 'tableCell--sticky': sticky })} key={j}>
                  {render(item)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
