import { SelectCalendarModal } from 'components/tasks/selectCalendarModal'
import React from 'react'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { Task } from 'lib/oleenApi/services/tasksService'

import { FormProvider } from 'ui/forms/form'

export const AddTaskToCalendarPage: React.FC = () => {
  const { taskId } = useParams()
  const navigate = useNavigate()
  const { task } = useOutletContext<{ task: Task }>()

  return (
    <FormProvider>
      {taskId && <SelectCalendarModal onClose={() => navigate(`${ROUTES.BACK}/${ROUTES.BACK}`)} task={task} />}
    </FormProvider>
  )
}
