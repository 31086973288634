import classNames from 'classnames'
import React, { forwardRef } from 'react'

import { FieldState } from 'ui/forms/form.hook'
import { DateInput } from 'ui/inputs/dateInput'

type FormDateInputProps = FieldState & {
  dateInputClassName?: string
  disabled?: boolean
  id?: string
  placeholder?: string
  onFocus?: () => void
}

export const FormDateInput = forwardRef<HTMLInputElement, FormDateInputProps>(
  ({ id, placeholder, handleFieldChange, ...props }, ref) => {
    return (
      <DateInput
        ref={ref}
        className={classNames({
          'textInput--error': !!props.error,
          'textInput--medium': !props.dateInputClassName?.includes('textInput--small'),
        })}
        placeholder={placeholder}
        id={id}
        onChange={(value: string) => handleFieldChange(value)}
        onBlur={() => props.handleFieldBlur(props.value as string)}
        onFocus={props.onFocus}
        value={props.value as string}
        disabled={props.disabled}
      />
    )
  }
)
FormDateInput.displayName = 'FormDateInput'
