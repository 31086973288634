import React from 'react'
import { t } from 'utils/i18n'

import { Modal } from 'ui/modals/modal'

import { InsuranceOpportunityForm } from './insuranceOpportunityForm'
import { Opportunity } from 'lib/oleenApi'

interface DropModalProps {
  closeModal: () => void
  opportunityId: Opportunity['id']
}

export const InsuranceOpportunityModal: React.FC<DropModalProps> = ({ closeModal, opportunityId }) => {
  return (
    <Modal
      className="modal--form md:w-[450px]"
      isOpen={true}
      onClose={closeModal}
      title={t('opportunity.insurance_opportunity_modal.title')}
    >
      <div>
        <InsuranceOpportunityForm opportunityId={opportunityId} />
      </div>
    </Modal>
  )
}
