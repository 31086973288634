import React, { PropsWithChildren } from 'react'

interface IPageLayoutProps {
  title?: string
  topRightActions?: React.ReactNode
  centerTitle?: boolean
}

export const PageHeader: React.FC<PropsWithChildren<object>> = ({ children }) => (
  <div className="w-full flex justify-between items-center p-6 border-b border-gray-200">{children}</div>
)

export const PageLayout: React.FC<IPageLayoutProps> = ({ title, topRightActions, centerTitle, children }) => (
  <div className="h-full flex flex-col bg-white margin-auto">
    {title && (
      <PageHeader>
        {centerTitle && <div />}
        {title && <span className="text-3xl font-bold text-gray-900 truncate">{title}</span>}
        <div className="flex space-x-3">{topRightActions}</div>
      </PageHeader>
    )}
    <div className="p-6 grow flex flex-col">{children}</div>
  </div>
)
