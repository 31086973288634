import { useOleenApi } from 'contexts/oleenApi'
import React from 'react'
import { Card } from 'ui/sections/card'
import { t } from 'utils/i18n'

import { useQuery } from '@tanstack/react-query'
import { AddonItem } from './addonItem'

export const AddonsCard: React.FC = () => {
  const { oleenApi } = useOleenApi()

  const addonsQuery = useQuery({
    queryKey: ['addons'],
    queryFn: () => oleenApi.addons().listAll(),
  })

  return (
    <Card title={t('addons.title')}>{addonsQuery.data?.map((addon, i) => <AddonItem key={i} addon={addon} />)}</Card>
  )
}
