export interface Competitor {
  label: string
  value: string
}

export enum CompetitorType {
  bank,
  broker,
}

export type CompetitorListType = {
  banks: Competitor[]
  brokers: Competitor[]
}
