import classNames from 'classnames'
import React, { forwardRef } from 'react'

import { FieldState } from 'ui/forms/form.hook'
import { AutocompleteInput } from 'ui/inputs/autocompleteInput'

export interface FormAutocompleteInputProps extends FieldState {
  className?: string
  dropdownClassName?: string
  disabled?: boolean
  placeholder?: string
  items: string[]
}

export const FormAutocompleteInput = forwardRef<HTMLInputElement, FormAutocompleteInputProps>(
  ({ placeholder, ...props }, ref) => {
    return (
      <AutocompleteInput
        className={props.className}
        textFieldClassName={classNames({
          'textInput--error': !!props.error,
        })}
        dropdownClassName={props.dropdownClassName}
        ref={ref}
        placeholder={placeholder}
        {...props}
        value={props.value as string}
        onChange={props.handleFieldChange}
        onBlur={props.handleFieldBlur}
        items={props.items}
      />
    )
  }
)
FormAutocompleteInput.displayName = 'FormAutocompleteInput'
