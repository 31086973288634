import { AnalyticsBrowser } from '@segment/analytics-next'
import Sentry from 'utils/sentry'

const segment = AnalyticsBrowser.load({ writeKey: process.env.SEGMENT_KEY || '' })

export const trackEvent = (event: string, options?: object | undefined) => {
  try {
    segment.track(event, options)
  } catch (e) {
    Sentry.captureException(e)
  }
}

export const TRACKING_EVENT = {
  ACCOUNT_PARAMETERS: {
    FEES_UPDATED: 'account-parameters:fees-updated',
    DISCONNECTED: 'account-parameters:disconnect',
  },
  CONTACT: {
    ARCHIVED: 'contact:archived',
  },
  DOCUMENTS: {
    DEPOSIT_CERTIFICATE_GENERATED: 'deposit-certificate-generate',
    SIMULATION_CERTIFICATE_GENERATED: 'simulation-certificate-generate',
  },
  OPPORTUNITY: {
    CREATED: 'opportunity:created',
    EDITED: 'opportunity:edited',
    LOST: 'opportunity:lost',
  },
}
