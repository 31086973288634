import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import ExclamationCircleIcon from '@heroicons/react/20/solid/ExclamationCircleIcon'
import { EMPTY_SIGN, formatAmount, formatDate } from 'helpers/format'
import React from 'react'
import { Interrogation } from 'lib/oleenApi'
import { t } from 'utils/i18n'

import Banner from 'ui/banners/banner'
import { BannerVariants } from 'ui/banners/banner.styled'
import { Button } from 'ui/buttons/button'
import { CLICKS, track } from 'helpers/tracking'

type OpportunityInterrogationProps = {
  interrogation: Interrogation
  projectId: string
}

export const OpportunityInterrogation = ({ interrogation, projectId }: OpportunityInterrogationProps) => {
  const renderLine = (label: string, value: string) => {
    return (
      <div className="flex items-start justify-between gap-x-8">
        <p className="text-sm font-normal antialiased h-6 leading-6">{label}</p>
        <p className="text-sm font-semibold antialiased h-6 leading-6">{value}</p>
      </div>
    )
  }
  return (
    <div
      onClick={() => {
        track(CLICKS.opportunityOpenZouInterrogations)
        window.open(t('url.zou_interrogation', { projectId }), '_blank')
      }}
      className="cursor-pointer pt-2"
    >
      {interrogation.isBankResponseExpected && (
        <div className="py-2">
          <Banner variant={BannerVariants.ORANGE} Icon={ExclamationCircleIcon}>
            <p className="flex-1">{t('opportunity.interrogation.bank_response_banner')}</p>

            <Button
              className="button--solid button--warning button--small"
              tracking={[CLICKS.opportunityOpenZouBankResponses]}
              onClick={() => {
                window.open(
                  t('url.zou_interrogation_responses', { projectId, interrogationId: interrogation.finspotId }),
                  '_blank'
                )
              }}
            >
              {t('opportunity.interrogation.bank_response_banner_action')}

              <ArrowTopRightOnSquareIcon />
            </Button>
          </Banner>
        </div>
      )}

      <div className="flex flex-col md:flex-row divide-y md:divide-x md:divide-y-0 justify-center">
        <div className="flex flex-col gap-1 basis-0 grow mb-4 md:mb-0 md:mr-4 py-1">
          <span className="uppercase text-gray-500 font-semibold text-xs leading-6 antialiased truncate">
            {t('opportunity.interrogation.details')}
          </span>
          <div className="text-gray-500 antialiased text-xs font-normal">
            {t('opportunity.interrogation.contact_bank')} {': '} {interrogation.bankContactName ?? EMPTY_SIGN}
          </div>
          {renderLine(t('opportunity.interrogation.amount'), formatAmount(interrogation.loanAmount))}
          {renderLine(
            t('opportunity.interrogation.rate_asked'),
            t('opportunity.interrogation.rate_asked_formatted', { rate: interrogation.loanRate })
          )}
          {renderLine(
            t('opportunity.interrogation.duration_asked'),
            t('opportunity.interrogation.duration_asked_formatted', { month: interrogation.loanDuration })
          )}
        </div>
        <div className="flex flex-col gap-1 basis-0 grow pt-4 md:pt-1 md:pl-4">
          <span className="uppercase text-gray-500 font-semibold text-xs leading-6 antialiased truncate">
            {t('opportunity.interrogation.events').toUpperCase()}
          </span>
          {renderLine(t('opportunity.interrogation.creation_date'), formatDate(interrogation.createdAt).split(' ')[0])}
          {renderLine(t('opportunity.interrogation.sent_date'), formatDate(interrogation.sentAt).split(' ')[0])}
          {interrogation.bankResponseStatus && (
            <>
              {interrogation.bankResponseStatus === 'denied' &&
                renderLine(
                  t('opportunity.interrogation.bank_refusal_date'),
                  formatDate(interrogation.bankResponseCreatedAt).split(' ')[0]
                )}
              {renderLine(
                t('opportunity.interrogation.bank_response'),
                t(`opportunity.interrogation.bank_response_status.${interrogation.bankResponseStatus}`)
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
