import { formatAddress, formatFullName } from 'helpers/format'
import { Opportunity } from 'lib/oleenApi'

export interface IContact {
  fullAddress?: string
  name: string
  phoneNumber?: string | null
  email?: string | null
}

interface IOpportunityContacts {
  primaryContact: IContact
  secondaryContact?: IContact
}

export const mapOpportunityToPrimaryAndSecondaryContact = ({
  mortgagorEmail,
  mortgagorLastName,
  mortgagorFirstName,
  mortgagorPhone,
  mortgagorAddress,
  hasCoMortgagor,
  coMortgagorEmail,
  coMortgagorPhone,
  coMortgagorAddress,
  coMortgagorFirstName,
  coMortgagorLastName,
}: Opportunity): IOpportunityContacts => ({
  primaryContact: {
    fullAddress: formatAddress(mortgagorAddress),
    name: formatFullName(mortgagorFirstName, mortgagorLastName),
    phoneNumber: mortgagorPhone,
    email: mortgagorEmail,
  },
  ...(hasCoMortgagor
    ? {
        secondaryContact: {
          fullAddress: formatAddress(coMortgagorAddress),
          name: formatFullName(coMortgagorFirstName, coMortgagorLastName),
          email: coMortgagorEmail,
          phoneNumber: coMortgagorPhone,
        },
      }
    : {}),
})
