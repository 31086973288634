import { Client } from '../client'
import { ObjectCamelToSnakeCase } from '../misc'
import { Paginated } from '../types'

declare module '../client' {
  interface Client {
    addons: () => AddonsService
  }
}

Client.prototype.addons = function () {
  return new AddonsService(this)
}

export interface Addon {
  name: AddonName
  status: AddonStatus
}

type AddonResponse = ObjectCamelToSnakeCase<Addon>

interface IAddonsService {
  list: (page: number, pageSize: number) => Promise<Paginated<Addon>>
  listAll: () => Promise<Addon[]>
  get: (id: string) => Promise<Addon>
}

export enum AddonName {
  YUCCANLEAD = 'yuccanlead',
  SIMPLEBO = 'simplebo',
}

export enum AddonStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
  PENDING = 'pending',
}

class AddonsService implements IAddonsService {
  constructor(private client: Client) {}

  list(page = 1, pageSize = 10) {
    return this.client.getPaginatedData<AddonResponse, Addon>('/addons', page, pageSize, parseAddon)
  }

  async listAll(): Promise<Addon[]> {
    let nextPage: null | number = 1
    const allAddons: Addon[] = []

    while (nextPage) {
      const paginatedResult: Paginated<Addon> = await this.list(nextPage, 10)
      allAddons.push(...paginatedResult.items)
      nextPage = paginatedResult.nextPage
    }

    return allAddons
  }

  get(id: string) {
    return this.client.request<undefined, Addon, AddonResponse>(
      {
        url: `/addons/${id}`,
        method: 'GET',
      },
      response => parseAddon(response.data)
    )
  }
}

const parseAddon = (data: AddonResponse): Addon => {
  return {
    name: data.name,
    status: data.status,
  }
}
