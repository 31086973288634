import { isBosAlertStatus } from 'helpers/opportunity'
import { CLICKS, track } from 'helpers/tracking'
import { t } from 'i18next'
import { Opportunity } from 'lib/oleenApi'
import React, { useMemo } from 'react'
import { Alert } from 'ui/banners/alert'
import { Button } from 'ui/buttons/button'

interface OpportunityAlertsProps {
  opportunity: Opportunity
}

const OpportunityAlerts: React.FC<OpportunityAlertsProps> = ({ opportunity }) => {
  const isMortgagorMissingPhone = useMemo<boolean>(
    () =>
      opportunity.mortgagorPhone === null ||
      opportunity.mortgagorPhone === '' ||
      (opportunity.hasCoMortgagor && (opportunity.coMortgagorPhone === null || opportunity.coMortgagorPhone === '')),
    [opportunity]
  )

  const hasBOSError = useMemo<boolean>(() => {
    return !opportunity.isReadyToBOS && isBosAlertStatus(opportunity.status)
  }, [opportunity])

  const isActive = hasBOSError || isMortgagorMissingPhone

  return (
    <>
      {(hasBOSError || isMortgagorMissingPhone) && (
        <div className="flex flex-col gap-y-2">
          {hasBOSError && (
            <Alert className="alert--warning alert--small">
              <div className="flex flex-col">
                <p className="font-semibold">{t('opportunity.alert.bos_conditions.title')}</p>
                <ul className="flex-1 list-disc">
                  {opportunity.areBankResponsesExpected && (
                    <li className="ml-6">
                      <a
                        onClick={() => track(CLICKS.bosCalloutDetails, { type: 'empty_bank_response' })}
                        href="#interrogations_card"
                      >
                        {t('opportunity.alert.bos_conditions.empty_bank_response')}
                      </a>
                    </li>
                  )}
                  {opportunity.bankAppointmentOn === null && (
                    <li className="ml-6">
                      <a
                        onClick={() => track(CLICKS.bosCalloutDetails, { type: 'bank_appointment_missing' })}
                        href="#bank_appointment_on_input"
                      >
                        {t('opportunity.bank_appointment_on')}
                      </a>
                    </li>
                  )}
                  {opportunity.bankContact === null && (
                    <li className="ml-6">
                      <a
                        onClick={() => track(CLICKS.bosCalloutDetails, { type: 'bank_contact' })}
                        href="#bank_contact_input"
                      >
                        {t('opportunity.bank_contact')}
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <Button
                className="button--outline button--small bg-inherit ml-auto"
                onClick={() => window.open(t('opportunity.alert.bos_conditions.help_link'), '_blank')}
                tracking={[CLICKS.bosHelp]}
              >
                {t('opportunity.alert.bos_conditions.help')}
              </Button>
            </Alert>
          )}
          {isMortgagorMissingPhone && (
            <Alert id="inactive-userspace-banner" className="alert--warning alert--small">
              <p>
                {t('opportunity.alert.empty_mortgagor_phone.left')}
                <a
                  target="_blank"
                  href={t('url.zou_project_mortgagor', { projectId: opportunity.projectId })}
                  rel="noreferrer"
                >
                  {t('opportunity.alert.empty_mortgagor_phone.link')}
                </a>
              </p>
            </Alert>
          )}
        </div>
      )}
    </>
  )
}

export default OpportunityAlerts
