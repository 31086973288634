import { TooltipSumCategory } from 'components/opportunities/atoms/tooltipSumCategory/tooltipSumCategory'
import { Fees } from 'components/opportunities/atoms/tooltipSumCategory/tooltipSumCategory.types'
import {
  getOpportunityStatusBadgeColor,
  sumBankAndBrokerageFees,
  sumBankCommission,
  sumBrokerageFees,
} from 'helpers/opportunity'
import { id as refId } from 'helpers/ref'
import React from 'react'
import { t } from 'utils/i18n'

import { Badge } from 'ui/badges/badge'
import { Opportunity, OPPORTUNITY_STATUS } from 'lib/oleenApi'
import { CLICKS, track } from 'helpers/tracking'

type KanbanHeaderProps = {
  status: OPPORTUNITY_STATUS
  opportunitiesMap: Record<string, Opportunity[]>
}

export const KanbanHeader = ({ status, opportunitiesMap }: KanbanHeaderProps) => {
  const displayTooltip = ![
    OPPORTUNITY_STATUS.PERDU,
    OPPORTUNITY_STATUS.A_JOINDRE,
    OPPORTUNITY_STATUS.RELANCE_DOC,
  ].includes(status)

  return (
    <div className="flex items-center justify-between" key={refId()}>
      <Badge className={getOpportunityStatusBadgeColor(status)}>{t(`opportunity.statuses.${status}`)}</Badge>
      <div className="flex flex-col group">
        {displayTooltip && (
          <>
            <div
              className="flex p-1.5 cursor-pointer rounded-xl hover:bg-[#2596BE]/[.16]"
              onMouseEnter={() => track(CLICKS.opportunitiesRevenueShow, { status })}
            >
              <span className="antialiased text-xs font-semibold truncate">
                {sumBankAndBrokerageFees(opportunitiesMap[status])}
              </span>
            </div>
            <TooltipSumCategory
              className="absolute top-14 invisible group-hover:visible"
              title={status}
              items={[
                { label: Fees.brokerageFees, amount: sumBrokerageFees(opportunitiesMap[status]) },
                { label: Fees.bankCommission, amount: sumBankCommission(opportunitiesMap[status]) },
              ]}
            />
          </>
        )}
      </div>
    </div>
  )
}
