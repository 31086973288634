import { Client } from '../client'

/**
 * Augments the 'Client' class with a method for accessing mandates services.
 */
declare module '../client' {
  interface Client {
    /**
     * Gets an instance of the mandatesService.
     * @returns mandatesService instance.
     */
    mandates: () => MandatesService
  }
}

/**
 * Extension method to get mandatesService.
 */
Client.prototype.mandates = function () {
  return new MandatesService(this)
}

/**
 * Service for managing mandates.
 */
interface ImandatesService {
  cancel: (mandateId: string) => Promise<void>
}

class MandatesService implements ImandatesService {
  constructor(private client: Client) {}

  cancel = (mandateId: string): Promise<void> => {
    return this.client.request({
      url: `/mortgage/mandates/${mandateId}`,
      method: 'DELETE',
    })
  }
}
