import { useCallback, useState } from 'react'

interface UseAccordionItemProps {
  defaultIsOpen?: boolean
}

export const useAccordionItem = ({ defaultIsOpen }: UseAccordionItemProps) => {
  const [isOpen, setIsOpen] = useState(defaultIsOpen ?? false)

  const toggleAccordionItem = useCallback(() => setIsOpen(!isOpen), [isOpen, setIsOpen])

  return { isOpen, toggleAccordionItem }
}
