import { LeadContributorManagerItem } from 'components/businessPartners/leadContributorsManager/leadContributorManagerItem'
import { useLeadContributors } from 'contexts/leadContributors'
import { EMPTY_SIGN, formatFullName } from 'helpers/format'
import { LeadContributor } from 'lib/oleenApi'
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { t } from 'utils/i18n'

import { DropdownOptionValue } from 'ui/dropdowns/dropdown/types'
import { SelectInput } from 'ui/dropdowns/selectInput'

export type LeadContributorsManagerProps = {
  leadContributors: LeadContributor[]
  setLeadContributors: Dispatch<SetStateAction<LeadContributor[]>>
}

export const LeadContributorsManager: React.FC<LeadContributorsManagerProps> = ({
  leadContributors,
  setLeadContributors,
}) => {
  const { leadContributors: allLeadContributors, getLeadContributor } = useLeadContributors()

  const options = useMemo(
    () =>
      allLeadContributors
        .filter(lc => !leadContributors.includes(lc))
        .map(lc => ({
          value: lc.id,
          label: formatFullName(lc?.firstName, lc?.lastName, EMPTY_SIGN),
          type: 'text' as const,
        })),
    [allLeadContributors, leadContributors]
  )

  // TODO : add tracking capabilities to selectInput button and options
  const addLeadContributor = useCallback(
    (value: DropdownOptionValue) => {
      if (!value) return
      setLeadContributors(prev => {
        const leadContributor = getLeadContributor(value)
        return leadContributor ? [...prev, leadContributor] : prev
      })
    },
    [setLeadContributors, getLeadContributor]
  )

  const removeLeadContributor = useCallback(
    (value: string) => {
      setLeadContributors(prev => prev.filter(lc => lc.id !== value))
    },
    [setLeadContributors]
  )

  return (
    <>
      {leadContributors.map(lc => (
        <LeadContributorManagerItem key={lc.id} leadContributor={lc} onRemove={removeLeadContributor} />
      ))}
      <SelectInput
        dropdownClassName="w-full !max-h-[200px]"
        value={null}
        options={[
          [
            {
              value: null,
              label: t('business_partners.lead_contributors.add'),
              type: 'text' as const,
            },
            ...options,
          ],
        ]}
        onChange={addLeadContributor}
        isSearchable={true}
        searchPlaceholder="Rechercher un collaborateur"
      />
    </>
  )
}
