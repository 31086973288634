import { PencilIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { OPPORTUNITY_FIELDS } from 'helpers/opportunity'
import { id } from 'helpers/ref'
import React, { useEffect, useState } from 'react'
import { FormInput } from 'ui/forms/formInput'
import { t } from 'utils/i18n'

type SubtitleProps = {
  subtitle: string | null
}

export const Subtitle: React.FC<SubtitleProps> = ({ subtitle }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [subtitleValue, setSubtitleValue] = useState(subtitle)

  const inputId = id()

  useEffect(() => {
    if (isEditing) document.getElementById(inputId)?.focus()
  })

  return (
    <>
      <FormInput
        id={inputId}
        name={OPPORTUNITY_FIELDS.SUBTITLE}
        defaultValue={subtitleValue}
        onBlur={() => setIsEditing(false)}
        onChange={value => setSubtitleValue(value as string)}
        autoFocus={isEditing}
        className={classNames({ hidden: !isEditing })}
        textInputClassName="textInput--small md:textInput--medium"
        enableClear={false}
      />
      {!isEditing && (
        <div className="flex items-center cursor-pointer gap-2" onClick={() => setIsEditing(true)}>
          {subtitleValue ? (
            <p className="text-sm md:text-xl">{subtitleValue}</p>
          ) : (
            <p className="text-sm md:text-xl text-gray-500">{t('opportunity.subtitle.placeholder')}</p>
          )}
          <PencilIcon className="w-5 h-5" />
        </div>
      )}
    </>
  )
}
