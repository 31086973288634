import {
  LeadContributorsManager,
  LeadContributorsManagerProps,
} from 'components/businessPartners/leadContributorsManager/leadContributorsManager'
import React, { useEffect, useMemo } from 'react'

import { useFormContext } from 'ui/forms/form'

type LeadContributorsManagerFormElementProps = {
  name: string
} & LeadContributorsManagerProps

export const LeadContributorsManagerFormElement: React.FC<LeadContributorsManagerFormElementProps> = ({
  name,
  leadContributors,
  setLeadContributors,
}) => {
  const { updateFieldData, register, unregister } = useFormContext()

  const leadContributorIds = useMemo(() => leadContributors.map(lc => lc.id), [leadContributors])

  useEffect(() => {
    register({ name, value: leadContributorIds, valid: true })
    return () => unregister('leadContributorIds')
  })

  useEffect(() => {
    updateFieldData({ name, value: leadContributorIds, valid: true })
  }, [leadContributorIds, updateFieldData, name])

  return (
    <>
      <LeadContributorsManager leadContributors={leadContributors} setLeadContributors={setLeadContributors} />
    </>
  )
}
