import React from 'react'
import { Opportunity } from 'lib/oleenApi'
import { t } from 'utils/i18n'
import dayjs from 'dayjs'

export const BankAppointmentDateInfo: React.FC<{ opportunity: Opportunity }> = ({ opportunity }) => (
  <div className="border border-gray-300 rounded p-2">
    <p className="text-xs text-gray-500">
      {t('opportunity_kanban.card.appointment_at', {
        date: dayjs(opportunity.bankAppointmentOn).format('DD/MM/YYYY'),
      })}
    </p>
  </div>
)
