import { Opportunity, Representative } from 'lib/oleenApi'

const MORTGAGOR_MANDATORY_FIELDS = [
  'mortgagorLastName',
  'mortgagorFirstName',
  'mortgagorEmail',
  'mortgagorAddress',
  'mortgagorBirthdate',
  'mortgagorJobTitle',
] as const

const COMORTGAGOR_MANDATORY_FIELDS = [
  'coMortgagorLastName',
  'coMortgagorFirstName',
  'coMortgagorEmail',
  'coMortgagorAddress',
  'coMortgagorBirthdate',
  'coMortgagorJobTitle',
] as const

const REPRESENTATIVE_MANDATORY_FIELDS = ['oriasId'] as const

type MandatoryOpportunityField =
  | (typeof MORTGAGOR_MANDATORY_FIELDS)[number]
  | (typeof COMORTGAGOR_MANDATORY_FIELDS)[number]

type MandatoryRepresentativeField = (typeof REPRESENTATIVE_MANDATORY_FIELDS)[number]

export type MandatoryFields = Array<MandatoryOpportunityField | MandatoryRepresentativeField>

const getMandatoryFields = (opportunity: Opportunity): MandatoryOpportunityField[] => {
  if (opportunity.hasCoMortgagor) return [...MORTGAGOR_MANDATORY_FIELDS, ...COMORTGAGOR_MANDATORY_FIELDS]

  return [...MORTGAGOR_MANDATORY_FIELDS]
}

export const validateOpportunityState = (
  opportunity: Opportunity,
  representative: Representative | null
): { isValid: boolean; missingFields: MandatoryFields } => {
  const isMissing = (v: unknown) => v === null || v === undefined || v === ''

  let missingFields: MandatoryFields = getMandatoryFields(opportunity).filter(key => {
    const value = opportunity[key]
    return isMissing(value) || (value instanceof Object && Object.values(value).every(isMissing))
  })

  if (!representative) {
    return { isValid: missingFields.length === 0, missingFields }
  }

  missingFields = [
    ...missingFields,
    ...REPRESENTATIVE_MANDATORY_FIELDS.filter(key => representative[key] === null || representative[key] === ''),
  ]

  return { isValid: missingFields.length === 0, missingFields }
}
