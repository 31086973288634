import { ChevronDownIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { defaultCountries, FlagImage, parseCountry, usePhoneInput } from 'react-international-phone'

import { Dropdown, DropdownSection } from 'ui/dropdowns/dropdown'
import { CountryOption } from 'ui/inputs/countryOption'

export type PhoneInputProps = {
  id?: string
  className?: string
  value: string
  onChange: (value: string) => void
  onBlur?: () => void
}

export const PhoneInput = forwardRef<HTMLInputElement, PhoneInputProps>(
  ({ onChange, value, className, onBlur }, ref) => {
    const [isOpen, setIsOpen] = useState(false)
    const [search, setSearch] = useState('')
    const buttonRef = useRef<HTMLButtonElement>(null)
    const dropdownRef = useRef<HTMLDivElement>(null)

    const { inputValue, handlePhoneValueChange, inputRef, country, setCountry } = usePhoneInput({
      defaultCountry: 'fr',
      value,
      countries: defaultCountries,
      onChange: data => onChange(data.phone),
    })

    const filteredCountries = useMemo(
      () =>
        defaultCountries
          .filter(c => {
            const country = parseCountry(c)
            return (
              country.name.toLowerCase().includes(search.toLowerCase()) ||
              country.iso2.toLowerCase().includes(search.toLowerCase())
            )
          })
          .map(c => parseCountry(c)),
      [search]
    )

    const handleClickOutside = useCallback(
      event => {
        const clickedDropdown = dropdownRef.current && dropdownRef.current.contains(event.target as Node)
        const clickedButton = buttonRef.current && buttonRef.current.contains(event.target as Node)

        if (!clickedDropdown && !clickedButton) {
          setIsOpen(false)
        }
      },
      [dropdownRef, buttonRef]
    )

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => document.removeEventListener('mousedown', handleClickOutside)
    }, [handleClickOutside])

    const flagOptions: DropdownSection = useMemo(
      () =>
        filteredCountries.map(country => ({
          type: 'text',
          value: country.iso2,
          label: country.name,
          Label: <CountryOption country={country} />,
          onClick: () => {
            setCountry(country.iso2)
            setIsOpen(false)
          },
        })),
      [filteredCountries, setCountry]
    )

    const searchOptions: DropdownSection = useMemo(
      () => [
        {
          type: 'search',
          value: search,
          onChange: setSearch,
        },
      ],
      [search, setSearch]
    )

    return (
      <div ref={ref} className={classNames(className, 'phoneInput max-md:phoneInput--medium md:phoneInput--small')}>
        <button type="button" ref={buttonRef} onClick={() => setIsOpen(!isOpen)}>
          <FlagImage iso2={country.iso2} />
          <ChevronDownIcon className={classNames({ 'rotate-180': isOpen })} />
        </button>
        <input ref={inputRef} value={inputValue} onChange={handlePhoneValueChange} onBlur={onBlur} />
        <Dropdown ref={dropdownRef} className="w-full" isVisible={isOpen} options={[searchOptions, flagOptions]} />
      </div>
    )
  }
)
PhoneInput.displayName = 'PhoneInput'
