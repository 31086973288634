import React from 'react'
import { Card } from 'ui/sections/card'
import { useForm } from '@tanstack/react-form'
import { TextInput } from 'ui/inputs/textInput'
import { PhoneInput } from 'ui/inputs/phoneInput'
import { Button } from 'ui/buttons/button'
import { CheckBoxToggle } from 'ui/buttons/checkBoxToggle'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { getNewMortgageOpportunityMutation } from 'helpers/queries'
import { NewOpportunity } from 'lib/oleenApi'
import { zodValidator } from '@tanstack/zod-form-adapter'
import { z } from 'utils/zod'
import { textInputProps } from 'helpers/tanstackForm'
import { TanstackFormInput } from 'ui/misc/tanstackFormInput'
import { useNavigate } from 'react-router-dom'
import { t } from 'utils/i18n'
import { toast } from 'helpers/toast'

export const NewOpportunityForm: React.FC = () => {
  const [hasCoMortgagor, setHasCoMortgagor] = React.useState(false)
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const newMortgageOpportunityMutation = useMutation(
    getNewMortgageOpportunityMutation({
      queryClient,
      onSuccess: id => {
        toast.success(t('opportunities.new.form.toast.success'))
        navigate(`/opportunities/${id}`)
      },
    })
  )

  const { Field, Subscribe, handleSubmit } = useForm({
    defaultValues: {
      mortgagorFirstName: '',
      mortgagorLastName: '',
      mortgagorEmail: '',
      mortgagorPhone: '',
      coMortgagorFirstName: '',
      coMortgagorLastName: '',
      coMortgagorEmail: '',
      coMortgagorPhone: '',
      propertyPrice: '',
    },
    // @ts-expect-error zodValidator types don't seem to fit the useForm API anymore
    validatorAdapter: zodValidator(),
    onSubmit: ({ value }) => {
      const payload: NewOpportunity = {
        opportunity: {
          project: {
            projectKind: 'purchase',
            propertyPrice: Number(value.propertyPrice),
            mortgagors: hasCoMortgagor
              ? [
                  {
                    firstName: value.mortgagorFirstName,
                    lastName: value.mortgagorLastName,
                    phone: value.mortgagorPhone,
                    email: value.mortgagorEmail,
                  },
                  {
                    firstName: value.coMortgagorFirstName,
                    lastName: value.coMortgagorLastName,
                    phone: value.coMortgagorPhone,
                    email: value.coMortgagorEmail,
                  },
                ]
              : [
                  {
                    firstName: value.mortgagorFirstName,
                    lastName: value.mortgagorLastName,
                    phone: value.mortgagorPhone,
                    email: value.mortgagorEmail,
                  },
                ],
          },
        },
      }

      newMortgageOpportunityMutation.mutate(payload)
    },
  })

  return (
    <form
      className="flex flex-1 flex-col gap-y-6 pt-6 antialiased overflow-y-scroll pb-10 md:pb-6 px-6"
      onSubmit={e => {
        e.preventDefault()
        handleSubmit()
      }}
    >
      <Card title={t('opportunities.new.form.sections.contact_information.title')}>
        <div className="card--content flex flex-col gap-2">
          <h3 className="uppercase text-gray-500 text-xs font-semibold">{t('common.mortgagor')}</h3>
          <Field name="mortgagorFirstName" validators={{ onBlur: z.string().min(1) }}>
            {field => (
              <TanstackFormInput
                className="formInput--responsive"
                label={t('opportunities.new.form.sections.contact_information.fields.firstName.label')}
                field={field}
              >
                <TextInput
                  placeholder={t('opportunities.new.form.sections.contact_information.fields.firstName.placeholder')}
                  {...textInputProps(field)}
                />
              </TanstackFormInput>
            )}
          </Field>
          <Field name="mortgagorLastName" validators={{ onBlur: z.string().min(1) }}>
            {field => (
              <TanstackFormInput
                className="formInput--responsive"
                label={t('opportunities.new.form.sections.contact_information.fields.lastName.label')}
                field={field}
              >
                <TextInput
                  placeholder={t('opportunities.new.form.sections.contact_information.fields.lastName.placeholder')}
                  {...textInputProps(field)}
                />
              </TanstackFormInput>
            )}
          </Field>
          <Field
            name="mortgagorEmail"
            validators={{
              onBlur: z.string().email(),
            }}
          >
            {field => (
              <TanstackFormInput
                className="formInput--responsive"
                label={t('opportunities.new.form.sections.contact_information.fields.email.label')}
                field={field}
              >
                <TextInput
                  placeholder={t('opportunities.new.form.sections.contact_information.fields.email.placeholder')}
                  {...textInputProps(field)}
                />
              </TanstackFormInput>
            )}
          </Field>

          <Field name="mortgagorPhone">
            {field => (
              <TanstackFormInput
                className="formInput--responsive"
                label={t('opportunities.new.form.sections.contact_information.fields.phone.label')}
                field={field}
              >
                <PhoneInput
                  id={field.name}
                  value={field.state.value}
                  onChange={field.handleChange}
                  onBlur={field.handleBlur}
                />
              </TanstackFormInput>
            )}
          </Field>
          <CheckBoxToggle
            value={hasCoMortgagor}
            className="place-self-end"
            label={t('opportunities.new.form.sections.contact_information.toggle.label')}
            onChange={setHasCoMortgagor}
          />
        </div>
        {hasCoMortgagor && (
          <div className="card--content flex flex-col gap-2">
            <h3 className="uppercase text-gray-500 text-xs font-semibold">{t('common.co_mortgagor')}</h3>
            <Field name="coMortgagorFirstName" validators={{ onBlur: z.string().min(1) }}>
              {field => (
                <TanstackFormInput
                  className="formInput--responsive"
                  label={t('opportunities.new.form.sections.contact_information.fields.firstName.label')}
                  field={field}
                >
                  <TextInput
                    placeholder={t('opportunities.new.form.sections.contact_information.fields.firstName.placeholder')}
                    {...textInputProps(field)}
                  />
                </TanstackFormInput>
              )}
            </Field>
            <Field name="coMortgagorLastName" validators={{ onBlur: z.string().min(1) }}>
              {field => (
                <TanstackFormInput
                  className="formInput--responsive"
                  label={t('opportunities.new.form.sections.contact_information.fields.lastName.label')}
                  field={field}
                >
                  <TextInput
                    placeholder={t('opportunities.new.form.sections.contact_information.fields.lastName.placeholder')}
                    {...textInputProps(field)}
                  />
                </TanstackFormInput>
              )}
            </Field>
            <Field
              name="coMortgagorEmail"
              validators={{
                onBlur: z.string().email(),
              }}
            >
              {field => (
                <TanstackFormInput
                  className="formInput--responsive"
                  label={t('opportunities.new.form.sections.contact_information.fields.email.label')}
                  field={field}
                >
                  <TextInput
                    placeholder={t('opportunities.new.form.sections.contact_information.fields.email.placeholder')}
                    {...textInputProps(field)}
                  />
                </TanstackFormInput>
              )}
            </Field>

            <Field name="coMortgagorPhone">
              {field => (
                <TanstackFormInput
                  className="formInput--responsive"
                  label={t('opportunities.new.form.sections.contact_information.fields.phone.label')}
                  field={field}
                >
                  <PhoneInput
                    id={field.name}
                    value={field.state.value}
                    onChange={field.handleChange}
                    onBlur={field.handleBlur}
                  />
                </TanstackFormInput>
              )}
            </Field>
          </div>
        )}
      </Card>
      <Card title={'Projet'}>
        <div className="card--content">
          <Field name="propertyPrice" validators={{ onBlur: z.coerce.number().nonnegative().nullable() }}>
            {field => (
              <TanstackFormInput
                className="formInput--responsive"
                label={t('opportunities.new.form.sections.project.fields.property_price.label')}
                field={field}
              >
                <TextInput
                  placeholder={t('opportunities.new.form.sections.project.fields.property_price.placeholder')}
                  {...textInputProps(field)}
                />
              </TanstackFormInput>
            )}
          </Field>
        </div>
      </Card>
      <Subscribe selector={state => [state.canSubmit, state.isSubmitting]}>
        {([canSubmit, isSubmitting]) => (
          <div className="flex gap-4 justify-end">
            <Button className="button--secondary button--outline button--small">
              {t('opportunities.new.form.buttons.cancel')}
            </Button>
            <Button
              isLoading={newMortgageOpportunityMutation.isPending}
              className="button--primary button--solid button--small"
              disabled={!canSubmit || isSubmitting}
              onClick={handleSubmit}
            >
              {t('opportunities.new.form.buttons.submit')}
            </Button>
          </div>
        )}
      </Subscribe>
    </form>
  )
}
