import { ArrowTopRightOnSquareIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { LabelledCardVerticalBody as VerticalBody } from 'ui/legacy/molecules/card/card.styled'
import ProgressBar from 'ui/progressBar/progressBar'
import { Opportunity } from 'lib/oleenApi'
import { CLICKS } from 'helpers/tracking'

interface DocumentsCardCompletenessRowProps {
  opportunity: Opportunity
}

export const DocumentsCardCompletenessRow: React.FC<DocumentsCardCompletenessRowProps> = ({ opportunity }) => {
  const storageUrl = opportunity.storageUrl
  return (
    <VerticalBody>
      <div className="flex items-center gap-x-6">
        <div>
          <span className="uppercase text-gray-500 font-semibold text-xs leading-6 antialiased truncate">
            {t('opportunity.opportunity_docsCompletion')}
          </span>
          {opportunity.docsCompletionLastUpdateDate && (
            <div>{t('opportunity.last_upload_date', { date: opportunity.docsCompletionLastUpdateDate })}</div>
          )}
        </div>
        <ProgressBar value={opportunity.docsCompletion} />
        {storageUrl && (
          <Button
            className="button--outline button--secondary button--small"
            tracking={[CLICKS.opportunityOpenStorage]}
            onClick={() => window.open(storageUrl, '_blank')}
          >
            {storageUrl.includes('pretto.ent.box.com') ? t('opportunity.box') : t('opportunity.drive')}
            <ArrowTopRightOnSquareIcon />
          </Button>
        )}
      </div>
    </VerticalBody>
  )
}
