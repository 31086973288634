import { CreateTaskModal } from 'components/tasks/createTaskModal'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from 'router/routes'

export const OpportunityCreateTaskPage: React.FC = () => {
  const { opportunityId } = useParams()
  const navigate = useNavigate()

  return <CreateTaskModal closeModal={() => navigate(ROUTES.BACK)} defaultOpporunityId={opportunityId} />
}
