export const EMAIL_TYPE_PROMPTS: Record<string, string> = {
  askForAMeeting: `Tu rédiges pour {prenom_courtier} {nom_courtier}, courtier en crédit immobilier, un email destiné à un client.
  {prenom_courtier} {nom_courtier} souhaite proposer un premier RDV à son client. Ce RDV aura pour objectif de faire le point sur son projet, d'évaluer sa faisabilité, son budget et le taux auquel le client peut prétendre. Le courtier pourra remettre au client, une attestation de financement lui permettant de défendre son projet immobilier auprès des vendeurs et des acheteurs.
  Rédige un email en français proposant un RDV à ce client et lui demandant des créneaux de disponbilité. N'invente pas d'information et ne propose pas de créneau.
  Ajoute seulement le {prenom_courtier}, le {nom_courtier}, le {telephone_courtier} et la {marque_courtier} en signature. N'utilise que du format texte, pas de markdown.
`,

  meetingRecap: `Tu rédiges pour {prenom_courtier} {nom_courtier}, courtier en crédit immobilier, un email destiné à un client.
  {prenom_courtier} {nom_courtier} a reçu le client en RDV et réalisé une première simulation. Il est en train de constituer le dossier de crédit de ce client. La complétude actuelle des documents est détaillée dans le champ {avancementDuDossierEnPourcentage}. Le taux d'intérêt est à ce jour seulement une première estimation.

  Rédige un email en français faisant un récapitulatif complet et chiffré du projet de crédit à ce stade et demandant au client de se connecter sur https://dossier.finspot.co/ pour accéder à son dossier de crédit. Il peut facilement y mettre à jour ses informations et envoyer ses documents. Procéder de cette manière est plus sécurisé que d'envoyer les documents par email.
  Ajoute seulement le {prenom_courtier}, le {nom_courtier}, le {telephone_courtier} et la {marque_courtier} en signature. N'utilise que du format texte, pas de markdown.
`,

  askForDocs: `Tu rédiges pour {prenom_courtier} {nom_courtier}, courtier en crédit immobilier, un email destiné à un client.
{prenom_courtier} {nom_courtier} est en train de constituer le dossier de crédit de ce client. Sa complétude actuelle est détaillée dans le champ {avancementDuDossierEnPourcentage} et le dernier envoi de document date de {dernièreMiseÀJourDuDossier} (null s'il n'y a eu aucun envoi). IL est important que le client complète ses documents pour permettre au courtier de faire son analyse et d'anticiper son envoi en banque.
Rédige un email en français demandant au client de se connecter sur https://dossier.finspot.co/ pour accéder à son dossier de crédit. Il peut facilement y mettre à jour ses informations et envoyer ses documents. Procéder de cette manière est plus sécurisé que d'envoyer les documents par email.
Ajoute seulement le {prenom_courtier}, le {nom_courtier}, le {telephone_courtier} et la {marque_courtier} en signature. N'utilise que du format texte, pas de markdown.
`,

  searchUpdate: `Tu rédiges pour {prenom_courtier} {nom_courtier}, courtier en crédit immobilier, un email destiné à un client.
  {prenom_courtier} {nom_courtier} souhaite connaitre l'avancée du projet immobilier du client, qui selon ses dernières informations est à l'étape du champ {avancementDuProjet}. Ils se connaissent déjà.
  Rédige un email en français reprenant quelques informations sur le projet pour montrer que tu le connais, et demandant des nouvelles à l'emprunteur. Propose lui d'anticiper la préparation de son dossier de crédit et l'envoi de documents en se connectant sur https://dossier.finspot.co/.
  Ajoute seulement le {prenom_courtier}, le {nom_courtier}, le {telephone_courtier} et la {marque_courtier} en signature. N'utilise que du format texte, pas de markdown.
`,

  askForSponsorship: `Tu rédiges pour {prenom_courtier} {nom_courtier}, courtier en crédit immobilier, un email destiné à un client.
{prenom_courtier} {nom_courtier} est en train d'accompagner ce client, ou l'a accompagné par le passé. Ils se connaissent déjà. Si le dossier est en fonds débloqués, cela signifie que le projet d'achat s'est terminé avec succès.
Rédige un email en français demandant au client si parmi ses proches ou ses collègues il connait des personnes qui font un projet immobilier. Si c'est le cas, demande ses coordonnées pour que le courtier puisse les contacter de sa part et leur proposer son accompagnement. Remercie par avance le client.
Ajoute seulement le {prenom_courtier}, le {nom_courtier}, le {telephone_courtier} et la {marque_courtier} en signature. N'utilise que du format texte, pas de markdown.
`,

  insurance: `Tu rédiges pour {prenom_courtier} {nom_courtier}, courtier en crédit immobilier, un email destiné à un client. {prenom_courtier} {nom_courtier} a accompagné le projet immobilier de ce client, mais n'a pas conclu avec lui le financement. Rédige un email en français demandant des nouvelles à l'emprunteur, et lui proposant de l'accompagner dans le changement de son assurance emprunteur rendu possible à tout moment par la loi Lemoine en 2022. Le client pourra ainsi faire des économies de plusieurs milliers d'euros tout en étant mieux couvert par l'assurance emprunteur en cas de coup dur.
  Ajoute seulement le {prenom_courtier}, le {nom_courtier}, le {telephone_courtier} et la {marque_courtier} en signature. N'utilise que du format texte, pas de markdown.
`,
}

export const EMAIL_TYPE_BY_OPPORTUNITY_STATUS = {
  'À joindre': 'askForAMeeting',
  'Relance docs': 'askForDocs',
  'En montage': 'askForDocs',
  'Envoyé banque': 'askForDocs',
  'Accord banque': 'askForSponsorship',
  'Accord client': 'askForSponsorship',
  'RDV banque planifié': 'askForSponsorship',
  'Offre éditée': 'askForSponsorship',
  'Offre signée': 'askForSponsorship',
  'Fonds débloqués': 'askForSponsorship',
  Perdu: 'insurance',
}

export const EMAIL_STYLE_PROMPTS: Record<string, string> = {
  formal: 'Sois formel (vouvoiement)',
  informal: 'Sois informel (tutoiement)',
}

type GetPromptProps = {
  emailType: string
  emailStyle: string
  data: object
  userPrompt?: string | null
}

export const getPrompt = ({ emailType, emailStyle, data, userPrompt = '' }: GetPromptProps) => {
  const mainPrompt = EMAIL_TYPE_PROMPTS[emailType]
  const stylePrompt = EMAIL_STYLE_PROMPTS[emailStyle]
  const dataPrompt = `Pour rédiger cet email, appuie toi sur les données suivantes : ${JSON.stringify(data)}`
  const subjectPrompt =
    "La première ligne de ton résultat doit être `Objet : [objet concis de l'email]` puis 2 retours à la ligne."
  return `${mainPrompt}. ${userPrompt}. ${stylePrompt}. ${dataPrompt}. ${subjectPrompt}`
}
