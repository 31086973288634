import { CreateLeadContributorModal } from 'components/leadContributors/createLeadContributorModal'
import { useLeadContributors } from 'contexts/leadContributors'
import { errorHandlerMode } from 'helpers/errorHandler'
import { EMPTY_SIGN, formatFullName } from 'helpers/format'
import { OPPORTUNITY_FIELDS, OPPORTUNITY_SOURCE_OPTIONS } from 'helpers/opportunity'
import React, { useMemo } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { FormInput } from 'ui/forms/formInput'
import { useModal } from 'ui/legacy/atoms/modals/modal.hook'
import { LabelledCardVerticalBody } from 'ui/legacy/molecules/card/card.styled'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { PlusIcon } from '@heroicons/react/20/solid'
import { DisabledSourceType, Opportunity } from 'lib/oleenApi'
import { CLICKS } from 'helpers/tracking'

interface ISourceCardProps {
  opportunity: Opportunity
}

export const SourceCard: React.FC<ISourceCardProps> = ({ opportunity }) => {
  const { leadContributors } = useLeadContributors()
  const createLeadContributorModalProps = useModal()

  const isSourceTypeDisabled = useMemo(() => {
    const disabledSourceTypes: string[] = Object.values(DisabledSourceType)
    return opportunity.sourceType && disabledSourceTypes.includes(opportunity.sourceType)
  }, [opportunity.sourceType])

  const isLeadContributorDisabled = useMemo(() => {
    return isSourceTypeDisabled || opportunity.yuccanleadId !== null
  }, [isSourceTypeDisabled, opportunity.sourceType])

  return (
    <>
      <LabelledCard icon={CARD_ICON.UsersIcon} title={t('opportunity.source_title')}>
        <LabelledCardVerticalBody>
          <div className="space-y-3 md:min-h-[112px]">
            <FormInput
              type="select"
              selectClassName="selectInput--small"
              dropdownClassName="w-full z-10"
              name={OPPORTUNITY_FIELDS.SOURCE_TYPE}
              defaultValue={opportunity.sourceType}
              options={[OPPORTUNITY_SOURCE_OPTIONS]}
              disabled={isSourceTypeDisabled ?? false}
            />
            {!isSourceTypeDisabled && (
              <>
                {leadContributors.length > 0 ? (
                  <FormInput
                    type="select"
                    selectClassName="selectInput--small"
                    buttonClassName="text-ellipsis"
                    dropdownClassName="w-full z-10"
                    name={OPPORTUNITY_FIELDS.SOURCE_LEAD_CONTRIBUTOR}
                    options={[
                      [
                        {
                          type: 'text',
                          value: null,
                          label: t('opportunity.source_type.no_lead_contributor'),
                        },
                        ...leadContributors.map(leadContributor => ({
                          type: 'text' as const,
                          value: leadContributor.id,
                          label: formatFullName(leadContributor.firstName, leadContributor.lastName, EMPTY_SIGN),
                        })),
                      ],
                    ]}
                    isSearchable={leadContributors.length > 10}
                    defaultValue={opportunity.leadContributorId}
                    disabled={isLeadContributorDisabled}
                  />
                ) : (
                  <>{t('opportunity.source_type.no_lead_contributors')}</>
                )}
                {opportunity.yuccanleadId === null && (
                  <>
                    <Button
                      className="button--outline button--primary button--small"
                      tracking={[CLICKS.opportunityAddLeadContributor]}
                      onClick={createLeadContributorModalProps.openModal}
                    >
                      <PlusIcon className="w-4 h-4" />
                      {t('opportunity.source_type.create_lead_contributor')}
                    </Button>
                    <FormInput
                      name={OPPORTUNITY_FIELDS.SOURCE_AMOUNT}
                      textInputClassName="textInput--small"
                      label={t('opportunity.source_type.lead_contributor_fees')}
                      validations={[{ type: errorHandlerMode.NON_NULLABLE }, { type: errorHandlerMode.NUMBER }]}
                      defaultValue={opportunity.sourceAmountPaid?.toString()}
                      disabled={!opportunity.projectId}
                    />
                  </>
                )}
              </>
            )}
          </div>
        </LabelledCardVerticalBody>
      </LabelledCard>
      <CreateLeadContributorModal {...createLeadContributorModalProps} />
    </>
  )
}
