import { CalendarIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { Badge } from 'ui/badges/badge'

type TasksBadgeProps = {
  tasksCount: number
}

export const TasksBadge: React.FC<TasksBadgeProps> = ({ tasksCount }) => {
  return (
    <Badge className="flex-none badge--orange">
      <CalendarIcon /> {tasksCount}
    </Badge>
  )
}
