import { Representative } from 'lib/oleenApi'
import React from 'react'
import { Card } from 'ui/sections/card'
import { t } from 'utils/i18n'

type InformationsCardProps = {
  representative: Representative
}

export const InformationsCard: React.FC<InformationsCardProps> = ({ representative }) => {
  const items = [
    { label: 'account.address', data: representative.address },
    { label: 'account.brandName', data: representative.brandName },
    { label: 'account.companyName', data: representative.companyName },
    { label: 'account.firstName', data: representative.firstName },
    { label: 'account.lastName', data: representative.lastName },
    { label: 'account.oriasId', data: representative.oriasId },
    { label: 'account.email', data: representative.email },
  ] as const

  return (
    <Card title={t('account.my_information')}>
      <div className="card--content">
        {items.map((item, index) => {
          return (
            <div key={index} className="flex justify-between py-2">
              <p className="antialiased text-xs">{t(item.label)}</p>
              <p className="antialiased text-xs font-semibold">{item.data}</p>
            </div>
          )
        })}
      </div>
    </Card>
  )
}
