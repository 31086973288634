import { DuplicateModal } from 'components/opportunity/molecules/duplicateModal'
import { useLeadContributors } from 'contexts/leadContributors'
import { trackEvent, TRACKING_EVENT } from 'helpers/segment'
import OpportunityDetails from 'pages/opportunity/components/opportunityDetails'
import OpportunityHeader from 'pages/opportunity/components/opportunityHeader'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'

import { useFormContext } from 'ui/forms/form'
import { Loader } from 'ui/legacy/atoms/loader/loader'
import { useModal } from 'ui/legacy/atoms/modals/modal.hook'
import { SaveBar } from 'ui/legacy/atoms/saveBar/saveBar'
import { SAVEBAR_SCREEN } from 'ui/legacy/atoms/saveBar/saveBar.styled'
import { useCableContext } from 'contexts/cable'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { mortgageOpportunityQuery } from 'helpers/queries'
import { Slider } from 'ui/misc/slider'
import { BOS_STATUSES_START_INDEX, OPPORTUNITY_FIELDS } from 'helpers/opportunity'
import { OleenApi, OPPORTUNITY_STATUS, UpdatedOpportunity } from 'lib/oleenApi'
import { BOSOpportunityModal } from 'components/opportunity/bosOpportunityModal/bosOpportunityModal'
import { CLICKS, track } from 'helpers/tracking'

interface OpportunityProps {
  opportunityId: string
}

const Opportunity: React.FC<OpportunityProps> = ({ opportunityId }) => {
  const navigate = useNavigate()
  const { refreshLeadContributors } = useLeadContributors()
  const { getFormData, isFormValid, hasChanges, resetForm } = useFormContext()
  const { subscribeOpportunityChannel } = useCableContext()
  const { watchField } = useFormContext()
  const [isOpenBOSModal, setOpenBOSModal] = useState(false)
  const queryClient = useQueryClient()

  const opportunityQuery = useQuery(mortgageOpportunityQuery(opportunityId))
  const updateOpportunityMutation = useMutation({
    mutationFn: (updatedOpportunity: UpdatedOpportunity & { id: string }) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      const { id, ...params } = updatedOpportunity
      return client.mortgageOpportunities().update(id, params)
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', opportunityId] })
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunities'] })
    },
  })

  const [formError] = useState<string | null>(null)

  const { isOpen: isDuplicateOpen, openModal: openDuplicate, closeModal: closeDuplicate } = useModal()

  useEffect(() => {
    // TODO: centralize query key management to facilitate invalidation
    subscribeOpportunityChannel(opportunityId, () =>
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', opportunityId] })
    )
  }, [subscribeOpportunityChannel, queryClient, opportunityId])

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    setIsOpen(true)
  }, [])

  const onClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  const onTransitionEnd = useCallback(() => {
    if (!isOpen) {
      navigate(ROUTES.OPPORTUNITIES)
    }
  }, [navigate, isOpen])

  const onSubmit = useCallback(async () => {
    const payload = {
      id: opportunityId,
      // Opportunity update is a PUT meaning the payload should contain all the
      // fields.  This field is only accessible via an interruptive modal and
      // not directly on the form.  We should probably add hidden fields to
      // work around this.
      bos_files_uploaded: opportunityQuery.data?.bosFilesUploaded,
      ...getFormData(),
    } as UpdatedOpportunity & { id: string }

    return updateOpportunityMutation
      .mutateAsync(payload)
      .then(() => {
        trackEvent(TRACKING_EVENT.OPPORTUNITY.EDITED)
        resetForm()

        // Opportunity source may change after editing it. Lead contributors should
        // be refreshed so that `current_opportunity¸count` is accurate.
        refreshLeadContributors()
        return true
      })
      .catch(() => {
        resetForm()
        return false
      })
  }, [
    opportunityQuery.data?.bosFilesUploaded,
    getFormData,
    updateOpportunityMutation,
    opportunityId,
    resetForm,
    refreshLeadContributors,
  ])

  const bankAppointmentOnRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    watchField(OPPORTUNITY_FIELDS.STATUS, value => {
      const bosStatuses = Object.values(OPPORTUNITY_STATUS).slice(BOS_STATUSES_START_INDEX)
      if (opportunityQuery.data && bosStatuses.includes(value) && !bosStatuses.includes(opportunityQuery.data.status)) {
        track(CLICKS.openBOSModal)
        setOpenBOSModal(true)
      }
    })
  }, [navigate, opportunityQuery.data, watchField])

  return (
    <>
      <BOSOpportunityModal
        isOpen={isOpenBOSModal}
        closeModal={() => setOpenBOSModal(false)}
        opportunityId={opportunityId}
      />
      <Slider isOpen={isOpen} onClose={onClose} onTransitionEnd={onTransitionEnd}>
        {opportunityQuery.isPending || !opportunityQuery.data ? (
          <Loader />
        ) : (
          <>
            <OpportunityHeader opportunity={opportunityQuery.data} onClose={onClose} />

            <OpportunityDetails
              opportunity={opportunityQuery.data}
              openMandateModal={() => navigate(ROUTES.OPPORTUNITY_NEW_MANDATE)}
              openDuplicateModal={openDuplicate}
              bankAppointmentOnRef={bankAppointmentOnRef}
            />
            <SaveBar
              shown={!formError && isFormValid && hasChanges}
              submit={onSubmit}
              screen={SAVEBAR_SCREEN.opportunity}
              error={formError}
            />
            <DuplicateModal
              isOpen={isDuplicateOpen}
              closeModal={closeDuplicate}
              closeOpportunity={onClose}
              opportunityId={opportunityId}
            />
          </>
        )}
      </Slider>
    </>
  )
}

export default Opportunity
