import { UseFieldProps } from 'ui/forms/form.hook'

export const EMPTY_FIELD_VALUE = ''

export interface IFieldProps extends UseFieldProps {
  label?: string
  placeholder?: string
  disabled?: boolean
  hidden?: boolean
}

export interface FieldErrorState {
  valid: boolean
  message: string | null
}
