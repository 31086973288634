import { ArrowDownTrayIcon } from '@heroicons/react/24/solid'
import { FeasabilityConfig, Opportunity } from 'lib/oleenApi'
import {
  EMPTY_SIGN,
  formatAdressLine1,
  formatAdressLine2,
  formatAmount,
  formatDate,
  formatFullName,
  formatNumber,
  formatPercentage,
} from 'helpers/format'
import { CertificateItem, CertificateType } from 'types/certificate'
import { t } from 'utils/i18n'

import { ICheckbox } from 'ui/legacy/atoms/checkbox/checkbox.types'
import { CardDataRowProps } from 'ui/legacy/molecules/card/card.types'

export const CERTIFICATES: Array<CertificateItem> = [
  {
    label: t('certificates.feasability'),
    certificateType: CertificateType.Feasability,
    hasModal: true,
  },
  {
    label: t('certificates.simulation'),
    certificateType: CertificateType.Simulation,
    Icon: ArrowDownTrayIcon,
  },
  {
    label: t('certificates.deposit'),
    certificateType: CertificateType.Deposit,
    Icon: ArrowDownTrayIcon,
  },
]

export enum MODAL_FINANCING_FIELDS {
  FINANCING_DURATION = 'enable_financing_duration',
  FINANCING_MONTHLY_PAYMENT = 'enable_financing_monthly_payment',
  FINANCING_RATE = 'enable_financing_rate',
  FINANCING_BRIDGE = 'enable_financing_bridge',
}

export type FeasabilityCertificateForm = {
  [MODAL_FINANCING_FIELDS.FINANCING_DURATION]: boolean
  [MODAL_FINANCING_FIELDS.FINANCING_MONTHLY_PAYMENT]: boolean
  [MODAL_FINANCING_FIELDS.FINANCING_RATE]: boolean
  [MODAL_FINANCING_FIELDS.FINANCING_BRIDGE]: boolean
}

const MODAL_FINANCING_LOAN_DURATION_OPTION: ICheckbox = {
  label: t('opportunity.feasability_modal.financing_duration'),
  name: MODAL_FINANCING_FIELDS.FINANCING_DURATION,
  checked: true,
}

const MODAL_FINANCING_MONTHLY_PAYMENT_OPTION: ICheckbox = {
  label: t('opportunity.feasability_modal.financing_monthly_payment'),
  name: MODAL_FINANCING_FIELDS.FINANCING_MONTHLY_PAYMENT,
  checked: true,
}

const MODAL_FINANCING_RATE_OPTION: ICheckbox = {
  label: t('opportunity.feasability_modal.financing_rate'),
  name: MODAL_FINANCING_FIELDS.FINANCING_RATE,
  checked: true,
}

const MODAL_FINANCING_BRIDGE_OPTION: ICheckbox = {
  label: t('opportunity.feasability_modal.financing_bridge'),
  name: MODAL_FINANCING_FIELDS.FINANCING_BRIDGE,
  checked: true,
}

export const parseFeasabilityCertificatePayload = (formData: FeasabilityCertificateForm): FeasabilityConfig => {
  const keys = Object.keys(formData)
  const payload = {
    disable_financing_duration: false,
    disable_financing_monthly_payment: false,
    disable_financing_bridge_value: false,
    disable_financing_indebtedness_rate: false,
  }

  for (let i = 0; i < keys.length; i++) {
    switch (keys[i]) {
      case MODAL_FINANCING_FIELDS.FINANCING_DURATION: {
        payload.disable_financing_duration = !formData[MODAL_FINANCING_FIELDS.FINANCING_DURATION]
        break
      }
      case MODAL_FINANCING_FIELDS.FINANCING_MONTHLY_PAYMENT: {
        payload.disable_financing_monthly_payment = !formData[MODAL_FINANCING_FIELDS.FINANCING_MONTHLY_PAYMENT]
        break
      }
      case MODAL_FINANCING_FIELDS.FINANCING_RATE: {
        payload.disable_financing_indebtedness_rate = !formData[MODAL_FINANCING_FIELDS.FINANCING_RATE]
        break
      }
      case MODAL_FINANCING_FIELDS.FINANCING_BRIDGE: {
        payload.disable_financing_bridge_value = !formData[MODAL_FINANCING_FIELDS.FINANCING_BRIDGE]
        break
      }
      default:
        break
    }
  }

  return payload
}

export const getModalFinancingFields = (opportunity: Opportunity) => [
  {
    selectOption: MODAL_FINANCING_LOAN_DURATION_OPTION,
    data: opportunity.loanDuration
      ? t('format_number_month', { number: formatNumber(opportunity.loanDuration, 0) })
      : EMPTY_SIGN,
  },
  { selectOption: MODAL_FINANCING_MONTHLY_PAYMENT_OPTION, data: formatAmount(opportunity.monthlyLoanRepayment) },
  { selectOption: MODAL_FINANCING_RATE_OPTION, data: formatPercentage(opportunity.indebtednessRate) },
  { selectOption: MODAL_FINANCING_BRIDGE_OPTION, data: formatAmount(opportunity.bridgeValue) },
]

export const getProjectRows = (opportunity: Opportunity): Array<CardDataRowProps> => {
  return [
    {
      label: t('opportunity.feasability_modal.project_price_before_fees'),
      data: formatAmount(opportunity.priceBeforeFees),
    },
    { label: t('opportunity.financing_total_fees'), data: formatAmount(opportunity.totalFees) },
    { label: t('opportunity.project_apport'), data: formatAmount(opportunity.contribution) },
    { label: t('opportunity.financing_amount_loan'), data: formatAmount(opportunity.loanAmount) },
  ]
}

export enum ConsultancyContractFormFields {
  fees = 'fees',
  template = 'template',
}

export type ConsultancyContractFormData = {
  [key in ConsultancyContractFormFields]: string | null
}

export const CONSULTANCY_MORTGAGOR_TITLES: Tuple<string, 5> = [
  t('opportunity.consultancy_modal.mortgagor_identity'),
  t('opportunity.consultancy_modal.mortgagor_birthdate'),
  t('opportunity.consultancy_modal.mortgagor_job_title'),
  t('opportunity.consultancy_modal.mortgagor_address_line1'),
  t('opportunity.consultancy_modal.mortgagor_address_line2'),
]

export const getConsultancyMortgagorInfos = (opportunity: Opportunity): typeof CONSULTANCY_MORTGAGOR_TITLES => [
  formatFullName(opportunity.mortgagorFirstName, opportunity.mortgagorLastName),
  formatDate(opportunity.mortgagorBirthdate),
  opportunity.mortgagorJobTitle || EMPTY_SIGN,
  formatAdressLine1(opportunity.mortgagorAddress),
  formatAdressLine2(opportunity.mortgagorAddress),
]

export const getConsultancyCoMortgagorInfos = (opportunity: Opportunity): typeof CONSULTANCY_MORTGAGOR_TITLES => [
  formatFullName(opportunity.coMortgagorFirstName, opportunity.coMortgagorLastName),
  formatDate(opportunity.coMortgagorBirthdate),
  opportunity.coMortgagorJobTitle || EMPTY_SIGN,
  formatAdressLine1(opportunity.coMortgagorAddress),
  formatAdressLine2(opportunity.coMortgagorAddress),
]
