import { errorHandlerMode } from 'helpers/errorHandler'
import { formatAmount, formatNumber, formatPercentage } from 'helpers/format'
import { OPPORTUNITY_FIELDS } from 'helpers/opportunity'
import React from 'react'
import { t } from 'utils/i18n'

import { FormInput } from 'ui/forms/formInput'
import {
  LabelledCardVerticalBody as VerticalBody,
  LabelledCardVerticalItem as VerticalItem,
} from 'ui/legacy/molecules/card/card.styled'
import { CardDataRow } from 'ui/legacy/molecules/card/cardDataRow'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { Opportunity } from 'lib/oleenApi'

interface FinancingCardProps {
  opportunity: Opportunity
}

export const FinancingCard: React.FC<FinancingCardProps> = ({ opportunity }) => (
  <LabelledCard icon={CARD_ICON.HomeIcon} title={t('opportunity.financing_title')}>
    <VerticalBody>
      <CardDataRow label={t('opportunity.financing_rate')} data={formatNumber(opportunity.mainLoansRate)} />
      <CardDataRow label={t('opportunity.financing_duration')} data={formatNumber(opportunity.loanDuration, 0)} />
      <CardDataRow
        label={t('opportunity.financing_monthly_payment')}
        data={formatAmount(opportunity.monthlyLoanRepayment)}
      />
      <CardDataRow label={t('opportunity.financing_amount_loan')} data={formatAmount(opportunity.loanAmount)} />
      <CardDataRow
        label={t('opportunity.financing_indebtedness_rate')}
        data={formatPercentage(opportunity.indebtednessRate)}
      />
    </VerticalBody>
    <VerticalItem>
      <FormInput
        name={OPPORTUNITY_FIELDS.BROKERAGE_FEES}
        label={t('opportunity.brokerage_fees')}
        defaultValue={opportunity.brokerageFees.toString()}
        validations={[{ type: errorHandlerMode.NUMBER }]}
      />
    </VerticalItem>
  </LabelledCard>
)
