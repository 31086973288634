import { CardSummary } from 'components/opportunities/atoms/cardSummary'
import { KanbanHeader } from 'components/opportunities/atoms/kanbanHeader/kanbanHeader'
import { mapOpportunitiesOnStatus } from 'helpers/opportunity'
import { id } from 'helpers/ref'
import { Opportunity } from 'lib/oleenApi'
import React, { useCallback, useMemo } from 'react'

import { Kanban } from 'ui/legacy/organisms/kanban/kanban'

interface IOpportunityKanbanProps {
  opportunities: Opportunity[]
}

export const OpportunityKanban: React.FC<IOpportunityKanbanProps> = ({ opportunities }) => {
  const opportunitiesMap = useMemo(() => mapOpportunitiesOnStatus(opportunities), [opportunities])
  const renderOpportunityCard = useCallback(opportunity => <CardSummary key={id()} opportunity={opportunity} />, [])

  /**
   * Global tooltips hook to fix multiple open tooltips. Can not be inside renderHeader.
   * Some browsers crash wih the tooltips...
   * **/
  // const initialState = Object.keys(opportunitiesMap).map(status => ({ status, visible: false }))
  // const { showTooltip, dismissAllTooltips, isCurrentTooltipVisible } = useTooltips(initialState)

  const renderHeader = useCallback(status => <KanbanHeader status={status} opportunitiesMap={opportunitiesMap} />, [])

  return <Kanban dataMap={opportunitiesMap} renderDataItems={renderOpportunityCard} renderHeader={renderHeader} />
}
