import { XMarkIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

export type ModalProps = PropsWithChildren<{
  className?: string
  title?: string
  isOpen?: boolean
  onClose?: () => void
}>

export const Modal = ({ isOpen, onClose, title, className, children }: ModalProps) => {
  if (!isOpen) return null

  return (
    <>
      <div className="modal__overlay" onClick={onClose} />
      <dialog className={classNames(className, 'modal')} open={isOpen}>
        <section>
          <header>
            {title && <h1>{title}</h1>}
            <button onClick={onClose}>
              <XMarkIcon />
            </button>
          </header>
          {children}
        </section>
      </dialog>
    </>
  )
}
