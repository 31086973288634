import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid'
import React, { ReactElement, useCallback, useEffect, useState } from 'react'
import { matchPath, useLocation } from 'react-router-dom'

import { ISidebarItemProps, SidebarItem } from './sidebarItem'

interface ISidebarItemExpendable {
  Icon?: HeroIcon
  navItems: Array<ReactElement<ISidebarItemProps>>
  isOpenByDefault?: boolean
}

export const SidebarItemExpendable: React.FC<ISidebarItemExpendable> = ({
  Icon,
  navItems,
  isOpenByDefault,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(isOpenByDefault ?? false)
  const toggleIsOpen = useCallback(() => setIsOpen(prevState => !prevState), [])
  const { pathname } = useLocation()

  useEffect(() => {
    // If the current location is one of the route in the dropdown, we force its opening
    const match = navItems.find(({ props: { to } }) => matchPath(to ?? '', pathname))

    if (match) {
      setIsOpen(true)
    }
  }, [navItems, pathname])

  return (
    <div className="flex flex-col space-y-2">
      <SidebarItem
        to={''}
        highlight
        onClick={toggleIsOpen}
        Icon={Icon}
        ActionIcon={isOpen ? ChevronUpIcon : ChevronDownIcon}
      >
        {children}
      </SidebarItem>
      {isOpen && navItems}
    </div>
  )
}
