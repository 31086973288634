import React from 'react'
import { t } from 'utils/i18n'

import { PageLayout } from 'ui/layouts/pageLayout/pageLayout'

const OrgMembers: React.FC = () => (
  <PageLayout>
    <iframe className="h-full border-0" src={t('url.dashboard.orga')} />
  </PageLayout>
)

export default OrgMembers
