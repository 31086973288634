import { IContact } from 'helpers/opportunityContact'
import * as React from 'react'

export type IContactInformationProps = {
  title: string
} & IContact

const ContactInformation: React.FC<IContactInformationProps> = ({ title, name, phoneNumber, email, fullAddress }) => {
  return (
    <div className="flex-col text-xs leading-5 break-words">
      <h1 className="uppercase text-gray-500 font-semibold text-xs leading-6 antialiased truncate">{title}</h1>
      <div className="font-semibold mt-2">{name}</div>
      {fullAddress && <div>{fullAddress}</div>}
      <div>{phoneNumber}</div>
      <a href={`mailto:${email}`} className="font-semibold text-blue-700">
        {email}
      </a>
    </div>
  )
}

export default ContactInformation
