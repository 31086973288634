import { GenerateFeasabilityCertificateModal } from 'components/opportunity/molecules/generateFeasabilityCertificateModal'
import { FormProvider } from 'ui/forms/form'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { Opportunity } from 'lib/oleenApi'

export const NewFeasabilityCertificatePage: React.FC = () => {
  const { opportunityId } = useParams()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  // TODO: centralize query key management to facilitate invalidation
  const opportunity = queryClient.getQueryData(['mortgageOpportunity', opportunityId]) as Opportunity

  return (
    <>
      {opportunity && (
        <FormProvider>
          <GenerateFeasabilityCertificateModal opportunity={opportunity} closeModal={() => navigate(-1)} />
        </FormProvider>
      )}
    </>
  )
}
