import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table'
import classNames from 'classnames'
import { TaskItem } from 'components/tasks/taskItem'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'
import { Task } from 'lib/oleenApi'

import { BigCheck } from 'ui/buttons/bigCheck'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { mortgageOpportunityQuery, updateTaskCompletionQuery } from 'helpers/queries'
import { ActionsMenu } from './actionsMenu'

interface ITasksTableProps {
  tasks: Task[]
}

// FIXME: API should return taskable information to avoid querying for each task
// TODO: use suspens to display loading state
const Taskable = ({ opportunityId }: { opportunityId: string }) => {
  const { data: opportunity } = useQuery(mortgageOpportunityQuery(opportunityId))

  return (
    <>
      {t('tasks.mortgagor.fullname', {
        first_name: opportunity?.mortgagorFirstName,
        last_name: opportunity?.mortgagorLastName,
      })}
    </>
  )
}

export const TasksTable: React.FC<ITasksTableProps> = ({ tasks }) => {
  const columnHelper = createColumnHelper<Task>()
  const queryClient = useQueryClient()
  const updateTaskCompletion = useMutation({
    mutationFn: updateTaskCompletionQuery,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks', 'meta'] })
    },
  })

  // Définition des colonnes en utilisant tanstack/react-table
  const columns = useMemo(
    () => [
      columnHelper.display({
        id: 'check',
        header: t('tasks.table.header.complete'),
        cell: props => (
          <BigCheck
            value={Boolean(props.row.original.completedAt)}
            onChange={value => updateTaskCompletion.mutate({ task: props.row.original, value })}
            defaultChecked={Boolean(props.row.original.completedAt)}
          />
        ),
      }),
      columnHelper.accessor('title', {
        header: t('tasks.table.header.subject'),
        cell: props => <TaskItem task={props.row.original} />,
      }),
      columnHelper.accessor('taskableId', {
        header: t('tasks.table.header.opportunity'),
        cell: props => (
          <Link to={`${ROUTES.OPPORTUNITIES}/${props.row.original.taskableId}`}>
            <span className={classNames('select-none', { 'text-gray-400': props.row.original.completedAt })}>
              <Taskable opportunityId={props.row.original.taskableId} />
            </span>
          </Link>
        ),
      }),
      columnHelper.display({
        // Utilisez une fonction d'accès si vous n'accédez pas directement à une propriété
        id: 'actions', // Fournir un ID pour les colonnes calculées ou personnalisées
        cell: props => <ActionsMenu task={props.row.original} />,
      }),
    ],
    [updateTaskCompletion, columnHelper]
  )

  const data = React.useMemo(() => tasks, [tasks])

  const table = useReactTable({ columns, data, getCoreRowModel: getCoreRowModel() })

  return (
    <div className="flex flex-col self-stretch w-full">
      <table className="border-collapse rounded-none md:rounded-lg border-x-0 border-y md:border-x h-full border-gray-300 border-t-0 md:border-t w-full">
        <thead className="hidden md:table-header-group">
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id} className={classNames('tableHead', {})}>
                  <span>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td
                  key={cell.id}
                  className={classNames('', {
                    'border-t border-grey-300': rowIndex !== 0,
                    'tableCell tableCell--check': cell.column.id === 'check',
                    'tableCell tableCell--picto relative w-[52px]':
                      cell.column.id === 'actions' || cell.column.id === 'extraActions',
                    'tableCell tableCell--text': cell.column.id !== 'actions' && cell.column.id !== 'check',
                    'tableCell hidden md:table-cell': cell.column.id === 'taskableId',
                  })}
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}
