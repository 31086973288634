import { XMarkIcon } from '@heroicons/react/24/solid'
import React, { PropsWithChildren, ReactNode } from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { ICON_BUTTON_VARIANTS, IconButton } from 'ui/buttons/iconButton'
import classNames from 'classnames'

export enum MODAL_SIZES {
  SM = 'w-full lg:w-3/12',
  SM_FIX = 'w-full lg:max-w-[472px] lg:min-w-0', // la largeur doit être fixe, max 472px
  MD = 'w-full lg:w-4/12',
  LG = 'w-full lg:w-6/12',
  XL = 'w-full lg:w-9/12',
  XXL = 'w-full lg:w-10/12',
}

interface IModalProps {
  className?: string
  size: MODAL_SIZES
  header?: ReactNode
  footer?: ReactNode
  closeModal: () => void
  isOpen: boolean
}

export const Modal: React.FC<PropsWithChildren<IModalProps>> = ({
  className,
  size,
  header,
  footer,
  isOpen,
  closeModal,
  children,
}) => {
  return (
    <>
      <div
        className={`${isOpen ? 'block' : 'hidden'} fixed overflow-x-hidden z-50 inset-0 bg-gray-900 bg-opacity-50`}
        onClick={closeModal}
      />
      <div className={`fixed z-[10000] inset-4 ${isOpen ? 'flex' : 'hidden'} justify-center items-center`}>
        <div
          className={classNames(
            `bg-white rounded-lg shadow ${size} max-h-full overflow-y-auto z-[60] min-w-fit`,
            className
          )}
        >
          {header ? (
            <>
              <div className="flex justify-between items-center p-6 rounded-t border-b border-gray-200">
                {header}
                <IconButton variant={ICON_BUTTON_VARIANTS.GRAY} Icon={XMarkIcon} onClick={closeModal} />
              </div>
              <div className="p-6 space-y-6">{children}</div>
            </>
          ) : (
            <>
              <div className="p-6 space-y-6">
                <div className="flex flex-row-reverse shrink-0 w-full">
                  <IconButton variant={ICON_BUTTON_VARIANTS.GRAY} Icon={XMarkIcon} onClick={closeModal} />
                </div>
                {children}
              </div>
            </>
          )}
          {footer && (
            <div className="flex items-center justify-between p-6 space-x-2 rounded-b border-t border-gray-200">
              <Button className="button--outline button--secondary button--small" onClick={closeModal}>
                {t('modal_cancel')}
              </Button>
              <div className="flex gap-2">{footer}</div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
