import React from 'react'
import { MaturityBadgeEmoji } from 'helpers/emoji'
import { Badge } from 'ui/badges/badge'
import { Maturity } from 'lib/oleenApi'
import { t } from 'utils/i18n'

type MaturityBadgeProps = {
  maturity: Maturity
}

export const MaturityBadge: React.FC<MaturityBadgeProps> = ({ maturity }) => {
  return (
    <Badge className="flex-none badge--gray">
      {MaturityBadgeEmoji[maturity]} {t(`maturity.${maturity}`)}
    </Badge>
  )
}
