import { Client } from '../../client'

/**
 * Augments the 'Client' class with a method for accessing OpportunityDER services.
 */
declare module '../../client' {
  interface Client {
    /**
     * Gets an instance of the OpportunityDERService.
     * @returns OpportunityDERService instance.
     */
    opportunityDER: () => OpportunityDERService
  }
}

/**
 * Extension method to get OpportunityDERService.
 */
Client.prototype.opportunityDER = function () {
  return new OpportunityDERService(this)
}

/**
 * Service for managing OpportunityDER.
 */
interface IOpportunityDERService {
  create: (opportunityId: string) => Promise<void>
}

// NOTE: This class could be renamed CustomerEmailsService and implement a sendDER function
class OpportunityDERService implements IOpportunityDERService {
  constructor(private client: Client) {}

  /**
   * Creates a new DER and send it.
   * @returns A promise resolving to the creation result.
   */
  create(opportunityId: string) {
    // Logic to create a lead contributor
    return this.client.request<void, void, unknown>({
      url: `/mortgage/opportunities/${opportunityId}/der`,
      method: 'POST',
    })
  }
}
