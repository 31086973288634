import { MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { useForwardRef } from 'hooks/forwardedRef.hook'
import React, { forwardRef, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react'

import { Dropdown, DropdownSection } from 'ui/dropdowns/dropdown'
import { TextInput } from 'ui/inputs/textInput'

export type AutocompleteInputProps = PropsWithChildren<{
  className?: string
  textFieldClassName?: string
  dropdownClassName?: string
  items: string[]
  onChange: (value: string) => void
  onBlur?: (value?: string) => void
  placeholder?: string
  value: string
}>

const HighlightedText: React.FC<{ text: string; highlight: string }> = ({ text, highlight }) => {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <>
      {parts.map((part, i) =>
        part.toLowerCase() === highlight.toLowerCase() && part !== '' ? (
          // FIXME: add to tailwind config
          // <mark key={i} className="inline-block not-italic text-blue-600 bg-transparent">
          <mark key={i} className="">
            {part}
          </mark>
        ) : (
          <React.Fragment key={i}>{part}</React.Fragment>
        )
      )}
    </>
  )
}

export const AutocompleteInput = forwardRef<HTMLInputElement, AutocompleteInputProps>(
  ({ className, items, placeholder, textFieldClassName, dropdownClassName, value, onChange, onBlur }, ref) => {
    const [dropdownIsVisible, setDropdownIsVisible] = useState(false)
    const dropdownRef = useRef<HTMLDivElement>(null)
    const forwardRef = useForwardRef<HTMLInputElement>(ref)
    const fallbackInputRef = useRef<HTMLInputElement>(null)
    const inputRef = (ref && forwardRef) || fallbackInputRef

    const handleClickOutside = useCallback(
      (event: MouseEvent) => {
        const clickedOutside = dropdownRef.current && !dropdownRef.current.contains(event.target as Node)
        const clickedInInput = inputRef.current && inputRef.current.contains(event.target as Node)

        if (clickedOutside && !clickedInInput) {
          setDropdownIsVisible(false)
          if (onBlur) onBlur()
        }
      },
      [inputRef, dropdownRef, setDropdownIsVisible, onBlur]
    )

    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [handleClickOutside])

    const dropdownOptions: DropdownSection = items
      .filter(item => item.toLowerCase().includes(value.toLowerCase()))
      .map(item => ({
        type: 'text',
        label: item,
        Label: <HighlightedText text={item} highlight={value} />,
        value: item,
        onClick: () => {
          onChange(item)
          if (onBlur) onBlur(item)
          setDropdownIsVisible(false)
        },
      }))

    return (
      <div className={classNames('autocompleteInput', 'autocompleteInput--medium', className)}>
        <TextInput
          className={textFieldClassName}
          Icon={MagnifyingGlassIcon}
          ref={inputRef}
          placeholder={placeholder}
          onChange={onChange}
          onFocus={() => setDropdownIsVisible(true)}
          value={value}
        />
        <Dropdown
          className={dropdownClassName}
          ref={dropdownRef}
          isVisible={dropdownIsVisible}
          options={[dropdownOptions]}
        />
      </div>
    )
  }
)
AutocompleteInput.displayName = 'AutocompleteInput'
