import React from 'react'
import { Interrogation } from 'lib/oleenApi'
import { getCompetitorLabel } from 'helpers/competitors'
import { t } from 'utils/i18n'
import dayjs from 'dayjs'

export const BankAskedInfo: React.FC<{ interrogation: Interrogation }> = ({ interrogation }) => (
  <div className="border border-gray-300 rounded p-2">
    <p className="text-xs font-semibold text-gray-500">{getCompetitorLabel(interrogation.bankGroup)}</p>

    <p className="text-xs font-normal text-gray-500">
      {t('opportunity_kanban.card.sent_at', { date: dayjs(interrogation.sentAt).format('DD/MM/YYYY') })}
    </p>
  </div>
)
