import { EnvelopeIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { LabelledCardVerticalItem as VerticalItem } from 'ui/legacy/molecules/card/card.styled'
import { Opportunity } from 'lib/oleenApi'
import { CLICKS } from 'helpers/tracking'

type DocumentsCardGenerateConsultancyContractRowProps = {
  opportunity: Opportunity
  openConsultancyModal: () => void
}

export const DocumentsCardGenerateConsultancyContractRow: React.FC<
  DocumentsCardGenerateConsultancyContractRowProps
> = ({ openConsultancyModal }) => (
  <VerticalItem>
    <div className="flex justify-between items-center">
      <p className="text-sm font-bold antialiased leading-6 text-gray-800">
        {t('opportunity.send_consultancy_contract_label')}
      </p>
      <Button
        className="button--outline button--primary button--small"
        tracking={[CLICKS.opportunityConsultancyContractSend]}
        onClick={openConsultancyModal}
      >
        {t('opportunity.send')}
        <EnvelopeIcon />
      </Button>
    </div>
  </VerticalItem>
)
