import classNames from 'classnames'
import React from 'react'

interface Spinner {
  className?: string
}

export const Spinner: React.FC<Spinner> = props => {
  return (
    <span className={classNames(props.className, 'spinner')}>
      <span></span>
      <span></span>
    </span>
  )
}
