export enum HIGHLIGHTED_ICON_VARIANTS {
  RED = 'bg-red-100 text-red-600',
  YELLOW = 'bg-yellow-100 text-yellow-500',
}

export type HighlightedIconProps = {
  Icon: HeroIcon
  variant: HIGHLIGHTED_ICON_VARIANTS
  highlightIconSize?: HighlightedIconSize
}

export type HighlightedIconSize = { highlightSize: string; iconSize: string }

export const HIGHLIGHTED_ICON_SIZE = {
  SM: { highlightSize: 'min-w-[32px] h-8', iconSize: 'w-5 h-5' },
  MD: { highlightSize: 'w-8 h-8', iconSize: 'w-5 h-5' },
} as const
