import { MutableRefObject, useEffect, useRef, useState } from 'react'

export const useElementOnScreen = (options?: IntersectionObserverInit) => {
  const containerRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    let observerRefValue: MutableRefObject<null>['current'] = null

    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting)
    }, options)
    if (containerRef.current) {
      observer.observe(containerRef.current)
      observerRefValue = containerRef.current
    }
    return () => {
      if (observerRefValue) observer.unobserve(observerRefValue)
    }
  }, [containerRef, options])

  return [containerRef, isVisible] as const
}
