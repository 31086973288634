import React, { ReactNode } from 'react'

import { BOS_STATUSES_START_INDEX } from 'helpers/opportunity'
import { KanbanSeparator } from 'components/kanban/kanbanSeparator'
import { t } from 'utils/i18n'
import classNames from 'classnames'

export interface IKanbanProps<T> {
  dataMap: Record<string, T[]>
  renderDataItems: (item: T) => ReactNode
  renderHeader: (header: string) => ReactNode
}

export const Kanban: <T>(props: IKanbanProps<T> & { children?: ReactNode }) => JSX.Element = ({
  dataMap,
  renderDataItems,
  renderHeader,
}) => {
  return (
    <div className="flex grow h-full relative">
      <div className="absolute inset-0 flex gap-x-4 w-full overflow-x-auto snap-x snap-mandatory">
        {Object.entries(dataMap).map(([columnTitle, data], index) => (
          <React.Fragment key={index}>
            {index === BOS_STATUSES_START_INDEX && <KanbanSeparator label={t('opportunity.statuses.bos_separator')} />}
            <div
              className={classNames('column', {
                'column--stripped': index >= BOS_STATUSES_START_INDEX && index < Object.entries(dataMap).length - 1,
              })}
            >
              <div className="p-4">{renderHeader(columnTitle)}</div>
              <div className="max-w-full flex flex-col grow p-4 pb-4 space-y-4">{data.map(renderDataItems)}</div>
            </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  )
}
