import { MortgageFilesUploadedNotification } from 'components/notifications/mortgageFilesUploadedNotification'
import { WebsiteOpportunityCreatedNotification } from 'components/notifications/websiteOpportunityCreatedNotification'
import { formatTiming } from 'helpers/notification'
import React from 'react'
import { OpportunityContributedNotification } from './opportunityContributedNotification'
import { Notification as INotification } from 'lib/oleenApi'

type NotificationProps = {
  notification: INotification
}
export const Notification: React.FC<NotificationProps> = ({ notification }) => {
  const interacted = notification.interactedAt !== null
  const timing = formatTiming(notification.createdAt)
  return (
    <>
      {notification.type === 'Notification::WebsiteOpportunityCreated' && (
        <WebsiteOpportunityCreatedNotification
          key={notification.id}
          id={notification.id}
          opportunityId={notification.data.opportunityId}
          mortgagor={notification.data.mortgagor}
          timing={timing}
          interacted={interacted}
        />
      )}
      {notification.type === 'Notification::MortgageFilesUploaded' && (
        <MortgageFilesUploadedNotification
          key={notification.id}
          id={notification.id}
          opportunityId={notification.data.opportunityId}
          mortgagor={notification.data.mortgagor}
          files={notification.data.files}
          timing={timing}
          interacted={interacted}
        />
      )}
      {notification.type === 'Notification::OpportunityContributed' && (
        <OpportunityContributedNotification
          key={notification.id}
          id={notification.id}
          opportunityId={notification.data.opportunityId}
          mortgagor={notification.data.mortgagor}
          leadContributor={notification.data.leadContributor}
          timing={timing}
          interacted={interacted}
        />
      )}
    </>
  )
}
