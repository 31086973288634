import { DropOpportunityForm } from 'components/opportunity/dropOpportunityModal/dropOpportunityForm'
import { getCompetitors } from 'helpers/competitors'
import { MORTGAGE_OPPORTUNITY_LOSS_REASONS } from 'helpers/dropMortgageOpportunity'
import { trackEvent, TRACKING_EVENT } from 'helpers/segment'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { DropOpportunityParams, OleenApi, Opportunity } from 'lib/oleenApi'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { useFormContext } from 'ui/forms/form'
import { Modal } from 'ui/modals/modal'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { toast } from 'helpers/toast'
import { ApiError } from 'lib/oleenApi/apiError'

interface DropModalProps {
  closeModal: () => void
  opportunityId: Opportunity['id']
}

export const DropOpportunityModal: React.FC<DropModalProps> = ({ closeModal, opportunityId }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const dropOpportunityMutation = useMutation({
    mutationFn: ({ opportunityId, payload }: { opportunityId: string; payload: DropOpportunityParams }) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.mortgageOpportunities().drop(opportunityId, payload)
    },
    onSuccess: (_data, { payload }) => {
      trackEvent(TRACKING_EVENT.OPPORTUNITY.LOST)
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', opportunityId] })
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunities'] })

      const shouldSuggestInsurance =
        payload.reason === MORTGAGE_OPPORTUNITY_LOSS_REASONS.BANK_COMPETITOR ||
        payload.reason === MORTGAGE_OPPORTUNITY_LOSS_REASONS.BROKER_COMPETITOR

      if (shouldSuggestInsurance) {
        return navigate(`${ROUTES.BACK}/${ROUTES.OPPORTUNITY_INSURANCE}`)
      }

      navigate(ROUTES.BACK)
    },
    onError: error => {
      if (error instanceof ApiError && error.status === 400) {
        toast.error(t('opportunity.drop_opportunity_modal.toast.error.invalid_data'))
      } else {
        toast.error(t('opportunity.drop_opportunity_modal.toast.error.generic'))
      }
    },
  })

  const competitors = getCompetitors()

  const { getFormData, isFormValid } = useFormContext()

  const submit = useCallback(() => {
    const payload = getFormData() as DropOpportunityParams

    dropOpportunityMutation.mutate({ opportunityId, payload })
  }, [getFormData, opportunityId, dropOpportunityMutation])

  return (
    <Modal
      className="modal--form md:w-[450px]"
      isOpen={true}
      onClose={closeModal}
      title={t('opportunity.drop_opportunity_modal.title')}
    >
      <div>
        {competitors && <DropOpportunityForm competitors={competitors} />}

        <Button
          className="button--solid button--danger button--small"
          onClick={submit}
          disabled={!isFormValid}
          isLoading={dropOpportunityMutation.isPending}
        >
          {t('opportunity.drop_opportunity_modal.submit')}
        </Button>
      </div>
    </Modal>
  )
}
