import React from 'react'
import { EmailModal } from 'components/opportunity/emailModal/emailModal'
import { useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { FormProvider } from 'ui/forms/form'
import { useRepresentativeContext } from 'contexts/representative'
import { useQuery } from '@tanstack/react-query'
import { mortgageOpportunityQuery } from 'helpers/queries'

export const OpportunityEmailPage: React.FC = () => {
  const navigate = useNavigate()
  const { representative } = useRepresentativeContext()
  const { opportunityId } = useParams() as { opportunityId: string }

  const opportunity = useQuery(mortgageOpportunityQuery(opportunityId))

  if (!representative || !opportunity.data) {
    return <></>
  }

  return (
    <FormProvider>
      <EmailModal
        onClose={() => navigate(ROUTES.BACK)}
        opportunity={opportunity.data}
        representative={representative}
      />
    </FormProvider>
  )
}
