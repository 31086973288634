import { Client } from '../../client'

/**
 * Augments the 'Client' class with a method for accessing OpportunityCertificates services.
 */
declare module '../../client' {
  interface Client {
    /**
     * Gets an instance of the OpportunityCertificatesService.
     * @returns OpportunityCertificatesService instance.
     */
    opportunityCertificates: () => OpportunityCertificatesService
  }
}

/**
 * Extension method to get OpportunityCertificatesService.
 */
Client.prototype.opportunityCertificates = function () {
  return new OpportunityCertificatesService(this)
}

export type FeasabilityConfig = {
  disable_financing_duration: boolean
  disable_financing_monthly_payment: boolean
  disable_financing_indebtedness_rate: boolean
  disable_financing_bridge_value: boolean
}

/**
 * Service for managing OpportunityCertificates.
 */
interface IOpportunityCertificatesService {
  deposit: (opportunityId: string) => Promise<Blob>
  feasability: (opportunityId: string, config: FeasabilityConfig) => Promise<Blob>
  simulation: (opportunityId: string) => Promise<Blob>
}

class OpportunityCertificatesService implements IOpportunityCertificatesService {
  constructor(private client: Client) {}

  /**
   * Generates a deposit certificate.
   * @param opportunityId - The opportunity ID.
   * @returns A promise resolving to the certificate.
   */
  deposit(opportunityId: string) {
    return this.client.request<unknown, Blob, unknown>({
      url: `/opportunity/${opportunityId}/certificate/deposit`,
      method: 'POST',
      responseType: 'blob',
    })
  }

  /**
   * Generates a feasability certificate.
   * @param opportunityId - The opportunity ID.
   * @returns A promise resolving to the certificate.
   */
  feasability(opportunityId: string, config: FeasabilityConfig) {
    return this.client.request<unknown, Blob, unknown>({
      url: `/opportunity/${opportunityId}/certificate/feasability`,
      method: 'POST',
      responseType: 'blob',
      data: config,
    })
  }

  /**
   * Generates a simulation certificate.
   * @param opportunityId - The opportunity ID.
   * @returns A promise resolving to the certificate.
   */
  simulation(opportunityId: string) {
    return this.client.request<unknown, Blob, unknown>({
      url: `/opportunity/${opportunityId}/certificate/simulation`,
      method: 'POST',
      responseType: 'blob',
    })
  }
}
