import { CheckIcon, ClockIcon, ExclamationCircleIcon, NoSymbolIcon, PaperAirplaneIcon } from '@heroicons/react/24/solid'
import { getCompetitorLabel } from 'helpers/competitors'
import { getInterrogationStageTranslated } from 'helpers/opportunity'
import React from 'react'
import { Interrogation, InterrogationStage } from 'lib/oleenApi'
import { t } from 'utils/i18n'

type OpportunityInterrogationProps = {
  interrogation: Interrogation
  index: number
}

const INTERROGATION_HEADER_BADGE = [
  {
    stage: InterrogationStage.client_approved,
    color: 'badge--green',
    icon: CheckIcon,
  },
  {
    stage: InterrogationStage.bank_approved,
    color: 'badge--green',
    icon: CheckIcon,
  },
  {
    stage: InterrogationStage.refused,
    color: 'badge--red',
    icon: ExclamationCircleIcon,
  },
  {
    stage: InterrogationStage.dropped,
    color: 'badge--gray',
    icon: NoSymbolIcon,
  },
  {
    stage: InterrogationStage.sent,
    color: 'badge--green',
    icon: CheckIcon,
  },
  {
    stage: InterrogationStage.pending,
    color: 'badge--yellow',
    icon: ClockIcon,
  },
  {
    stage: InterrogationStage.sending_in_progress,
    color: 'badge--blue',
    icon: PaperAirplaneIcon,
  },
  {
    stage: InterrogationStage.sending_error,
    color: 'badge--red',
    icon: ExclamationCircleIcon,
  },
  {
    stage: InterrogationStage.approval_denied,
    color: 'badge--red',
    icon: ExclamationCircleIcon,
  },
  {
    stage: InterrogationStage.approval_pending,
    color: 'badge--yellow',
    icon: ClockIcon,
  },
]

export const OpportunityInterrogationHeader = ({ interrogation, index }: OpportunityInterrogationProps) => {
  const tag = INTERROGATION_HEADER_BADGE.find(iht => iht.stage === interrogation.stage)
  const tagColor = tag?.color || 'bg-gray-200'
  const TagIcon = tag?.icon || ClockIcon

  return (
    <div className="flex">
      <div className="text-sm font-bold transition-colors text-gray-800 uppercase">
        {t('opportunity.interrogation.title', { index, bank: getCompetitorLabel(interrogation.bankGroup) })}
      </div>
      <div
        className={`text-xs font-medium inline-flex items-center px-2.5 py-0.5 rounded ml-2
            ${tagColor}`}
      >
        <TagIcon className="mr-1 w-4 h-4" />
        {getInterrogationStageTranslated(interrogation.stage)}
      </div>
    </div>
  )
}
