import React from 'react'
import { Button } from 'ui/buttons/button'
import { FormInput } from 'ui/forms/formInput'
import { EMAIL_STYLE_OPTIONS, EMAIL_TYPE_OPTIONS } from './misc'
import { t } from 'utils/i18n'
import { CLICKS } from 'helpers/tracking'

type EmailGenerationSideProps = {
  onSubmit: () => void
  userPrompt?: string
}

export const EmailGenerationSide: React.FC<EmailGenerationSideProps> = ({ onSubmit, userPrompt }) => {
  return (
    <>
      <div>
        <p className="text-xs">{t('opportunity.emailModal.description')}</p>
        <FormInput
          label={t('opportunity.emailModal.inputs.emailType.label')}
          type="select"
          name="emailType"
          defaultValue={'askForAMeeting'}
          dropdownClassName="w-full"
          options={[EMAIL_TYPE_OPTIONS]}
        />
        <FormInput
          label={t('opportunity.emailModal.inputs.emailStyle.label')}
          type="select"
          name="emailStyle"
          dropdownClassName="w-full"
          defaultValue={'formal'}
          options={[EMAIL_STYLE_OPTIONS]}
        />
        <FormInput
          label={t('opportunity.emailModal.inputs.userPrompt.label')}
          type="textArea"
          name="userPrompt"
          defaultValue={userPrompt}
          placeholder={t('opportunity.emailModal.inputs.userPrompt.placeholder')}
        />
        <div className="flex justify-between">
          <Button className="button--medium button--secondary button--outline">{t('common.cancel')}</Button>
          <Button
            className="button--medium button--primary button--solid"
            onClick={onSubmit}
            tracking={[CLICKS.opportunityEmailModelGenerate]}
          >
            {t('opportunity.emailModal.cta.generateEmail')}
          </Button>
        </div>
      </div>
    </>
  )
}
