import { EnvelopeIcon, PhoneIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import { useBusinessPartners } from 'contexts/businessPartners'
import { EMPTY_SIGN } from 'helpers/format'
import { id } from 'helpers/ref'
import {
  LEAD_CONTRIBUTOR_BUSINESS_TYPES,
  LeadContributor,
  LeadContributorErrorAttribute,
  LeadContributorErrorType,
} from 'lib/oleenApi'
import React, { PropsWithChildren, useMemo, useState } from 'react'
import { t } from 'utils/i18n'

import { RadioGroup } from 'ui/buttons/radioGroup'
import { SelectOption } from 'ui/dropdowns/selectInput'
import { FormInput } from 'ui/forms/formInput'

export type LeadContributorFormProps = PropsWithChildren<{
  leadContributor?: LeadContributor
  error?: LeadContributorFormError
}>

enum LeadContributorFormFields {
  representativeId = 'representativeId',
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  phone = 'phone',
  jobTitle = 'jobTitle',
  businessType = 'businessType',
  businessPartnerId = 'businessPartnerId',
}

export type LeadContributorFormData = {
  [key in LeadContributorFormFields]: string | null
}

const businessTypesOptions = LEAD_CONTRIBUTOR_BUSINESS_TYPES.map(value => ({
  value,
  label: t(`business_partners.business_type.${value}`),
  type: 'text' as const,
}))

export type LeadContributorFormError = Partial<Record<LeadContributorErrorAttribute, LeadContributorErrorType>>

export const LeadContributorForm: React.FC<LeadContributorFormProps> = ({ leadContributor, error, children }) => {
  const [hasBusinessPartner, setHasBusinessPartner] = useState(!!leadContributor?.businessPartnerId)
  const { businessPartners } = useBusinessPartners()

  const defaultBusinessPartnerId = useMemo(() => {
    if (!hasBusinessPartner) return null
    return leadContributor?.businessPartnerId
  }, [hasBusinessPartner, leadContributor?.businessPartnerId])

  const businessPartnerOptions: SelectOption[] = useMemo(() => {
    if (!hasBusinessPartner || businessPartners.length === 0) return [{ value: null, label: EMPTY_SIGN, type: 'text' }]
    return businessPartners.map(businessPartner => ({
      value: businessPartner.id,
      label: businessPartner.name,
      type: 'text',
    }))
  }, [hasBusinessPartner, businessPartners])

  return (
    <div className="flex flex-col gap-4">
      <FormInput
        name={LeadContributorFormFields.firstName}
        label={t('lead_contributors.form.first_name')}
        type="text"
        placeholder={'Ex: John'}
        defaultValue={leadContributor?.firstName ?? null}
        error={error?.first_name && { id: id(), value: t(`error.type.${error.first_name}`) }}
      />
      <FormInput
        name={LeadContributorFormFields.lastName}
        label={t('lead_contributors.form.last_name')}
        type="text"
        placeholder={'Ex: Doe'}
        defaultValue={leadContributor?.lastName ?? null}
        error={error?.last_name && { id: id(), value: t(`error.type.${error.last_name}`) }}
      />
      <FormInput
        name={LeadContributorFormFields.email ?? null}
        label={t('lead_contributors.form.email')}
        type="text"
        Icon={EnvelopeIcon}
        placeholder={'Ex: email@apporteur.com'}
        defaultValue={leadContributor?.email ?? null}
        error={error?.email && { id: id(), value: t(`error.type.${error.email}`) }}
      />
      <FormInput
        name={LeadContributorFormFields.phone ?? null}
        label={t('lead_contributors.form.phone')}
        type="phone"
        Icon={PhoneIcon}
        defaultValue={leadContributor?.phone ?? null}
        error={error?.phone && { id: id(), value: t(`error.type.${error.phone}`) }}
      />
      <FormInput
        name={LeadContributorFormFields.jobTitle}
        label={t('lead_contributors.form.jobTitle')}
        type="text"
        placeholder={'Ex: Directeur'}
        defaultValue={leadContributor?.jobTitle ?? null}
        error={error?.job_title && { id: id(), value: t(`error.type.${error.job_title}`) }}
      />
      <p className="font-inter text-xs font-semibold leading-4 antialiased">
        {t('lead_contributors.form.has_business_partner')}
      </p>
      <RadioGroup
        className="flex gap-6"
        name="hasBusinessPartner"
        value={hasBusinessPartner.toString()}
        onChange={value => setHasBusinessPartner(value === 'true')}
        options={[
          { value: 'true', label: t('common.yes') },
          { value: 'false', label: t('common.no') },
        ]}
      />
      <FormInput
        className={classNames({ 'invisible absolute': !hasBusinessPartner })}
        dropdownClassName="w-full !max-h-40"
        name={LeadContributorFormFields.businessPartnerId}
        label={t('lead_contributors.form.business_partner')}
        type="select"
        isSearchable={businessPartners.length > 5}
        options={[businessPartnerOptions]}
        defaultValue={defaultBusinessPartnerId ?? null}
        error={error?.business_partner && { id: id(), value: t(`error.type.${error.business_partner}`) }}
      />
      <FormInput
        className={classNames({ 'invisible absolute': hasBusinessPartner })}
        dropdownClassName="w-full !max-h-40"
        name={LeadContributorFormFields.businessType}
        label={t('lead_contributors.form.business_type')}
        type="select"
        options={[businessTypesOptions]}
        defaultValue={hasBusinessPartner ? null : (leadContributor?.businessType ?? null)}
        error={error?.business_type && { id: id(), value: t(`error.type.${error.business_type}`) }}
      />
      {children}
    </div>
  )
}
