import { PencilIcon } from '@heroicons/react/20/solid'
import dayjs from 'dayjs'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { Task, taskDurations } from 'lib/oleenApi/services/tasksService'
import { t } from 'utils/i18n'

import { Modal } from 'ui//modals/modal'
import { Button } from 'ui/buttons/button'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import {
  getMortgageOpportunityQueryData,
  mortgageOpportunityQuery,
  updateMortgageOpportunityQueryData,
  updateTaskCompletionQuery,
  updateTaskQueryData,
} from 'helpers/queries'
import { CLICKS } from 'helpers/tracking'

type ShowTaskModalProps = {
  task: Task
  closeModal: () => void
}

export const ShowTaskModal: React.FC<ShowTaskModalProps> = ({ task, closeModal }) => {
  const navigate = useNavigate()
  const { data: opportunity } = useQuery(mortgageOpportunityQuery(task.taskableId))
  const queryClient = useQueryClient()
  const durationLabel = taskDurations.find(option => option.value === task.duration.toString())?.label
  const updateTaskCompletion = useMutation({
    mutationFn: updateTaskCompletionQuery,
    onSuccess: () => {
      updateTaskQueryData(queryClient, task)
      queryClient.invalidateQueries({ queryKey: ['tasks_count'] })
      const opportunity = getMortgageOpportunityQueryData(queryClient, task.taskableId)
      if (opportunity) {
        updateMortgageOpportunityQueryData(queryClient, {
          ...opportunity,
          remainingTasksCount: opportunity.remainingTasksCount + (task.completedAt ? -1 : 1),
        })
      }
      closeModal()
    },
  })

  return (
    <Modal onClose={closeModal} isOpen={true} className="modal--form w-1/3" title={task.title}>
      <div className="flex flex-col">
        <p className="text-xs">{task.description}</p>
        <div className="flex flex-row justify-between">
          <p>{t('tasks.modal.show.opportunity')}</p>
          <Link className="text-blue-600 font-semibold" to={`${ROUTES.OPPORTUNITIES}/${task.taskableId}`}>
            <span>
              {t('tasks.mortgagor.fullname', {
                first_name: opportunity?.mortgagorFirstName,
                last_name: opportunity?.mortgagorLastName,
              })}
            </span>
          </Link>
        </div>
        <div className="flex flex-row justify-between">
          <p>{t('tasks.modal.show.due_date')}</p>
          <p className="font-semibold">{dayjs(task.dueDate).format('DD/MM/YYYY')}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p>{t('tasks.modal.show.due_time')}</p>
          <p className="font-semibold">{dayjs(task.dueDate).format('HH:mm')}</p>
        </div>
        <div className="flex flex-row justify-between">
          <p>{t('tasks.modal.show.duration')}</p>
          <p className="font-semibold">{durationLabel}</p>
        </div>
        <div className="flex flex-row justify-between gap-4">
          <Button
            className="button--medium button--confirmation button--solid w-full"
            onClick={() => {
              updateTaskCompletion.mutate({ task, value: true })
            }}
            tracking={[CLICKS.taskComplete]}
          >
            {t('tasks.modal.show.complete')}
          </Button>
          <Button
            className="button--medium button--secondary button--outline"
            onClick={() => {
              navigate(`../${ROUTES.TASK_EDIT}`)
            }}
            tracking={[CLICKS.taskEdit]}
          >
            <PencilIcon className="mt-1" />
          </Button>
        </div>
      </div>
    </Modal>
  )
}
