import { Maturity } from 'lib/oleenApi'

export const MaturityBadgeEmoji = {
  [Maturity.null]: '❓',
  [Maturity.search_started]: '🔎',
  [Maturity.offer_made]: '🔖',
  [Maturity.offer_accepted]: '🤝',
  [Maturity.under_study]: '🤔',
  [Maturity.sale_agreement_signed]: '✅',
}
