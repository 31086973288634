import { Auth0Client } from '@auth0/auth0-spa-js'

const domain: string = process.env.AUTH0_ACCOUNT as string
const clientId: string = process.env.AUTH0_CLIENTID as string

export const Auth0 = new Auth0Client({
  domain,
  clientId,
  cacheLocation: 'localstorage',
})
