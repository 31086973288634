import { id } from 'helpers/ref'
import React from 'react'

export const CardDataColumns: React.FC<{ content: Array<Array<string>> }> = ({ content }) => {
  const [titles, ...columns] = content
  return (
    <div className="flex justify-between gap-6">
      <div className="flex-wrap">
        {titles.map(title => (
          <div key={id()}>{title}</div>
        ))}
      </div>
      {columns.map(column => (
        <div key={id()} className="flex-wrap text-right">
          {column.map(item => (
            <div key={id()}>
              <span className="font-semibold truncate">{item}</span>
            </div>
          ))}
        </div>
      ))}
    </div>
  )
}
