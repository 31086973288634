import React from 'react'

import { HIGHLIGHTED_ICON_SIZE, HighlightedIconProps } from 'ui/legacy/atoms/highlightedIcon/highlightedIcon.types'

export const HighlightedIcon: React.FC<HighlightedIconProps> = ({ Icon, variant, highlightIconSize }) => {
  const size = highlightIconSize ?? HIGHLIGHTED_ICON_SIZE.MD
  return (
    <div className={`flex justify-center items-center rounded-md ${size?.highlightSize} ${variant}`}>
      <Icon className={size.iconSize} />
    </div>
  )
}
