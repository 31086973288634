import { EMPTY_SIGN, formatFullName } from 'helpers/format'
import { LeadContributor } from 'lib/oleenApi'
import React from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'router/routes'

import { Button } from 'ui/buttons/button'

type LeadContributorManagerItemProps = {
  leadContributor: LeadContributor
  onRemove: (value: string) => void
}

export const LeadContributorManagerItem: React.FC<LeadContributorManagerItemProps> = ({
  leadContributor,
  onRemove,
}) => {
  const params = new URLSearchParams({ leadContributorId: leadContributor.id })

  return (
    <div className="flex gap-2 items-center self-strech jusify-end text-sm font-normal leading-6 antialiased">
      <p className="flex-1">{formatFullName(leadContributor?.firstName, leadContributor?.lastName, EMPTY_SIGN)}</p>
      <Link to={`${ROUTES.OPPORTUNITIES}?${params.toString()}`} className=" flex-1 text-blue-600">
        {leadContributor.currentOpportunitiesCount} opportunités
      </Link>
      <Button className="button--outline button--medium button--primary" onClick={() => onRemove(leadContributor.id)}>
        Retirer
      </Button>
    </div>
  )
}
