import { TaskItem } from 'components/tasks/taskItem'
import React from 'react'
import { Task } from 'lib/oleenApi/services/tasksService'

import { BigCheck } from 'ui/buttons/bigCheck'
import { LabelledCardVerticalItem as VerticalItem } from 'ui/legacy/molecules/card/card.styled'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  getMortgageOpportunityQueryData,
  updateMortgageOpportunityQueryData,
  updateTaskCompletionQuery,
} from 'helpers/queries'
import { Button } from 'ui/buttons/button'
import { CLICKS } from 'helpers/tracking'
import { ROUTES } from 'router/routes'
import { useNavigate } from 'react-router-dom'
import { PlusIcon } from '@heroicons/react/20/solid'
import { t } from 'utils/i18n'

interface TaskItemRowProps {
  task: Task
}

export const TaskItemRow: React.FC<TaskItemRowProps> = ({ task }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const updateTaskCompletion = useMutation({
    mutationFn: updateTaskCompletionQuery,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['tasks', 'meta'] })
      const opportunity = getMortgageOpportunityQueryData(queryClient, task.taskableId)
      if (opportunity) {
        updateMortgageOpportunityQueryData(queryClient, {
          ...opportunity,
          remainingTasksCount: opportunity.remainingTasksCount + (task.completedAt ? -1 : 1),
        })
      }
    },
  })

  return (
    <VerticalItem>
      <div className="flex gap-4 items-center relative">
        <BigCheck
          value={Boolean(task.completedAt)}
          onChange={value => updateTaskCompletion.mutate({ task, value })}
          defaultChecked={Boolean(task.completedAt)}
        />
        <div className="grow">
          <TaskItem task={task} />
        </div>
        <div>
          <Button
            className="button--outline button--primary button--small"
            onClick={() => {
              navigate(`./${task.id}/${ROUTES.TASK_ADD_TO_CALENDAR}`)
            }}
            tracking={[CLICKS.taskExport, { taskId: task.id }]}
          >
            <PlusIcon />
            {t('tasks.add_to_calendar')}
          </Button>
        </div>
      </div>
    </VerticalItem>
  )
}
