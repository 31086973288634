import { TaskForm } from 'components/tasks/taskForm'
import { useOleenApi } from 'contexts/oleenApi'
import { toast } from 'helpers/toast'
import React, { useCallback } from 'react'
import { t } from 'utils/i18n'

import { Modal } from 'ui//modals/modal'
import { useQueryClient } from '@tanstack/react-query'
import { NewTask } from 'lib/oleenApi'
import { getMortgageOpportunityQueryData, updateMortgageOpportunityQueryData } from 'helpers/queries'

type CreateTaskModalProps = {
  closeModal: () => void
  defaultOpporunityId?: string
}

export const CreateTaskModal: React.FC<CreateTaskModalProps> = ({ closeModal, defaultOpporunityId }) => {
  const { oleenApi } = useOleenApi()
  const queryClient = useQueryClient()
  const action = useCallback(
    (task: NewTask) => {
      return oleenApi.tasks().create(task)
    },
    [oleenApi]
  )

  const handleSuccess = useCallback(
    (task: NewTask) => {
      queryClient.invalidateQueries({ queryKey: ['tasks'] })
      queryClient.invalidateQueries({ queryKey: ['tasks', 'meta'] })
      const opportunity = getMortgageOpportunityQueryData(queryClient, task.taskableId)
      if (opportunity) {
        updateMortgageOpportunityQueryData(queryClient, {
          ...opportunity,
          remainingTasksCount: opportunity.remainingTasksCount + 1,
        })
      }
      toast.success(t('tasks.toast.creation.success'))
      closeModal()
    },
    [queryClient, closeModal]
  )

  const handleError = useCallback(() => {
    toast.error(t('tasks.toast.creation.error'))
  }, [])

  return (
    <Modal onClose={closeModal} isOpen={true} className="modal--form w-1/3" title={t('tasks.modal.create.title')}>
      <TaskForm
        defaultTaskableId={defaultOpporunityId}
        action={action}
        onSuccess={handleSuccess}
        onError={handleError}
      />
    </Modal>
  )
}
