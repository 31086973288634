import { id } from 'helpers/ref'
import { BusinessPartnerErrorAttribute, BusinessPartnerErrorType } from 'lib/oleenApi'
import { BUSINESS_TYPES } from 'lib/oleenApi/services/businessPartnersService'
import React, { PropsWithChildren } from 'react'
import { t } from 'utils/i18n'

import { FormInput } from 'ui/forms/formInput'

enum CreateBusinessPartnerFormFields {
  name = 'name',
  businessType = 'businessType',
}

export type CreateBusinessPartnerFormData = {
  [key in CreateBusinessPartnerFormFields]: string
}

export type CreateBusinessPartnerFormError = Partial<Record<BusinessPartnerErrorAttribute, BusinessPartnerErrorType>>

const options = BUSINESS_TYPES.map(value => ({
  value,
  label: t(`business_partners.business_type.${value}`),
  type: 'text' as const,
}))

type CreateBusinessPartnerFormProps = PropsWithChildren<{
  error?: CreateBusinessPartnerFormError
}>

export const CreateBusinessPartnerForm: React.FC<CreateBusinessPartnerFormProps> = ({ error, children }) => {
  return (
    <div className="flex flex-col gap-4">
      <FormInput
        name={CreateBusinessPartnerFormFields.name}
        label="Nom"
        type="text"
        placeholder={"Ex: Agence de l'Etoile"}
        error={error?.name && { id: id(), value: t(`error.type.${error.name}`) }}
      />
      <FormInput
        name={CreateBusinessPartnerFormFields.businessType}
        dropdownClassName="w-full"
        label="Type"
        type="select"
        options={[options]}
        error={error?.business_type && { id: id(), value: t(`error.type.${error.business_type}`) }}
      />
      {children}
    </div>
  )
}
