import translationFR from 'assets/locales/fr/index.json'
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import zodFrenchTranslation from 'zod-i18n-map/locales/fr/zod.json'

const resources = {
  fr: {
    translation: translationFR,
    zod: zodFrenchTranslation,
  },
}

type Path<T, Prev = ''> = {
  [K in keyof T]: T[K] extends string ? `${string & Prev}${string & K}` : Path<T[K], `${string & Prev}${string & K}.`>
}[keyof T]

export type I18NPath = Path<typeof translationFR>

type OptionValue = Scalar

export const t = (key: I18NPath, options?: Record<string, OptionValue>) => i18n.t(key, options)

i18n.use(initReactI18next).init({
  resources,
  lng: 'fr',
  returnEmptyString: true,
  interpolation: {
    escapeValue: false,
  },
})

export default i18n
