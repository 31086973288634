import banks from 'config/banks.json'
import brokers from 'config/brokers.json'
import { Competitor } from 'types/competitor'

const getBanks = (): Competitor[] =>
  banks.map((bank: Competitor) => ({
    label: bank.label,
    value: bank.value,
  }))

export const getCompetitors = () => {
  const banks = getBanks()
  return {
    banks,
    brokers,
  }
}

export const getCompetitorLabel = (value: string) => {
  const competitors = brokers.concat(banks)
  const foundCompetitor = competitors.find((competitor: Competitor) => competitor.value === value)
  if (foundCompetitor) return foundCompetitor.label
  return ''
}
