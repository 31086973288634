import classNames from 'classnames'
import { id } from 'helpers/ref'
import React, { ForwardedRef, forwardRef } from 'react'

interface ITextAreaProps {
  className?: string
  id?: string
  placeholder?: string
  disabled?: boolean
  value?: string
  onChange: (value: string) => void
  onBlur?: () => void
  onFocus?: () => void
  error?: boolean
}

export const TextArea = forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  ({ onChange, onBlur, onFocus, value, className, ...props }, ref) => {
    const inputId = props.id || id()

    return (
      <div
        className={classNames('group', 'textArea', {
          'textArea--disabled': props.disabled,
        })}
      >
        <textarea
          className={className}
          ref={ref}
          id={inputId}
          disabled={props.disabled}
          placeholder={props.placeholder}
          value={value}
          onChange={({ currentTarget: { value } }) => onChange(value)}
          onFocus={onFocus}
          onBlur={onBlur}
        ></textarea>
      </div>
    )
  }
)
TextArea.displayName = 'TextArea'
