import React from 'react'

interface IAccordionItemProps {
  children: React.ReactNode
  isOpen: boolean
}

export const AccordionItem: React.FC<IAccordionItemProps> = ({ isOpen, children }) => (
  <div className={`max-h-0 transition-maxHeight duration-100 overflow-hidden ${isOpen && 'max-h-80'}`}> {children}</div>
)
