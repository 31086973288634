import { formatAmount } from 'helpers/format'
import React from 'react'

interface IAmountSummaryProps {
  label: string
  amount: number
}

export const AmountSummary: React.FC<IAmountSummaryProps> = ({ label, amount }) => (
  <div className="flex items-baseline">
    <span className="uppercase text-gray-500 font-semibold text-xs leading-6 antialiased truncate">{label}</span>
    <div className="font-semibold text-sm ml-1">{formatAmount(amount)}</div>
  </div>
)
