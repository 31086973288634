import classNames from 'classnames'
import { t } from 'i18next'
import React, { PropsWithChildren } from 'react'

interface ITanstackFormInputProps {
  field: any
  label: string
  className?: string
  apiError?: string
}

export const TanstackFormInput: React.FC<PropsWithChildren<ITanstackFormInputProps>> = ({
  field,
  label,
  className,
  apiError,
  children,
}) => (
  <div className={classNames('formInput', className)}>
    <label htmlFor={field.name}>
      <span>{label} </span>
    </label>
    {children}
    {field.state.meta.errors && <p className="caption caption--error"> {field.state.meta.errors.join(', ')} </p>}
    {apiError && <p className="caption caption--error"> {t(`error.type.${apiError}`)} </p>}
  </div>
)
