import React from 'react'
import { Addon, AddonName, AddonStatus } from 'lib/oleenApi'
import { t } from 'utils/i18n'
import { Button } from 'ui/buttons/button'
import { useNavigate } from 'react-router-dom'
import { CheckIcon, InformationCircleIcon } from '@heroicons/react/20/solid'
import { CLICKS } from 'helpers/tracking'

type AddonItemProps = {
  addon: Addon
}

export const AddonItem: React.FC<AddonItemProps> = ({ addon }) => {
  const navigate = useNavigate()

  const onClick = () => navigate(`./addons/${addon.name}`)

  return (
    <>
      {addon.name === AddonName.YUCCANLEAD && (
        <div className="flex flex-col md:flex-row items-start md:items-center md:justify-between card--content">
          <div>
            <h3 className="text-xs leading-4">{t(`addons.${addon.name}.title`)}</h3>
            <p className="text-xs leading-4 text-gray-400">{t(`addons.${addon.name}.description`)}</p>
          </div>
          <div className="flex gap-2 justify-between items-center">
            {addon.status === AddonStatus.INACTIVE && (
              <Button
                className="button--outline button--primary button--small"
                onClick={onClick}
                tracking={[CLICKS.addonSubscribe, { addon: addon.name }]}
              >
                {t(`addons.${addon.name}.subscribe`)}
              </Button>
            )}
            {addon.status === AddonStatus.ACTIVE && (
              <>
                <CheckIcon className="w-5 h-5 text-green-500" />
                <span className="text-xs leading-4 antialiased">{t(`addon_subscriptions.subscribed`)}</span>
              </>
            )}
            {addon.status === AddonStatus.PENDING && (
              <>
                <InformationCircleIcon className="w-5 h-5 text-blue-600" />
                <span className="text-xs leading-4 antialiased">{t(`addon_subscriptions.pending`)}</span>
              </>
            )}
          </div>
        </div>
      )}
      {addon.name === AddonName.SIMPLEBO && (
        <div className="card--content">
          <h3 className="text-xs leading-4">{t(`addons.${addon.name}.title`)}</h3>
          <p className="text-xs leading-4 text-gray-400">{t(`addons.${addon.name}.description`)}</p>
          <p className="text-xs leading-4 text-blue-400 underline">
            <a href={t(`addons.${addon.name}.link.url`)} target="_blank" rel="noreferrer">
              {t(`addons.${addon.name}.link.text`)}
            </a>
          </p>
        </div>
      )}
    </>
  )
}
