import { ExclamationCircleIcon } from '@heroicons/react/20/solid'
import classNames from 'classnames'
import React, { PropsWithChildren } from 'react'

export type AlertProps = PropsWithChildren<{
  id?: string
  className?: string
}>

export const Alert: React.FC<AlertProps> = ({ id, className, children }) => {
  return (
    <div id={id} className={classNames('alert alert--scoped', className)}>
      {<ExclamationCircleIcon />}
      {children}
    </div>
  )
}
