import classNames from 'classnames'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/fr'
import isToday from 'dayjs/plugin/isToday'
import isTomorrow from 'dayjs/plugin/isTomorrow'
import isYesterday from 'dayjs/plugin/isYesterday'
import React, { useMemo } from 'react'
import { Link } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { Task } from 'lib/oleenApi/services/tasksService'
import { t } from 'utils/i18n'

dayjs.extend(isYesterday)
dayjs.extend(isToday)
dayjs.extend(isTomorrow)

interface TaskItemProps {
  task: Task
}

export const TaskItem: React.FC<TaskItemProps> = ({ task }) => {
  const createdAt: Dayjs = useMemo(() => dayjs(task.createdAt), [task.createdAt])
  const dueDate: Dayjs = useMemo(() => dayjs(task.dueDate), [task.dueDate])
  const formattedDueDate = dueDate.locale('fr').format('ddd D MMM')
  const formattedCreatedAt = createdAt.locale('fr').format('ddd D MMM')
  const formattedDueTime = dueDate.format('HH:mm')
  const humanFriendlyDueDate = dueDate.isToday()
    ? formattedDueTime
    : dueDate.isYesterday()
      ? t('tasks.item.due_date.yesterday', { due_time: formattedDueTime })
      : dueDate.isTomorrow()
        ? t('tasks.item.due_date.tomorrow', { due_time: formattedDueTime })
        : t('tasks.item.due_date.at', { due_date: formattedDueDate })

  const humanFriendlyCreatedAt = createdAt.isYesterday()
    ? t('tasks.item.created_at.yesterday')
    : createdAt.isToday()
      ? t('tasks.item.created_at.today')
      : t('tasks.item.created_at.created', { created_at: formattedCreatedAt })

  return (
    <Link to={`./${task.id}/${ROUTES.TASK_SHOW}`}>
      <p
        className={classNames('select-none font-semibold text-sm leading-6', {
          'text-gray-400': task.completedAt,
          'text-gray-900': !task.completedAt,
        })}
      >
        {task.title}
      </p>
      <p
        className={classNames('select-none font-normal text-xs leading-4', {
          'text-gray-400': task.completedAt,
          'text-gray-500': !task.completedAt,
        })}
      >
        {t('tasks.item.calendar_emoji')}
        <span
          className={classNames(
            { 'text-green-500': !task.completedAt && (dueDate.isToday() || dueDate.isTomorrow()) },
            { 'text-red-600': !task.completedAt && dueDate.isBefore(dayjs(), 'day') }
          )}
        >
          {humanFriendlyDueDate}
        </span>
        {humanFriendlyCreatedAt}
      </p>
    </Link>
  )
}
