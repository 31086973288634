import { useLeadContributors } from 'contexts/leadContributors'
import { useOleenApi } from 'contexts/oleenApi'
import { useAsync } from 'hooks/async.hook'
import { BusinessPartner } from 'lib/oleenApi'
import React, { createContext, PropsWithChildren, useCallback, useContext, useEffect } from 'react'

/**
 * This is a context that provides access to the list of business partners.
 */
interface BusinessPartnersContextType {
  businessPartners: BusinessPartner[]
  upsertBusinessPartner: (businessPartner: BusinessPartner) => void
  getBusinessPartner: (id: string) => BusinessPartner | null
  refreshBusinessPartners: () => void
}

/**
 * This is a context that provides access to the list of business partners.
 */
const BusinessPartnersContext = createContext<BusinessPartnersContextType>({
  businessPartners: [],
  upsertBusinessPartner: () => null,
  getBusinessPartner: () => null,
  refreshBusinessPartners: () => null,
})

type BusinessPartnersProviderProps = PropsWithChildren<unknown>

export const BusinessPartnersProvider: React.FC<BusinessPartnersProviderProps> = ({ children }) => {
  const { oleenApi } = useOleenApi()
  const { refreshLeadContributors } = useLeadContributors()
  const [businessPartners, setBusinessPartners] = React.useState<BusinessPartner[]>([])
  const listBusinessPartners = useCallback(() => {
    if (!oleenApi.hasAuthToken()) return Promise.resolve(null)

    return oleenApi.businessPartners().listAll()
  }, [oleenApi])

  const { data, run, retry } = useAsync(listBusinessPartners)

  useEffect(() => {
    run()
  }, [run])

  useEffect(() => {
    if (data) setBusinessPartners(data)
  }, [data])

  const businessPartnersIndex = React.useMemo(() => {
    return businessPartners.reduce(
      (acc, item) => {
        acc[item.id] = item
        return acc
      },
      {} as { [key: string]: BusinessPartner }
    )
  }, [businessPartners])

  const upsertBusinessPartner = useCallback(
    (businessPartner: BusinessPartner) => {
      setBusinessPartners(prevBusinessPartners => {
        const index = prevBusinessPartners.findIndex(bp => bp.id === businessPartner.id)
        if (index === -1) {
          return [businessPartner, ...prevBusinessPartners]
        }
        const newBusinessPartners = [...prevBusinessPartners]
        newBusinessPartners[index] = businessPartner
        refreshLeadContributors()
        return newBusinessPartners
      })
    },
    [refreshLeadContributors]
  )

  const getBusinessPartner = useCallback((id: string) => businessPartnersIndex[id], [businessPartnersIndex])

  return (
    <BusinessPartnersContext.Provider
      value={{ businessPartners, upsertBusinessPartner, getBusinessPartner, refreshBusinessPartners: retry }}
    >
      {children}
    </BusinessPartnersContext.Provider>
  )
}

export const useBusinessPartners = () => useContext(BusinessPartnersContext)
