import { PlusIcon } from '@heroicons/react/20/solid'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'router/routes'
import { t } from 'utils/i18n'

import { Button } from 'ui/buttons/button'
import { LabelledCardVerticalItem as VerticalItem } from 'ui/legacy/molecules/card/card.styled'
import { Opportunity } from 'lib/oleenApi'
import { CLICKS } from 'helpers/tracking'

interface NewTaskRowProps {
  opportunity: Opportunity
}

export const NewTaskRow: React.FC<NewTaskRowProps> = ({ opportunity }) => {
  const navigate = useNavigate()

  return (
    <VerticalItem>
      <div className="flex gap-4 items-center justify-between">
        <p className="flex items-center text-sm">{t('opportunity.create_task')}</p>
        <Button
          className="button--outline button--primary button--small"
          onClick={() => navigate(`${ROUTES.OPPORTUNITIES}/${opportunity.id}/${ROUTES.OPPORTUNITY_CREATE_TASK}`)}
          tracking={[CLICKS.opportunityNewTask]}
        >
          <PlusIcon />
          {t('opportunity.new_task')}
        </Button>
      </div>
    </VerticalItem>
  )
}
