import { SendMandateModalProjectCard } from 'components/opportunity/molecules/sendMandateModal/sendMandateModalProjectCard'
import { SendMandateModalRepresentativeCard } from 'components/opportunity/molecules/sendMandateModal/sendMandateModalRepresentativeCard'
import { useRepresentativeContext } from 'contexts/representative'
import { MANDATE_FIELDS } from 'helpers/mandate'
import { validateMandatePayload } from 'helpers/opportunity'
import { mapOpportunityToPrimaryAndSecondaryContact } from 'helpers/opportunityContact'
import React, { useCallback, useMemo } from 'react'
import { t } from 'utils/i18n'

import Toast, { ToastVariants } from 'ui/banners/toast'
import { Button } from 'ui/buttons/button'
import { useFormContext } from 'ui/forms/form'
import { Modal } from 'ui/modals/modal'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { ContactCardBody } from 'ui/legacy/molecules/contactCard/contactCardBody'
import { buildMandateParams, OleenApi, Opportunity } from 'lib/oleenApi'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { CLICKS } from 'helpers/tracking'
import { toast } from 'helpers/toast'

interface SendMandateModalProps {
  closeModal: () => void
  opportunity: Opportunity
}

export const SendMandateModal: React.FC<SendMandateModalProps> = ({ closeModal, opportunity }) => {
  const { getFormData } = useFormContext()
  const { representative } = useRepresentativeContext()
  const queryClient = useQueryClient()
  // NOTE : Handle this condition using a backend validation
  const hasDuplicateEmail = opportunity.coMortgagorEmail && opportunity.mortgagorEmail === opportunity.coMortgagorEmail

  const handleReloadOpportunity = useCallback(() => {
    // TODO: centralize query key management to facilitate invalidation
    queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', opportunity.id] })
  }, [opportunity.id, queryClient])

  const previewMandateMutation = useMutation({
    mutationFn: (params: { comment: string }) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.opportunityMandates().preview(opportunity.id, buildMandateParams(params.comment))
    },
    onSuccess: data => {
      const { url } = data
      const link = document.createElement('a')
      link.href = url
      link.click()

      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', opportunity.id] })
    },
  })

  const sendMandateMutation = useMutation({
    mutationFn: (params: { comment: string }) => {
      const client = new OleenApi()
      client.setAuthToken(localStorage.getItem('token') ?? '')
      return client.opportunityMandates().send(opportunity.id, buildMandateParams(params.comment))
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['mortgageOpportunity', opportunity.id] })
      toast.success(t('opportunity.mandate_modal.toast_success'))
      closeModal()
    },
  })

  const { isValid, invalidFields } = useMemo(
    () => validateMandatePayload(opportunity, representative),
    [opportunity, representative]
  )

  const onPreview = useCallback(() => {
    const { [MANDATE_FIELDS.COMMENT]: comment } = getFormData()
    previewMandateMutation.mutate({ comment: comment as string })
  }, [getFormData, previewMandateMutation])

  const onSend = useCallback(() => {
    const { [MANDATE_FIELDS.COMMENT]: comment } = getFormData()
    sendMandateMutation.mutate({ comment: comment as string })
  }, [getFormData, sendMandateMutation])

  return (
    <Modal
      className="modal--form md:!w-10/12"
      isOpen={true}
      onClose={closeModal}
      title={t('opportunity.mandate_modal.title')}
    >
      <div className="flex flex-col space-y-6 grow text-sm">
        <span className="font-bold truncate">{t('opportunity.mandate_modal.warning')}</span>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <div className="space-y-6">
              <SendMandateModalRepresentativeCard />
              <LabelledCard
                icon={CARD_ICON.IdentificationIcon}
                title={t('opportunity.mandate_modal.mortgagors_information_card')}
              >
                <ContactCardBody
                  primaryTitle={t('opportunity.mandate_modal.mortgagor_contact_title')}
                  secondaryTitle={t('opportunity.mandate_modal.co_mortgagor_contact_title')}
                  {...mapOpportunityToPrimaryAndSecondaryContact(opportunity)}
                  projectId={opportunity.projectId}
                />
              </LabelledCard>
            </div>
          </div>
          <div>
            <SendMandateModalProjectCard opportunity={opportunity} />
          </div>
        </div>
        {isValid ? (
          <Toast
            text={
              <div>
                <span className="font-semibold truncate">{t('opportunity.mandate_modal.toast_highlight')}</span>
                <div>{t('opportunity.mandate_modal.toast')}</div>
              </div>
            }
            variant={ToastVariants.GRAY}
            closable
          />
        ) : (
          <Toast
            text={
              <div className="flex flex-col lg:flex-row items-center lg:justify-between space-x-3">
                <div>
                  <span className="font-semibold truncate">
                    {t('opportunity.mandate_modal.missingData.validationMessage', {
                      missingData: invalidFields.join(', '),
                    })}
                  </span>
                  <div>{t('opportunity.mandate_modal.missingData.action')}</div>
                </div>
                <div className="flex space-x-3 mt-3 lg:mt-0">
                  {
                    // TODO: This button should be useless now thanks to tanstack query
                  }
                  <Button className="button--outline button--warning button--small" onClick={handleReloadOpportunity}>
                    {t('opportunity.mandate_modal.missingData.refreshButton')}
                  </Button>
                  <Button
                    className="button--solid button--warning button--small"
                    onClick={() => window.open(t('url.zou_project', { projectId: opportunity.projectId }))}
                  >
                    {t('opportunity.mandate_modal.missingData.modifyButton')}
                  </Button>
                </div>
              </div>
            }
            variant={ToastVariants.ORANGE}
          />
        )}
      </div>
      <footer className="flex justify-between px-8 py-4">
        <Button className="button--outline button--secondary button--small self-start" onClick={closeModal}>
          {t('modal_cancel')}
        </Button>
        <div className="flex gap-2">
          <Button
            className="button--solid button--secondary button--small"
            tracking={[CLICKS.opportunityMandatePreview]}
            onClick={onPreview}
            disabled={!isValid || sendMandateMutation.isPending}
            isLoading={previewMandateMutation.isPending}
          >
            {t('opportunity.mandate_modal.preview')}
          </Button>
          <Button
            className="button--solid button--primary button--small"
            tracking={[CLICKS.opportunityMandateConfirmSend]}
            onClick={onSend}
            disabled={!isValid || hasDuplicateEmail || previewMandateMutation.isPending}
            isLoading={sendMandateMutation.isPending}
          >
            {t('opportunity.mandate_modal.confirm')}
          </Button>
        </div>
      </footer>
    </Modal>
  )
}
