import classNames from 'classnames'
import React, { forwardRef } from 'react'

import { DropdownOptionBadge } from './dropdown/dropdownOptionBadge'
import { DropdownOptionSearch } from './dropdown/dropdownOptionSearch'
import { DropdownOptionText } from './dropdown/dropdownOptionText'
import { DropdownSection } from './dropdown/types'
import { DropdownOptionCallout } from './dropdown/dropdownOptionCallout'
import { DropdownOptionSeparator } from './dropdown/dropdownOptionSeparator'

export * from './dropdown/types'

export type DropdownProps = {
  className?: string
  isVisible: boolean
  options: DropdownSection[]
}

export const Dropdown = forwardRef<HTMLDivElement, DropdownProps>(({ isVisible, options, className }, ref) => {
  if (!isVisible) return <></>

  return (
    <div ref={ref} className={classNames('dropdown', className)}>
      {options.map((suboptions, i) => (
        <div key={i} className="dropdownSection">
          {suboptions.map((option, j) => (
            <React.Fragment key={j}>
              {option.type === 'text' && <DropdownOptionText {...option} />}
              {option.type === 'search' && <DropdownOptionSearch {...option} />}
              {option.type === 'badge' && <DropdownOptionBadge {...option} />}
              {option.type === 'callout' && <DropdownOptionCallout {...option} />}
              {option.type === 'separator' && <DropdownOptionSeparator {...option} />}
            </React.Fragment>
          ))}
        </div>
      ))}
    </div>
  )
})
Dropdown.displayName = 'Dropdown'
