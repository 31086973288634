import { DropOpportunityModal } from 'components/opportunity/dropOpportunityModal/dropOpportunityModal'
import React from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { FormProvider } from 'ui/forms/form'

export const DropOpportunityPage: React.FC = () => {
  const { opportunityId } = useParams()
  const navigate = useNavigate()

  return (
    <FormProvider>
      {opportunityId && (
        <DropOpportunityModal
          closeModal={() => navigate(`/opportunities/${opportunityId}`)}
          opportunityId={opportunityId}
        />
      )}
    </FormProvider>
  )
}
