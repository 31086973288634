import React from 'react'
import { t } from 'utils/i18n'

import { LabelledCardHorizontalBody as HCardBody } from 'ui/legacy/molecules/card/card.styled'
import LabelledCard, { CARD_ICON } from 'ui/legacy/molecules/card/labelledCard'
import { Opportunity } from 'lib/oleenApi'

interface AnnotationCardProps {
  opportunity: Opportunity
}

export const AnnotationCard: React.FC<AnnotationCardProps> = ({ opportunity }) => (
  <LabelledCard icon={CARD_ICON.AnnotationIcon} title={t('opportunity.notes_title')}>
    <HCardBody>
      <iframe className="w-full" src={t('url.notes_iframe', { projectId: opportunity.projectId })}></iframe>
    </HCardBody>
  </LabelledCard>
)
